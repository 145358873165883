import { PageData } from "./PageData.model";

export interface InterfacePortalGroup {
  id: string;
  name: string;
  portalUsersCount: number;
  sonarServersCount: number;
  organizationsCount: number;
  sonarServerNames: [string];
  organizationNames: [string];
}

export interface InterfacePortalGroupAudit {
  rev: number;
  id: string;
  name: string;
  sonarServerId: string;
  username: string;
  timestamp: string;
  revtype: number;
}

export const emptyPortalGroup = (): PortalGroup => ({
  id: "",
  name: "",
  portalUsersCount: 0,
  sonarServersCount: 0,
  organizationsCount: 0,
  sonarServerNames: [""],
  organizationNames: [""],
});

export class PortalGroup implements InterfacePortalGroup {
  constructor(
    public id: string,
    public name: string,
    public portalUsersCount: number,
    public sonarServersCount: number,
    public organizationsCount: number,
    public sonarServerNames: [string],
    public organizationNames: [string]
  ) {}
}

export interface InterfacePagedPortalGroupMembership {
  portalGroupId: string;
  pageIndex: number;
  pageSize: number;
}

export interface InterfacePortalGroupsSearchRequest {
  name: string;
  pageIndex: number;
  pageSize: number;
}

export interface InterfacePortalGroupsSearchResult {
  paging: PageData;
  groups: InterfacePortalGroup[];
}
