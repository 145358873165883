import {
  emptySonarProject,
  InterfaceSonarProject,
} from "@/models/SonarProject.model";
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse } from "axios";
import { emptyPageData, PageData } from "@/models/PageData.model";
import environmentConfig from "@/utils/environmentConfig";
import { Alert, emptyAlert } from "@/models/Alert.model";
import { SearchByKeywordRequest } from "@/models/Search.model";

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/sonar-projects`;

@Module({ namespaced: true })
class SonarProjects extends VuexModule {
  public sonarProject: InterfaceSonarProject = emptySonarProject();
  public existingSonarProjects: InterfaceSonarProject[] = [];
  public pageDataSonarProjects: PageData = emptyPageData();
  public sonarProjectAlert: Alert = emptyAlert();
  public sonarProjectTableAlert: Alert = emptyAlert();
  public inProgress = false;
  public sonarServerId = "";
  public forceDelete = false;
  public notExistingSonarProject = false;

  @Mutation
  public setSonarProjects(data: InterfaceSonarProject[]): void {
    this.existingSonarProjects = data;
  }

  @Mutation
  public setPageData(pageData: PageData): void {
    this.pageDataSonarProjects.totalItems = pageData.totalItems;
  }

  @Mutation
  public setSonarProjectAlert(data: Alert): void {
    this.sonarProjectAlert = data;
  }

  @Mutation
  public clearSonarProjectAlert(): void {
    this.sonarProjectAlert = emptyAlert();
  }

  @Mutation
  public setSonarProjectTableAlert(data: Alert): void {
    this.sonarProjectTableAlert = data;
  }

  @Mutation
  public clearSonarProjectTableAlert(): void {
    this.sonarProjectTableAlert = emptyAlert();
  }

  @Mutation
  public setInProgress(data: boolean): void {
    this.inProgress = data;
  }

  @Mutation
  public setSonarServerId(data: string): void {
    this.sonarServerId = data;
  }
  @Mutation
  public setForceDelete(data: boolean): void {
    this.forceDelete = data;
  }
  @Mutation
  public setNotExistingSonarProject(data: boolean): void {
    this.notExistingSonarProject = data;
  }

  @Action
  public handleError(error: AxiosError): boolean {
    let alert: Alert;
    if (error.response) {
      const data = error.response.data as { message: string };
      alert = new Alert(error.response.status, data.message);
    } else {
      alert = new Alert(0, error.message);
    }
    this.context.commit("setSonarProjectAlert", alert);
    return false;
  }

  @Action
  public async storeSonarProject(
    sonarProject: InterfaceSonarProject
  ): Promise<boolean> {
    return axios
      .post(`${baseApiUrl}`, {
        name: sonarProject.name,
        organizationId: sonarProject.organizationId,
      })
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async getSonarProjects(
    searchRequest: SearchByKeywordRequest
  ): Promise<boolean> {
    this.context.commit("setInProgress", true);
    this.context.commit("clearSonarProjectTableAlert");
    const params = new URLSearchParams({
      pageIndex: `${searchRequest.pageData.options.page}`,
      pageSize: `${searchRequest.pageData.options.itemsPerPage}`,
    }).toString();
    let searchKeywordWithSonarServerId = "";
    if (searchRequest.keyword != "" && searchRequest.keyword != undefined) {
      searchKeywordWithSonarServerId =
        "/search/" + this.sonarServerId + "/" + searchRequest.keyword;
    }
    return axios
      .get(`${baseApiUrl}${searchKeywordWithSonarServerId}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSonarProjects", response.data.list);
        this.context.commit("setPageData", response.data.paging);
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setInProgress", false);
        this.context.commit(
          "setSonarProjectTableAlert",
          new Alert(0, error.message)
        );
        return false;
      });
  }

  @Action
  public async getOrganizationProjectMembership(
    organizationId: string
  ): Promise<boolean> {
    this.context.commit("setSonarProjects", []);
    const params = new URLSearchParams({
      pageIndex: `1`,
      pageSize: `100`,
    }).toString();
    return axios
      .get(
        `${environmentConfig(
          "VUE_APP_BACKEND_PATH"
        )}/api/organizations/${organizationId}/sonar-projects?${params}`
      )
      .then((response: AxiosResponse) => {
        this.context.commit("setSonarProjects", response.data.list);
        this.context.commit("setPageData", response.data.paging);
        return true;
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
      });
  }

  @Action
  public async updateSonarProject(
    sonarProject: InterfaceSonarProject
  ): Promise<boolean> {
    return axios
      .put(`${baseApiUrl}/${sonarProject.id}`, sonarProject)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  async deleteSonarProject(
    sonarProject: InterfaceSonarProject
  ): Promise<boolean> {
    try {
      if (this.forceDelete && this.notExistingSonarProject) {
        await axios.delete(`${baseApiUrl}/${sonarProject.id}?force=true`);
        this.context.commit("setForceDelete", false);
        return true;
      } else {
        await axios.delete(`${baseApiUrl}/${sonarProject.id}`);
        return true;
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 404) {
        this.context.dispatch("handleError", axiosError);
        this.context.commit("setNotExistingSonarProject", true);
        return false;
      } else {
        return this.context.dispatch("handleError", axiosError);
      }
    }
  }
}

export default SonarProjects;
