import { Alert, emptyAlert } from "@/models/Alert.model";
import {
  InterfaceCostCloudExcellence,
  emptyCostCloudExcellence,
} from "@/models/CostCloudExcellence.model";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse } from "axios";
import environmentConfig from "@/utils/environmentConfig";

interface FetchParams {
  costCenter: string;
  billingMonth: string;
}

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/cloud-excellence`;

@Module({ namespaced: true })
class CostCloudExcellence extends VuexModule {
  public selectedCloudExcellenceCost: InterfaceCostCloudExcellence =
    emptyCostCloudExcellence();
  public cloudExcellenceCostDetailAlert: Alert = emptyAlert();
  public costCloudExcellenceTableAlert: Alert = emptyAlert();
  public selectedCloudExcellenceCostsList: InterfaceCostCloudExcellence[] = [];
  public subscriptions: string[] = [];

  public billingMonth = "";
  public rangeDate: string[] = [];

  @Mutation
  public setSubscriptions(data: string[]): void {
    this.subscriptions = data;
  }

  @Mutation
  public setSelectedCloudExcellenceCost(
    data: InterfaceCostCloudExcellence
  ): void {
    this.selectedCloudExcellenceCost = data;
  }

  @Mutation
  public setCloudExcellenceCostDetailAlert(data: Alert): void {
    this.cloudExcellenceCostDetailAlert = data;
  }

  @Mutation
  public setSelectedCloudExcellenceCostsList(
    data: InterfaceCostCloudExcellence[]
  ): void {
    this.selectedCloudExcellenceCostsList = data;
  }

  @Mutation
  public setCloudExcellenceCostListAlert(data: Alert): void {
    this.costCloudExcellenceTableAlert = data;
  }

  @Mutation
  public clearCloudExcellenceCostListAlert(): void {
    this.costCloudExcellenceTableAlert = emptyAlert();
  }

  @Mutation
  public setBillingMonth(data: string): void {
    this.billingMonth = data;
  }
  @Mutation
  public setRangeDate(data: string[]): void {
    this.rangeDate = data;
  }

  @Action
  async getCloudExcellenceCosts(): Promise<boolean> {
    const allDates: InterfaceCostCloudExcellence[] = [];
    try {
      await Promise.all(
        this.rangeDate.map((date) => {
          return axios
            .get(`${baseApiUrl}?month=${date}`)
            .then((response: AxiosResponse) => {
              // For each item in response data push to allDates
              response.data.forEach((item: InterfaceCostCloudExcellence) => {
                allDates.push(item);
              });
            });
        })
      );
      this.context.commit("setSelectedCloudExcellenceCostsList", allDates);
      return true;
    } catch (error) {
      const axiosError = error as AxiosError;
      let alert: Alert;
      if (axiosError.response) {
        const data = axiosError.response.data as { message: string };
        alert = new Alert(axiosError.response.status, data.message);
      } else {
        alert = new Alert(0, axiosError.message);
      }
      this.context.commit("setCloudExcellenceCostListAlert", alert, {
        root: true,
      });
      return false;
    }
  }

  @Action
  public async getCloudExcellenceCost(
    fetchParams: FetchParams
  ): Promise<boolean> {
    const params = new URLSearchParams({
      costCenter: fetchParams.costCenter + "",
      billingMonth: fetchParams.billingMonth,
    }).toString();
    this.context.commit(
      "setSelectedCloudExcellenceCost",
      emptyCostCloudExcellence()
    );
    return axios
      .get(`${baseApiUrl}/report?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSelectedCloudExcellenceCost", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          const data = error.response.data as { message: string };
          alert = new Alert(error.response.status, data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setCloudExcellenceCostDetailAlert", alert);
        return false;
      });
  }
}

export default CostCloudExcellence;
