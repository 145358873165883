import { InterfaceOrganizationAudit } from "@/models/Organization.model";

import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { PageData } from "@/models/PageData.model";
import {
  emptyAuditLogRequest,
  InterfaceAuditLogRequest,
} from "@/models/Audit.model";
import { Alert } from "@/models/Alert.model";

const auditLogs = namespace("AuditLogs");

@Component({})
export default class AuditLogsOrganizations extends Vue {
  public datePicker = false;
  public dateRange: string[] = [];
  public auditLogRequest: InterfaceAuditLogRequest = emptyAuditLogRequest();

  @auditLogs.State
  public foundOrganizationAuditLogs!: InterfaceOrganizationAudit[];

  @auditLogs.State
  public pageDataOrganizationAuditLogs!: PageData;

  @auditLogs.State
  public auditLogsAlert!: Alert;

  @auditLogs.State
  public inProgress!: boolean;

  @auditLogs.Action
  public searchOrganizationAuditLogs!: (
    request: InterfaceAuditLogRequest
  ) => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const sonarServerId = [
      {
        text: "Sonar Server ID",
        align: "start",
        value: "sonarServerId",
        sortable: false,
      },
    ];
    const username = [
      {
        text: "User",
        align: "start",
        value: "username",
        sortable: false,
      },
    ];
    const revtstmp = [
      { text: "Time", align: "start", value: "revtstmp", sortable: true },
    ];
    const revtype = [
      { text: "Action", align: "start", value: "revtype", sortable: false },
    ];
    return revtype.concat(id, name, sonarServerId, username, revtstmp);
  }

  @Watch("pageDataOrganizationAuditLogs.options", {
    deep: true,
  })
  optionsChange(): void {
    this.auditLogRequest.pageData.options =
      this.pageDataOrganizationAuditLogs.options;
    this.auditLogRequest.dateRange = this.dateRange;
    this.searchOrganizationAuditLogs(this.auditLogRequest);
  }

  @Watch("auditLogRequest.keyword", {
    deep: true,
  })
  searchStringChange(): void {
    this.auditLogRequest.dateRange = this.dateRange;
    this.searchOrganizationAuditLogs(this.auditLogRequest);
  }

  public setDateRange(dateRange: string[]): void {
    this.auditLogRequest.dateRange = dateRange;
    this.datePicker = false;
    this.searchOrganizationAuditLogs(this.auditLogRequest);
  }

  public clearDateRange(): void {
    this.datePicker = false;
    this.dateRange = [];
    this.auditLogRequest.dateRange = [];
    this.searchOrganizationAuditLogs(this.auditLogRequest);
  }
}
