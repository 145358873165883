import { render, staticRenderFns } from "./PortalGroupServerMembership.vue?vue&type=template&id=14481b19"
import script from "@/components/portalGroups/PortalGroupServerMembership.component.ts?vue&type=script&lang=ts&external"
export * from "@/components/portalGroups/PortalGroupServerMembership.component.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports