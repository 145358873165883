import { Alert } from "@/models/Alert.model";
import { PageData } from "@/models/PageData.model";
import { InterfacePortalUser } from "@/models/PortalUser.model";
import {
  emptySonarServer,
  InterfaceSonarServer,
  SonarServer,
} from "@/models/SonarServer.model";
import router from "@/router";

import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { sanitizeUrl } from "@braintree/sanitize-url";

const sonarServers = namespace("SonarServers");
const portalUsers = namespace("PortalUsers");

@Component({})
export default class SonarServers extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public sonarServer: InterfaceSonarServer = emptySonarServer();
  public createSonarServerForm = false;
  public editSonarServerForm = false;
  public operationInProgress = false;
  public deleteSonarServerConfirmationForm = false;
  public updateSonarServerAccessTokenForm = false;
  public syncInProgress = false;
  public syncInProgressText = "";
  public syncInProgressType = "info";
  public hidden = false;

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    name: (value: string): string | boolean => {
      const pattern = /^[a-zA-Z0-9-_]{3,50}$/;
      const onlyNumbers = /^\d+$/;
      return (
        (!onlyNumbers.test(value) && pattern.test(value)) ||
        "Invalid server name. Names must be an alphanumeric (without spaces) at least 3 characters long and less than 50 characters."
      );
    },
    url: (value: string): string | boolean => {
      const pattern = /^((ht|f)tps?:\/\/)?[\w-]+(\.[\w-]+)+(:\d{1,5}\/?)?$/;
      return pattern.test(value) || "Invalid URL.";
    },
  };

  @Ref()
  createSonarServerFormElement!: HTMLFormElement;

  @Ref()
  editSonarServerFormElement!: HTMLFormElement;

  @Ref()
  updateSonarServerAccessTokenFormElement!: HTMLFormElement;

  @portalUsers.State
  public loggedInPortalUser!: InterfacePortalUser;

  @sonarServers.State
  public existingSonarServers!: InterfaceSonarServer[];

  @sonarServers.State
  public pageDataSonarServers!: PageData;

  @sonarServers.State
  public inProgress!: boolean;

  @sonarServers.State
  public sonarServerAlert!: Alert;

  @sonarServers.State
  public sonarServerTableAlert!: Alert;

  @sonarServers.Mutation
  public setPageData!: (pageData: PageData) => void;

  @sonarServers.Mutation
  public clearSonarServerAlert!: () => void;

  @sonarServers.Mutation
  public setSonarServerAlert!: (alert: Alert) => void;

  @sonarServers.Action
  public getSonarServers!: (pageData: PageData) => Promise<boolean>;

  @sonarServers.Action
  public storeSonarServer!: (
    sonarServer: InterfaceSonarServer
  ) => Promise<boolean>;

  @sonarServers.Action
  public updateSonarServer!: (
    sonarServer: InterfaceSonarServer
  ) => Promise<boolean>;

  @sonarServers.Action
  public updateSonarServerAccessToken!: (
    sonarServer: InterfaceSonarServer
  ) => Promise<boolean>;

  @sonarServers.Action
  public deleteSonarServer!: (
    sonarServer: InterfaceSonarServer
  ) => Promise<boolean>;

  @sonarServers.Action
  public verifyAccessToken!: (
    sonarServer: InterfaceSonarServer
  ) => Promise<boolean>;

  @sonarServers.Action
  public syncUsers!: (sonarServerId: string) => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const url = [
      {
        text: "Sonar Server URL",
        align: "start",
        value: "url",
        sortable: false,
      },
    ];
    const maintenance = [
      {
        text: "Maintenance",
        align: "start",
        value: "maintenance",
        sortable: false,
      },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return id.concat(name, url, maintenance, actions);
    }
    return name.concat(url, maintenance);
  }

  @Watch("pageDataSonarServers.options", {
    deep: true,
  })
  optionsChange(): void {
    this.getSonarServers(this.pageDataSonarServers);
  }

  public openSonarServerDetails(sonarServerId: string): void {
    router.push({
      name: "SonarServerDetails",
      params: { id: sonarServerId },
    });
  }

  public editSonarServerDialog(sonarServer: InterfaceSonarServer): void {
    this.sonarServer = new SonarServer(
      sonarServer.id,
      sonarServer.name,
      sonarServer.accessToken,
      sonarServer.showAccessToken,
      sonarServer.url,
      sonarServer.maintenance,
      sonarServer.organizationsCount,
      sonarServer.portalGroupsCount
    );
    this.editSonarServerForm = true;
  }

  public async editSonarServerDialogAction(
    sonarServer: InterfaceSonarServer
  ): Promise<void> {
    if (this.editSonarServerFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.updateSonarServer(sonarServer)) {
        this.closeEditSonarServerForm();
        this.$root.$emit("maintenance", sonarServer.maintenance);
      }
      this.operationInProgress = false;
      this.getSonarServers(this.pageDataSonarServers);
    }
  }

  public deleteSonarServerDialog(server: InterfaceSonarServer): void {
    this.sonarServer = server;
    this.deleteSonarServerConfirmationForm = true;
  }

  public async deleteSonarServerDialogAction(
    sonarServer: InterfaceSonarServer
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteSonarServer(sonarServer)) {
      this.closeDeleteSonarServerDialog();
    }
    this.operationInProgress = false;
    this.getSonarServers(this.pageDataSonarServers);
  }

  public updateSonarServerTokenDialog(sonarServer: InterfaceSonarServer): void {
    this.sonarServer = new SonarServer(
      sonarServer.id,
      sonarServer.name,
      sonarServer.accessToken,
      sonarServer.showAccessToken,
      sonarServer.url,
      sonarServer.maintenance,
      sonarServer.organizationsCount,
      sonarServer.portalGroupsCount
    );
    this.updateSonarServerAccessTokenForm = true;
  }

  public async updateSonarServerAccessTokenDialogAction(
    sonarServer: InterfaceSonarServer
  ): Promise<void> {
    if (this.updateSonarServerAccessTokenFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.verifyAccessToken(sonarServer)) {
        if (await this.updateSonarServerAccessToken(sonarServer)) {
          this.closeUpdateSonarServerAccessTokenForm();
        }
      }
      this.operationInProgress = false;
    }
  }

  public async storeSonarServerDialogAction(
    sonarServer: InterfaceSonarServer
  ): Promise<void> {
    if (this.createSonarServerFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.verifyAccessToken(sonarServer)) {
        if (await this.storeSonarServer(sonarServer)) {
          this.closeCreateSonarServerForm();
          this.getSonarServers(this.pageDataSonarServers);
        }
      }
      this.operationInProgress = false;
    }
  }

  private resetToPreSaveState() {
    this.clearSonarServerAlert();
    this.createSonarServerFormElement.reset();
  }

  public closeCreateSonarServerForm(): void {
    this.sonarServer = emptySonarServer();
    this.createSonarServerForm = false;
    this.resetToPreSaveState();
  }

  public closeEditSonarServerForm(): void {
    this.sonarServer = emptySonarServer();
    this.editSonarServerForm = false;
    this.clearSonarServerAlert();
  }

  public closeUpdateSonarServerAccessTokenForm(): void {
    this.sonarServer = emptySonarServer();
    this.updateSonarServerAccessTokenForm = false;
    this.clearSonarServerAlert();
  }

  public closeDeleteSonarServerDialog(): void {
    this.sonarServer = emptySonarServer();
    this.deleteSonarServerConfirmationForm = false;
    this.clearSonarServerAlert();
  }

  public async syncUsersForSonarServer(
    sonarServer: InterfaceSonarServer
  ): Promise<void> {
    this.syncInProgressText = "Synchronizing...";
    this.syncInProgressType = "warning";
    this.syncInProgress = true;
    const sync = this.syncUsers(sonarServer.id);
    if (await sync) {
      this.syncInProgressText = "Users successfully synchronized";
      this.syncInProgressType = "info";
      setInterval(() => {
        this.syncInProgress = false;
      }, 10000);
    } else {
      this.syncInProgressText =
        "User synchronization failed: " + this.sonarServerAlert.message;
      this.syncInProgressType = "error";
      setInterval(() => {
        this.syncInProgress = false;
      }, 10000);
    }
  }
  safeUrl(item: InterfaceSonarServer): string {
    const sanitizedUrl: string = sanitizeUrl(item.url);
    return sanitizedUrl;
  }

  public cannotDeleteSonarServer(sonarServer: InterfaceSonarServer): boolean {
    if (
      sonarServer.organizationsCount > 0 ||
      sonarServer.portalGroupsCount > 0
    ) {
      return true;
    } else return false;
  }
}
