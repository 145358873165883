import { PageData } from "./PageData.model";

export interface InterfaceSubscription {
  id: string;
  name: string;
  initialOwnerId: string;
  subscriptionType: string;
  organizationId: string;
  organizationName: string;
  managementGroupId: string;
  managementGroupName: string;
  subscriptionState: string;
  costCenterId: string;
  costCenterName: string;
  remoteId: string;
}

export interface InterfaceSubscriptionAudit {
  rev: number;
  id: string;
  remoteId: string;
  name: string;
  subscriptionType: string;
  organizationId: string;
  managementGroupId: string;
  costCenterId: string;
  username: string;
  timestamp: string;
  revtype: number;
}

export const emptySubscription = (): Subscription => ({
  id: "",
  name: "",
  initialOwnerId: "",
  subscriptionType: "",
  organizationId: "",
  organizationName: "",
  managementGroupId: "",
  managementGroupName: "",
  costCenterId: "",
  subscriptionState: "",
  costCenterName: "",
  remoteId: "",
});

export class Subscription implements InterfaceSubscription {
  constructor(
    public id: string,
    public name: string,
    public initialOwnerId: string,
    public subscriptionType: string,
    public organizationId: string,
    public organizationName: string,
    public managementGroupId: string,
    public managementGroupName: string,
    public subscriptionState: string,
    public costCenterId: string,
    public costCenterName: string,
    public remoteId: string
  ) {}
}

export interface InterfaceSubscriptionSearchResult {
  paging: PageData;
  subscriptions: InterfaceSubscription[];
}
