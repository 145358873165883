import { Alert } from "@/models/Alert.model";
import { PageData } from "@/models/PageData.model";
import {
  emptySearchByKeywordRequest,
  SearchByKeywordRequest,
} from "@/models/Search.model";
import {
  emptySonarGroup,
  InterfaceSonarGroup,
  InterfaceSonarGroupSearchRequest,
  InterfaceSonarGroupSearchResult,
} from "@/models/SonarGroup.model";
import {
  InterfacePagedSonarUserMembership,
  InterfaceSonarUser,
} from "@/models/SonarUser.model";
import Vue from "vue";
import Component from "vue-class-component";
import { Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const sonarGroups = namespace("SonarGroups");
const sonarUsers = namespace("SonarUsers");

@Component({})
export default class SonarUserGroupMembership extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public addSonarGroupForm = false;
  public removeConfirmationForm = false;
  public hidden = false;
  public selectedSonarGroupId = "";
  public searchSonarGroups = null;
  public sonarGroup: InterfaceSonarGroup = emptySonarGroup();
  public operationInProgress = false;
  public searchRequest: SearchByKeywordRequest = emptySearchByKeywordRequest();

  @Ref()
  addSonarGroupFormElement!: HTMLFormElement;

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
  };

  @sonarGroups.State
  public existingSonarGroups!: InterfaceSonarGroup[];

  @sonarGroups.State
  public foundSonarGroups!: InterfaceSonarGroup[];

  @sonarGroups.State
  public pageDataSonarGroups!: PageData;

  @sonarUsers.State
  public selectedSonarUser!: InterfaceSonarUser;

  @sonarUsers.State
  public sonarUserGroupMembership!: InterfaceSonarGroup[];

  @sonarUsers.State
  public pageDataSonarUserGroups!: PageData;

  @sonarUsers.State
  public sonarUserAlert!: Alert;

  @sonarGroups.Action
  public getSonarGroups!: (
    searchRequest: SearchByKeywordRequest
  ) => Promise<boolean>;

  @sonarGroups.Action
  public searchSonarGroup!: (
    searchRequest: InterfaceSonarGroupSearchRequest
  ) => Promise<InterfaceSonarGroupSearchResult>;

  @sonarUsers.Action
  public getSonarUserGroupMembership!: (
    pagedSonarUserMembership: InterfacePagedSonarUserMembership
  ) => Promise<boolean>;

  @sonarUsers.Action
  public addSonarUserToSonarGroup!: (sonarGroupId: string) => Promise<boolean>;

  @sonarUsers.Action
  public removeSonarUserFromSonarGroup!: (
    sonarGroupId: string
  ) => Promise<boolean>;

  @sonarUsers.Mutation
  public clearSonarUserAlert!: () => void;

  @Watch("searchSonarGroups")
  onPropertyChanged(value: string): void {
    if (value && value.length > 2) {
      const sonarGroupSearchRequest: InterfaceSonarGroupSearchRequest = {
        name: value,
        sonarServerId: this.selectedSonarUser.sonarServerId,
        pageIndex: 1,
        pageSize: 100,
      };
      this.searchSonarGroup(sonarGroupSearchRequest);
    }
  }

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const permission = [
      {
        text: "Permission",
        align: "start",
        value: "permission",
        sortable: false,
      },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return id.concat(name, permission, actions);
    }
    return name.concat(permission, actions);
  }

  @Watch("pageDataSonarUserGroups.options", {
    deep: true,
  })
  optionsChange(): void {
    this.getSonarUserGroupMembership({
      sonarUserId: this.$router.currentRoute.params.id,
      pageIndex: 1,
      pageSize: 25,
    });
    this.getSonarGroups(this.searchRequest);
  }

  public toggleSelectedSonarGroup(id: string): void {
    this.selectedSonarGroupId = id;
  }

  public async addSonarGroupDialogAction(): Promise<void> {
    this.operationInProgress = true;
    if (await this.addSonarUserToSonarGroup(this.selectedSonarGroupId)) {
      this.closeAddSonarGroupDiaglog();
    }
    this.operationInProgress = false;
  }

  public closeAddSonarGroupDiaglog(): void {
    this.addSonarGroupForm = false;
    this.addSonarGroupFormElement.reset();
    this.clearSonarUserAlert();
  }

  public removeSonarGroupDialog(sonarGroup: InterfaceSonarGroup): void {
    this.sonarGroup = sonarGroup;
    this.removeConfirmationForm = true;
  }

  public async removeSonarGroup(
    sonarGroup: InterfaceSonarGroup
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.removeSonarUserFromSonarGroup(sonarGroup.id)) {
      this.closeRemoveSonarGroupDialog();
    }
    this.operationInProgress = false;
  }

  public closeRemoveSonarGroupDialog(): void {
    this.removeConfirmationForm = false;
    this.clearSonarUserAlert();
  }
}
