import { PageData } from "./PageData.model";

export interface InterfaceOrganization {
  id: string;
  name: string;
  sonarServerId: string;
  sonarServerName: string;
  sonarProjectsCount: number;
  sonarGroupsCount: number;
  portalGroupsCount: number;
  costCentersCount: number;
  subscriptionsCount: number;
}

export interface InterfaceOrganizationAudit {
  rev: number;
  id: string;
  name: string;
  sonarServerId: string;
  username: string;
  timestamp: string;
  revtype: number;
}

export const emptyOrganization = (): Organization => ({
  id: "",
  name: "",
  sonarServerId: "",
  sonarServerName: "",
  sonarProjectsCount: 0,
  sonarGroupsCount: 0,
  portalGroupsCount: 0,
  costCentersCount: 0,
  subscriptionsCount: 0,
});

export class Organization implements InterfaceOrganization {
  constructor(
    public id: string,
    public name: string,
    public sonarServerId: string,
    public sonarServerName: string,
    public sonarProjectsCount: number,
    public sonarGroupsCount: number,
    public portalGroupsCount: number,
    public costCentersCount: number,
    public subscriptionsCount: number
  ) {}
}

export interface InterfaceOrganizationSearchRequest {
  name: string;
  pageIndex: number;
  pageSize: number;
}

export interface InterfaceOrganizationSearchResult {
  paging: PageData;
  organizations: InterfaceOrganization[];
}
