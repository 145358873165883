import { Alert, emptyAlert } from "@/models/Alert.model";
import {
  emptyOrganization,
  InterfaceOrganization,
  InterfaceOrganizationSearchRequest,
  InterfaceOrganizationSearchResult,
} from "@/models/Organization.model";
import { emptyPageData, PageData } from "@/models/PageData.model";
import { SearchByKeywordRequest } from "@/models/Search.model";
import { InterfaceSonarProject } from "@/models/SonarProject.model";
import environmentConfig from "@/utils/environmentConfig";
import axios, { AxiosError, AxiosResponse } from "axios";
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/organizations`;

interface FetchParams {
  search: string;
  page: number;
}

@Module({ namespaced: true })
class Organizations extends VuexModule {
  public existingOrganizations: InterfaceOrganization[] = [];
  public pageDataOrganizations: PageData = emptyPageData();
  public pageDataSearchOrganizations: PageData = emptyPageData();
  public selectedOrganization: InterfaceOrganization = emptyOrganization();
  public organizationProjectMembership: InterfaceSonarProject[] = [];
  public organizationProjectsPageData: PageData = emptyPageData();
  public foundOrganizations: InterfaceOrganization[] = [];
  public organizationAlert: Alert = emptyAlert();
  public organizationTableAlert: Alert = emptyAlert();
  public organizationDetailAlert: Alert = emptyAlert();
  public inProgress = false;
  public organizationsSelectList: InterfaceOrganization[] = [];
  public organizationAutocompleteLoadMore = false;

  @Mutation
  public setOrganizations(data: InterfaceOrganization[]): void {
    this.existingOrganizations = data;
  }

  @Mutation
  public setOrganizationsSelectList(data: InterfaceOrganization[]): void {
    this.organizationsSelectList = data;
  }

  @Mutation
  public setLoadMore(boolean: boolean): void {
    this.organizationAutocompleteLoadMore = boolean;
  }

  @Mutation
  public setPageData(pageData: PageData): void {
    this.pageDataOrganizations.totalItems = pageData.totalItems;
  }

  @Mutation
  public setSearchPageData(pageData: PageData): void {
    this.pageDataSearchOrganizations.totalPages = pageData.totalPages;
  }

  @Mutation
  public setSelectedOrganization(data: InterfaceOrganization): void {
    this.selectedOrganization = data;
  }

  @Mutation
  public setOrganizationProjectMembership(data: InterfaceSonarProject[]): void {
    this.organizationProjectMembership = data;
  }

  @Mutation
  public setOrganizationProjectsPageData(pageData: PageData): void {
    this.organizationProjectsPageData.totalItems = pageData.totalItems;
  }

  @Mutation
  public setFoundOrganizations(data: InterfaceOrganization[]): void {
    this.foundOrganizations = data;
  }

  @Mutation
  public setOrganizationAlert(data: Alert): void {
    this.organizationAlert = data;
  }

  @Mutation
  public clearOrganizationAlert(): void {
    this.organizationAlert = emptyAlert();
  }

  @Mutation
  public setOrganizationTableAlert(data: Alert): void {
    this.organizationTableAlert = data;
  }

  @Mutation
  public clearOrganizationTableAlert(): void {
    this.organizationTableAlert = emptyAlert();
  }

  @Mutation
  public setOrganizationDetailAlert(data: Alert): void {
    this.organizationDetailAlert = data;
  }

  @Mutation
  public clearOrganizationDetailAlert(): void {
    this.organizationDetailAlert = emptyAlert();
  }

  @Mutation
  public setInProgress(data: boolean): void {
    this.inProgress = data;
  }

  @Action
  public handleError(error: AxiosError): boolean {
    let alert: Alert;
    if (error.response) {
      const data = error.response.data as { message: string };
      alert = new Alert(error.response.status, data.message);
    } else {
      alert = new Alert(0, error.message);
    }
    this.context.commit("setOrganizationAlert", alert);
    return false;
  }

  @Action
  public async storeOrganization(
    organization: InterfaceOrganization
  ): Promise<boolean> {
    return axios
      .post(`${baseApiUrl}`, {
        name: organization.name,
        sonarServerId: organization.sonarServerId,
      })
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async getOrganizationsSelectList(
    fetchParams: FetchParams
  ): Promise<boolean> {
    const params = new URLSearchParams({
      pageIndex: fetchParams.page + "",
      pageSize: `5`,
    }).toString();
    let searchKeyword = "";
    if (fetchParams.search != "" && fetchParams.search != null) {
      searchKeyword = "/search/".concat(fetchParams.search);
    }
    return axios
      .get(`${baseApiUrl}${searchKeyword}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setOrganizationsSelectList", response.data.list);
        this.context.commit("setSearchPageData", response.data.paging);
        if (response.data.paging.pageIndex < response.data.paging.totalPages) {
          this.context.commit("setLoadMore", true);
        } else {
          this.context.commit("setLoadMore", false);
        }
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Action
  public async getOrganizations(
    searchRequest: SearchByKeywordRequest
  ): Promise<boolean> {
    const params = new URLSearchParams({
      pageIndex: `${searchRequest.pageData.options.page}`,
      pageSize: `${searchRequest.pageData.options.itemsPerPage}`,
    }).toString();
    let searchKeyword = "";
    if (searchRequest.keyword != "" && searchRequest.keyword != undefined) {
      searchKeyword = "/search/" + searchRequest.keyword;
    }
    this.context.commit("setInProgress", true);
    this.context.commit("clearOrganizationTableAlert");

    return axios
      .get(`${baseApiUrl}${searchKeyword}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setOrganizations", response.data.list);
        this.context.commit("setPageData", response.data.paging);
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setInProgress", false);
        this.context.commit(
          "setOrganizationTableAlert",
          new Alert(0, error.message)
        );
        return false;
      });
  }

  @Action
  public async getOrganization(id: string): Promise<boolean> {
    this.context.commit("setSelectedOrganization", emptyOrganization());
    return axios
      .get(`${baseApiUrl}/${id}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSelectedOrganization", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          const data = error.response.data as { message: string };
          alert = new Alert(error.response.status, data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setOrganizationDetailAlert", alert);
        return false;
      });
  }

  @Action
  public async getSonarServerOrganizationMembership(
    sonarServerId: string
  ): Promise<boolean> {
    this.context.commit("setOrganizations", []);
    const params = new URLSearchParams({
      pageIndex: `1`,
      pageSize: `100`,
    }).toString();
    return axios
      .get(
        `${environmentConfig(
          "VUE_APP_BACKEND_PATH"
        )}/api/sonar-servers/${sonarServerId}/organizations?${params}`
      )
      .then((response: AxiosResponse) => {
        this.context.commit("setOrganizations", response.data.list);
        this.context.commit("setPageData", response.data.paging);
        return true;
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
      });
  }

  @Action
  public async updateOrganization(
    organization: InterfaceOrganization
  ): Promise<boolean> {
    return axios
      .put(`${baseApiUrl}/${organization.id}`, organization)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async deleteOrganization(
    organization: InterfaceOrganization
  ): Promise<boolean> {
    return axios
      .delete(`${baseApiUrl}/${organization.id}`)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async searchOrganization(
    searchRequest: InterfaceOrganizationSearchRequest
  ): Promise<InterfaceOrganizationSearchResult> {
    this.context.commit("setFoundOrganizations", []);
    const params = new URLSearchParams({
      pageIndex: `${searchRequest.pageIndex}`,
      pageSize: `${searchRequest.pageSize}`,
    }).toString();
    return axios
      .get(`${baseApiUrl}/search/${searchRequest.name}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setFoundOrganizations", response.data.list);
        return <InterfaceOrganizationSearchResult>{
          paging: response.data.paging,
          organizations: response.data.list,
        };
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return <InterfaceOrganizationSearchResult>{};
      });
  }
}

export default Organizations;
