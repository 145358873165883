
import { Component, Prop, Vue } from "vue-property-decorator";

type ValueProp = string | number | null;

@Component
export default class ServerSideAutocomplete extends Vue {
  @Prop({ required: true }) fetchItemsMethod!: (
    search: string,
    page: number
  ) => Promise<[]>;

  @Prop()
  onChange!: (val: unknown) => void;

  @Prop() label!: string;

  @Prop() loadMore!: boolean;

  @Prop() rules!: [undefined];

  @Prop() disabled!: [undefined];

  @Prop({ default: null }) value!: ValueProp;

  get localValue(): ValueProp {
    return this.value;
  }

  set localValue(val: ValueProp) {
    this.$emit("input", val);
  }

  items: unknown[] = [];
  searchInput = "";
  loading = false;
  page!: number;

  async created(): Promise<void> {
    this.$watch("searchInput", this.fetchItems);
  }

  beforeDestroy(): void {
    this.page = 1;
    this.items = [];
  }

  handleInput(): void {
    if (this.onChange && this.localValue != null) {
      this.onChange(this.localValue);
    }
  }

  async fetchItems(): Promise<void> {
    this.loading = true;
    this.page = 1;
    this.items = await this.mapper();
    this.loading = false;
  }

  async appendItems(): Promise<void> {
    ++this.page;
    this.items = this.items.concat(await this.mapper());
  }

  async mapper(): Promise<{ text: string; value: string }[]> {
    return (await this.fetchItemsMethod(this.searchInput, this.page)).map(
      (fetchedItem: { name: string; id: string }) => {
        return { text: fetchedItem.name, value: fetchedItem.id };
      }
    );
  }
}
