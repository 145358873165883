export interface InterfaceCostCloudExcellence {
  id: string;
  creation: string;
  subscriptionName: string;
  billingMonth: string;
  totalCost: number;
  currency: string;
  serviceId: string;
  chargedBy: string;
  costCenter: string;
  costCenterResponsible: string;
  costCenterResponsibleEmailAddress: string;
  costCenterNotificationUsers: string[];
  cloudExcellenceCostDetails: InterfaceCostCloudExcellenceDetails;
}

export interface InterfaceCostCloudExcellenceDetails {
  subscriptions: string[];
}

export const emptyCostCloudExcellence = (): InterfaceCostCloudExcellence => ({
  id: "",
  creation: "",
  subscriptionName: "",
  billingMonth: "",
  totalCost: 0,
  currency: "",
  serviceId: "",
  chargedBy: "",
  costCenter: "",
  costCenterResponsible: "",
  costCenterResponsibleEmailAddress: "",
  costCenterNotificationUsers: [],
  cloudExcellenceCostDetails: emptyCostCloudExcellenceDetails(),
});

export const emptyCostCloudExcellenceDetails =
  (): InterfaceCostCloudExcellenceDetails => ({
    subscriptions: [],
  });

export class CostCloudExcellence implements InterfaceCostCloudExcellence {
  constructor(
    public id: string,
    public creation: string,
    public subscriptionName: string,
    public billingMonth: string,
    public totalCost: number,
    public currency: string,
    public serviceId: string,
    public chargedBy: string,
    public costCenter: string,
    public costCenterResponsible: string,
    public costCenterResponsibleEmailAddress: string,
    public costCenterNotificationUsers: string[],
    public cloudExcellenceCostDetails: InterfaceCostCloudExcellenceDetails
  ) {}
}
