import Vue from "vue";
import { Component } from "vue-property-decorator";
import Organizations from "@/views/organizations/Organizations.vue";
import PortalGroupUserMembership from "@/views/portalGroups/PortalGroupUserMembership.vue";
import PortalGroupServerMembership from "@/views/portalGroups/PortalGroupServerMembership.vue";
import PortalGroupOrganizationMembership from "@/views/portalGroups/PortalGroupOrganizationMembership.vue";
import { namespace } from "vuex-class";
import { InterfacePortalGroup } from "@/models/PortalGroup.model";
import { Alert } from "@/models/Alert.model";

const portalGroups = namespace("PortalGroups");

@Component({
  components: {
    Organizations,
    PortalGroupUserMembership,
    PortalGroupServerMembership,
    PortalGroupOrganizationMembership,
  },
})
export default class PortalGroupDetails extends Vue {
  sonarGroupsDialog = false;
  showSonarServers = false;
  showOrganizations = false;
  showPortalUsers = true;

  @portalGroups.State
  public selectedPortalGroup!: InterfacePortalGroup;

  @portalGroups.State
  public portalGroupDetailAlert!: Alert;

  @portalGroups.Action
  public getPortalGroup!: (id: string) => Promise<boolean>;

  mounted(): void {
    this.getPortalGroup(this.$router.currentRoute.params.id);
  }

  public reload(): void {
    window.location.reload();
  }
}
