import Vue from "vue";
import { Component, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import {
  InterfaceServiceAgreement,
  emptyServiceAgreement,
} from "@/models/ServiceAgreement.model";
import { Alert } from "@/models/Alert.model";
import { PageData } from "@/models/PageData.model";
import { InterfaceOrganization } from "@/models/Organization.model";
import ServerSideAutocomplete from "../serverSideAutocomplete/ServerSideAutocomplete.vue";

const serviceAgreements = namespace("ServiceAgreements");
const organizations = namespace("Organizations");

interface Service {
  serviceName: string;
  serviceType: string;
  sourceServiceAgreement: string;
  cost: number;
  billingMonth: string;
}

interface FetchParams {
  search: string;
  page: number;
}

@Component({ components: { ServerSideAutocomplete } })
export default class ServiceAgreementDetails extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  sonarGroupsDialog = false;
  startDate = new Date(new Date().setMonth(new Date().getMonth() - 2))
    .toISOString()
    .slice(0, 7);
  endDate = new Date(new Date().setMonth(new Date().getMonth() - 1))
    .toISOString()
    .slice(0, 7);
  public selectedStartDate = new Date(
    new Date().setMonth(new Date().getMonth() - 2)
  )
    .toISOString()
    .slice(0, 7);
  public selectedEndDate = new Date(
    new Date().setMonth(new Date().getMonth() - 1)
  )
    .toISOString()
    .slice(0, 7);

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    companyName: (value: string): string | boolean => {
      const pattern = /^[0-9a-zA-Z_. -]{3,1000}$/;
      return (
        pattern.test(value) ||
        "Invalid value. Company Names must be a alphanumeric and at least 3 characters long and less than 1000 characters. The following symbols are allowed: . - _"
      );
    },
    departmentName: (value: string): string | boolean => {
      const pattern = /^[0-9a-zA-Z_\s\n-.,:/]{3,500}$/;
      return (
        pattern.test(value) ||
        "Invalid value. Department Names must be a alphanumeric and at least 3 characters long and less than 500 characters. The following symbols are allowed: . , - _ : /"
      );
    },
    name: (value: string): string | boolean => {
      const pattern = /^[a-zA-Z0-9-_/]{3,50}$/;
      return (
        pattern.test(value) ||
        "Invalid ServiceAgreement ID. Names must be a alphanumeric (without spaces) and at least 3 characters long and less than 50 characters."
      );
    },
    alphanumericAndSymbols: (value: string): string | boolean => {
      const pattern = /^[a-zA-Z 0-9_-\s\S.,:/]{3,500}$/;
      return (
        pattern.test(value) ||
        "Invalid value. Value must be a alphanumeric and at least 3 characters long and less than 500 characters."
      );
    },
    email: (value: string): string | boolean => {
      const pattern =
        /^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/;
      return pattern.test(value) || "Invalid Email Address.";
    },
  };

  public currencyTypeSelectList: string[] = ["EUR", "USD"];
  public paymentScheduleTypeSelectList: string[] = [
    "MONTHLY",
    "QUARTERLY",
    "ONE_TIME",
  ];
  public statusTypeSelectList: string[] = [
    "DRAFT",
    "PENDING",
    "APPROVED",
    "REJECTED",
    "COMPLETED",
    "FAILED",
    "CLOSED",
  ];

  @Ref()
  editServiceAgreementFormElement!: HTMLFormElement;

  public endDateMenu = false;
  public startDateMenu = false;
  public items: Service[] = [];
  public rangeDate: string[] = [];
  public editServiceAgreementForm = false;
  public deleteConfirmationForm = false;
  public sendServiceAgreementForm = false;
  public operationInProgress = false;
  public serviceAgreement: InterfaceServiceAgreement = emptyServiceAgreement();

  @serviceAgreements.State
  public selectedServiceAgreement!: InterfaceServiceAgreement;

  @serviceAgreements.State
  public serviceAgreementDetailAlert!: Alert;

  @serviceAgreements.Mutation
  public setBillingMonth!: (data: string) => void;

  @serviceAgreements.Mutation
  public setRangeDate!: (data: string[]) => void;

  @serviceAgreements.Mutation
  public clearServiceAgreementAlert!: () => void;

  @serviceAgreements.Action
  public getServiceAgreement!: (id: string) => Promise<boolean>;

  @serviceAgreements.Action
  public updateServiceAgreement!: (
    serviceAgreement: InterfaceServiceAgreement
  ) => Promise<boolean>;

  @serviceAgreements.Action
  public sendServiceAgreement!: (
    serviceAgreement: InterfaceServiceAgreement
  ) => Promise<boolean>;

  @serviceAgreements.Action
  public deleteServiceAgreement!: (
    serviceAgreement: InterfaceServiceAgreement
  ) => Promise<boolean>;

  @organizations.State
  public pageDataOrganizations!: PageData;

  @organizations.State
  public organizationsSelectList!: InterfaceOrganization[];

  @organizations.State
  public organizationAutocompleteLoadMore!: boolean;

  @organizations.Action
  public getOrganizationsSelectList!: (
    fetchParams: FetchParams
  ) => Promise<boolean>;

  async mounted(): Promise<void> {
    await this.getServiceAgreement(this.$router.currentRoute.params.id);
  }

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const serviceName = [
      {
        text: "Service Name",
        align: "start",
        value: "serviceName",
        sortable: false,
      },
    ];
    const serviceType = [
      {
        text: "Service Type",
        align: "start",
        value: "serviceType",
        sortable: false,
      },
    ];
    const sourceServiceAgreement = [
      {
        text: "Source Cost Center",
        align: "start",
        value: "sourceServiceAgreement",
        sortable: false,
      },
    ];
    const cost = [
      {
        text: "Cost",
        align: "start",
        value: "cost",
        sortable: false,
      },
    ];
    const billingMonth = [
      {
        text: "Billing Month",
        align: "start",
        value: "billingMonth",
        sortable: false,
      },
    ];

    return serviceName.concat(
      serviceType,
      sourceServiceAgreement,
      cost,
      billingMonth
    );
  }

  public reload(): void {
    window.location.reload();
  }
  @Watch("startDate", {
    deep: true,
  })
  endDateUpdate(): void {
    if (new Date(this.startDate) > new Date(this.endDate)) {
      this.endDate = this.startDate;
    }
  }

  getTotalCost(): number {
    return this.items.reduce((total, item) => total + item.cost, 0);
  }

  public editServiceAgreementDialog(
    serviceAgreement: InterfaceServiceAgreement
  ): void {
    this.serviceAgreement = serviceAgreement;
    this.editServiceAgreementForm = true;
  }

  public sendServiceAgreementDialog(
    serviceAgreement: InterfaceServiceAgreement
  ): void {
    this.serviceAgreement = serviceAgreement;
    this.sendServiceAgreementForm = true;
  }

  public deleteServiceAgreementDialog(): void {
    this.deleteConfirmationForm = true;
  }

  public closeEditServiceAgreementForm(): void {
    this.editServiceAgreementForm = false;
    this.clearServiceAgreementAlert();
  }

  public closeSendServiceAgreementForm(): void {
    this.sendServiceAgreementForm = false;
    this.clearServiceAgreementAlert();
  }

  public closeDeleteServiceAgreementDialog(): void {
    this.deleteConfirmationForm = false;
    this.clearServiceAgreementAlert();
  }

  public async fetchCreateServiceAgreementOrganizationSelectList(
    search: string,
    page: number
  ): Promise<Array<unknown>> {
    this.pageDataOrganizations.pageIndex = page;
    await this.getOrganizationsSelectList({
      search: search,
      page: page,
    });
    return this.organizationsSelectList;
  }

  public async deleteServiceAgreementDialogAction(
    serviceAgreement: InterfaceServiceAgreement
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteServiceAgreement(serviceAgreement)) {
      this.closeDeleteServiceAgreementDialog();
    }
    this.operationInProgress = false;
    this.$router.push("/costs/service-agreements");
  }

  public async editServiceAgreementDialogAction(
    serviceAgreement: InterfaceServiceAgreement
  ): Promise<void> {
    if (serviceAgreement.status === "APPROVED") {
      serviceAgreement.dateSigned = new Date();
    }
    if (this.editServiceAgreementFormElement.validate()) {
      this.operationInProgress = true;

      if (await this.updateServiceAgreement(serviceAgreement)) {
        this.closeEditServiceAgreementForm();
      }
      this.operationInProgress = false;
      this.$router.go(0);
    }
  }

  public async sendServiceAgreementDialogAction(
    serviceAgreement: InterfaceServiceAgreement
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.sendServiceAgreement(serviceAgreement)) {
      serviceAgreement.status = "PENDING";
      serviceAgreement.dateSent = new Date();
      this.updateServiceAgreement(serviceAgreement);
      this.closeSendServiceAgreementForm();
    }
    this.operationInProgress = false;
  }

  cancelEditStartDate(): void {
    this.startDateMenu = false;
    this.selectedStartDate = this.startDate;
  }

  selectEditStartDate(): void {
    this.startDateMenu = false;
  }

  selectEditEndDate(): void {
    this.endDateMenu = false;
  }

  cancelEditEndDate(): void {
    this.endDateMenu = false;
    this.selectedEndDate = this.endDate;
  }
}
