declare global {
  interface Window {
    deploymentConfiguration: Record<string, string>;
  }
}

export default function environmentConfig(name: string): string {
  if (process.env.NODE_ENV != "production") {
    return process.env[name];
  } else {
    return window.deploymentConfiguration[name] || process.env[name];
  }
}
