import { PageData } from "@/models/PageData.model";
import {
  InterfacePortalGroup,
  InterfacePagedPortalGroupMembership,
} from "@/models/PortalGroup.model";
import {
  emptyOrganization,
  InterfaceOrganization,
  InterfaceOrganizationSearchRequest,
  InterfaceOrganizationSearchResult,
} from "@/models/Organization.model";
import Vue from "vue";
import Component from "vue-class-component";
import { Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Alert } from "@/models/Alert.model";
import {
  emptySearchByKeywordRequest,
  SearchByKeywordRequest,
} from "@/models/Search.model";

const portalGroups = namespace("PortalGroups");
const organizations = namespace("Organizations");

@Component({})
export default class PortalGroupOrganizationMembership extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public addOrganizationForm = false;
  public removeConfirmationForm = false;
  public hidden = false;
  public selectedOrganizationId = "";
  public organization: InterfaceOrganization = emptyOrganization();
  public searchOrganizations = null;
  public operationInProgress = false;
  public searchRequest: SearchByKeywordRequest = emptySearchByKeywordRequest();

  @Ref()
  addOrganizationFormElement!: HTMLFormElement;

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
  };

  @portalGroups.Mutation
  public clearPortalGroupAlert!: () => void;

  @organizations.State
  public existingOrganizations!: InterfaceOrganization[];

  @organizations.State
  public foundOrganizations!: InterfaceOrganization[];

  @portalGroups.State
  public selectedPortalGroup!: InterfacePortalGroup;

  @portalGroups.State
  public portalGroupAlert!: Alert;

  @organizations.State
  public pageDataOrganizations!: PageData;

  @portalGroups.State
  public portalGroupOrganizationMembership!: InterfaceOrganization[];

  @portalGroups.State
  public pageDataPortalGroupOrganizations!: PageData;

  @organizations.Action
  public getOrganizations!: (
    searchRequest: SearchByKeywordRequest
  ) => Promise<boolean>;

  @organizations.Action
  public searchOrganization!: (
    searchRequest: InterfaceOrganizationSearchRequest
  ) => Promise<InterfaceOrganizationSearchResult>;

  @portalGroups.Action
  public getPortalGroupOrganizationMembership!: (
    pagedPortalGroupMembership: InterfacePagedPortalGroupMembership
  ) => Promise<boolean>;

  @portalGroups.Action
  public addOrganizationToPortalGroup!: (
    portalGroupId: string
  ) => Promise<boolean>;

  @portalGroups.Action
  public removeOrganizationFromPortalGroup!: (
    organizationId: string
  ) => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      {
        text: "Name",
        align: "start",
        value: "name",
        sortable: false,
        class: "nameColumn",
      },
    ];
    const actions = [
      {
        text: "Actions",
        align: "start",
        value: "actions",
        sortable: false,
        class: "actionsColumn",
      },
    ];
    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return id.concat(name, actions);
    }
    return name;
  }

  @Watch("pageDataPortalGroupOrganizations.options", {
    deep: true,
  })
  optionsChange(): void {
    this.getOrganizations(this.searchRequest);
    this.getPortalGroupOrganizationMembership({
      portalGroupId: this.$router.currentRoute.params.id,
      pageIndex: this.pageDataPortalGroupOrganizations.options.page,
      pageSize: this.pageDataPortalGroupOrganizations.options.itemsPerPage,
    });
  }

  public toggleSelectedOrganization(id: string): void {
    this.selectedOrganizationId = id;
  }

  public async addOrganizationDialogAction(): Promise<void> {
    this.operationInProgress = true;
    if (await this.addOrganizationToPortalGroup(this.selectedOrganizationId)) {
      this.closeAddOrganizationForm();
    }
    this.operationInProgress = false;
  }

  public closeAddOrganizationForm(): void {
    this.addOrganizationForm = false;
    this.addOrganizationFormElement.reset();
    this.clearPortalGroupAlert();
  }

  public removeOrganizationDialog(organization: InterfaceOrganization): void {
    this.organization = organization;
    this.removeConfirmationForm = true;
  }

  public async removeOrganization(
    organization: InterfaceOrganization
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.removeOrganizationFromPortalGroup(organization.id)) {
      this.closeRemoveOrganizationDialog();
    }
    this.operationInProgress = false;
  }

  public closeRemoveOrganizationDialog(): void {
    this.removeConfirmationForm = false;
    this.clearPortalGroupAlert();
  }

  @Watch("searchOrganizations")
  onPropertyChanged(value: string): void {
    if (value && value.length > 2) {
      const organizationSearchRequest: InterfaceOrganizationSearchRequest = {
        name: value,
        pageIndex: 1,
        pageSize: 100,
      };

      this.searchOrganization(organizationSearchRequest);
    }
  }
}
