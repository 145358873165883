export interface InterfaceAlert {
  status: number;
  message: string;
}

export const emptyAlert = (): Alert => ({
  status: 0,
  message: "",
});

export class Alert implements InterfaceAlert {
  constructor(public status: number, public message: string) {}
}
