export interface InterfaceSonarServer {
  id: string;
  name: string;
  accessToken: string;
  showAccessToken: boolean;
  url: string;
  maintenance: boolean;
  organizationsCount: number;
  portalGroupsCount: number;
}

export interface InterfaceSonarServerAudit {
  rev: number;
  id: string;
  name: string;
  url: string;
  maintenance: boolean;
  username: string;
  timestamp: string;
  revtype: number;
}

export const emptySonarServer = (): SonarServer => ({
  id: "",
  name: "",
  accessToken: "",
  showAccessToken: false,
  url: "",
  maintenance: false,
  organizationsCount: 0,
  portalGroupsCount: 0,
});

export class SonarServer implements InterfaceSonarServer {
  constructor(
    public id: string,
    public name: string,
    public accessToken: string,
    public showAccessToken: boolean,
    public url: string,
    public maintenance: boolean,
    public organizationsCount: number,
    public portalGroupsCount: number
  ) {}
}
