
import { Component, Prop, Vue } from "vue-property-decorator";

type ValueProp = string | number | null;

@Component
export default class SearchInput extends Vue {
  @Prop() label!: string;
  @Prop() clearable!: boolean;
  @Prop({ default: null }) value!: ValueProp;

  get internalValue(): ValueProp {
    return this.value;
  }

  set internalValue(val: ValueProp) {
    this.$emit("input", val);
  }
  updateValue(value: string): void {
    this.internalValue = value;
  }
}
