import { Alert } from "@/models/Alert.model";
import { PageData } from "@/models/PageData.model";
import {
  InterfacePagedPortalGroupMembership,
  InterfacePortalGroup,
} from "@/models/PortalGroup.model";
import {
  emptySonarServer,
  InterfaceSonarServer,
} from "@/models/SonarServer.model";
import Vue from "vue";
import Component from "vue-class-component";
import { Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const portalGroups = namespace("PortalGroups");
const sonarServers = namespace("SonarServers");

@Component({})
export default class PortalGroupServerMembership extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public addSonarServerForm = false;
  public removeConfirmationForm = false;
  public hidden = false;
  public selectedSonarServerId = "";
  public sonarServer: InterfaceSonarServer = emptySonarServer();
  public operationInProgress = false;

  @Ref()
  addSonarServerFormElement!: HTMLFormElement;

  @portalGroups.Mutation
  public clearPortalGroupAlert!: () => void;

  @sonarServers.State
  public existingSonarServers!: InterfaceSonarServer[];

  @portalGroups.State
  public selectedPortalGroup!: InterfacePortalGroup;

  @sonarServers.State
  public pageDataSonarServers!: PageData;

  @portalGroups.State
  public portalGroupServerMembership!: InterfaceSonarServer[];

  @portalGroups.State
  public pageDataPortalGroupServers!: PageData;

  @portalGroups.State
  public portalGroupAlert!: Alert;

  @sonarServers.Action
  public getSonarServers!: (pageData: PageData) => Promise<boolean>;

  @portalGroups.Action
  public getPortalGroupServerMembership!: (
    pagedPortalGroupSonarServerMembership: InterfacePagedPortalGroupMembership
  ) => Promise<boolean>;

  @portalGroups.Action
  public addSonarServerToPortalGroup!: (
    portalGroupId: string
  ) => Promise<boolean>;

  @portalGroups.Action
  public removeSonarServerFromPortalGroup!: (
    sonarServerId: string
  ) => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      {
        text: "Name",
        align: "start",
        value: "name",
        sortable: false,
        class: "nameColumn",
      },
    ];
    const actions = [
      {
        text: "Actions",
        align: "start",
        value: "actions",
        sortable: false,
        class: "actionsColumn",
      },
    ];
    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return id.concat(name, actions);
    }
    return name;
  }

  @Watch("pageDataPortalGroupServers.options", {
    deep: true,
  })
  optionsChange(): void {
    this.getSonarServers(this.pageDataSonarServers);
    this.getPortalGroupServerMembership({
      portalGroupId: this.$router.currentRoute.params.id,
      pageIndex: this.pageDataPortalGroupServers.options.page,
      pageSize: this.pageDataPortalGroupServers.options.itemsPerPage,
    });
  }

  public toggleSelectedSonarServer(id: string): void {
    this.selectedSonarServerId = id;
  }

  public async addSonarServerDialogAction(): Promise<void> {
    this.operationInProgress = true;
    if (await this.addSonarServerToPortalGroup(this.selectedSonarServerId)) {
      this.closeAddSonarServerForm();
    }
    this.operationInProgress = false;
  }

  public closeAddSonarServerForm(): void {
    this.addSonarServerForm = false;
    this.addSonarServerFormElement.reset();
    this.clearPortalGroupAlert();
  }

  public removeSonarServerDialog(sonarServer: InterfaceSonarServer): void {
    this.sonarServer = sonarServer;
    this.removeConfirmationForm = true;
  }

  public async removeSonarServer(
    sonarServer: InterfaceSonarServer
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.removeSonarServerFromPortalGroup(sonarServer.id)) {
      this.closeRemoveSonarServerDialog();
    }
    this.operationInProgress = false;
  }

  public closeRemoveSonarServerDialog(): void {
    this.removeConfirmationForm = false;
    this.clearPortalGroupAlert();
  }
}
