import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueKeycloakJs from "@dsb-norge/vue-keycloak-js";
import environmentConfig from "@/utils/environmentConfig";

Vue.config.productionTip = false;

function tokenInterceptor() {
  axios.interceptors.request.use(
    (config) => {
      if (Vue.prototype.$keycloak.authenticated) {
        config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

Vue.use(VueKeycloakJs, {
  init: {
    // Use 'login-required' to always require authentication
    // If using 'login-required', there is no need for the router guards in router.js
    onLoad: "login-required",
  },

  // Configuration values are used from '.env' files
  config: {
    url: environmentConfig("VUE_APP_KEYCLOAK_URL"),
    realm: environmentConfig("VUE_APP_KEYCLOAK_REALM"),
    clientId: environmentConfig("VUE_APP_KEYCLOAK_CLIENT"),
  },

  onReady: (keycloak) => {
    // eslint-disable-next-line no-console
    console.log(`Login User ID Keycloak: ${keycloak.subject}`);
    tokenInterceptor();
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  },
});
