import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({})
export default class AuditLogs extends Vue {
  public resources = [
    "Requests",
    "Sonar-Servers",
    "Organizations",
    "Portal-Groups",
    "Portal-Users",
    "Sonar-Projects",
    "Sonar-Groups",
    "Sonar-Users",
    "Subscriptions",
    "Management-Groups",
    "Cost-Centers",
  ] as const;

  changeRoute(a: string): void {
    if (this.$router.currentRoute.fullPath !== "/audit/" + a) {
      this.$router.push({ path: "/audit/" + a });
    }
  }

  public currentResource(): string {
    if (this.$router.currentRoute.fullPath === "/audit/Sonar-Servers")
      return "Sonar-Servers";
    if (this.$router.currentRoute.fullPath === "/audit/Organizations")
      return "Organizations";
    if (this.$router.currentRoute.fullPath === "/audit/Portal-Groups")
      return "Portal-Groups";
    if (this.$router.currentRoute.fullPath === "/audit/Portal-Users")
      return "Portal-Users";
    if (this.$router.currentRoute.fullPath === "/audit/Sonar-Projects")
      return "Sonar-Projects";
    if (this.$router.currentRoute.fullPath === "/audit/Sonar-Groups")
      return "Sonar-Groups";
    if (this.$router.currentRoute.fullPath === "/audit/Sonar-Users")
      return "Sonar-Users";
    if (this.$router.currentRoute.fullPath === "/audit/Subscriptions")
      return "Subscriptions";
    if (this.$router.currentRoute.fullPath === "/audit/Management-Groups")
      return "Management-Groups";
    if (this.$router.currentRoute.fullPath === "/audit/Cost-Centers")
      return "Cost-Centers";
    return "Requests";
  }
}
