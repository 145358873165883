import { PageData } from "@/models/PageData.model";
import {
  InterfaceSonarGroup,
  InterfacePagedSonarGroupMembership,
} from "@/models/SonarGroup.model";
import {
  emptySonarUser,
  InterfaceSonarUser,
  InterfaceSonarUserSearchRequest,
  InterfaceSonarUserSearchResult,
} from "@/models/SonarUser.model";
import { InterfaceOrganization } from "@/models/Organization.model";
import Vue from "vue";
import Component from "vue-class-component";
import { Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Alert } from "@/models/Alert.model";
import { SearchByKeywordRequest } from "@/models/Search.model";
import { sanitizeUrl } from "@braintree/sanitize-url";

const sonarGroups = namespace("SonarGroups");
const sonarUsers = namespace("SonarUsers");
const organizations = namespace("Organizations");

@Component({})
export default class SonarGroupUserMembership extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public addSonarUserForm = false;
  public removeConfirmationForm = false;
  public hidden = false;
  public selectedSonarUserId = "";
  public sonarUser: InterfaceSonarUser = emptySonarUser();
  public searchSonarServerUsers = null;
  public alertPrimed = false;
  public operationInProgress = false;

  @Ref()
  addSonarUserFormElement!: HTMLFormElement;

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
  };

  @sonarUsers.State
  public existingSonarUsers!: InterfaceSonarUser[];

  @sonarUsers.State
  public foundSonarUsers!: InterfaceSonarUser[];

  @sonarGroups.State
  public selectedSonarGroup!: InterfaceSonarGroup;

  @sonarGroups.State
  public pageDataSonarGroupUsers!: PageData;

  @sonarGroups.State
  public sonarGroupUserMembership!: InterfaceSonarUser[];

  @sonarUsers.State
  public pageDataSonarUsers!: PageData;

  @sonarGroups.State
  public sonarGroupAlert!: Alert;

  @organizations.State
  public selectedOrganization!: InterfaceOrganization;

  @sonarUsers.Action
  public getSonarUsers!: (
    searchRequest: SearchByKeywordRequest
  ) => Promise<boolean>;

  @sonarUsers.Action
  public searchUsers!: (
    searchRequest: InterfaceSonarUserSearchRequest
  ) => Promise<InterfaceSonarUserSearchResult>;

  @organizations.Action
  public getOrganization!: (id: string) => Promise<boolean>;

  @sonarGroups.Mutation
  public clearSonarGroupAlert!: () => void;

  @Watch("searchSonarServerUsers")
  onPropertyChanged(value: string): void {
    if (value && value.length > 2) {
      const sonarUserSearchRequest: InterfaceSonarUserSearchRequest = {
        username: value,
        sonarServerId: this.selectedSonarGroup.sonarServerId,
        pageIndex: 1,
        pageSize: 100,
      };

      this.searchUsers(sonarUserSearchRequest).then((data) => {
        if (data.users.length == 0) {
          this.alertPrimed = true;
        }
      });
    } else this.alertPrimed = false;
  }

  // get users in a given group
  @sonarGroups.Action
  public getSonarGroupUserMembership!: (
    pagedSonarGroupMembership: InterfacePagedSonarGroupMembership
  ) => Promise<boolean>;

  @sonarGroups.Action
  public addSonarUserToSonarGroup!: (sonarUserId: string) => Promise<boolean>;

  @sonarGroups.Action
  public removeSonarUserFromSonarGroup!: (
    sonarUserId: string
  ) => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Name", align: "start", value: "username", sortable: false },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return id.concat(name, actions);
    }
    return name.concat(actions);
  }

  @Watch("pageDataSonarGroupUsers.options", {
    deep: true,
  })
  optionsChange(): void {
    this.operationInProgress = true;
    this.getSonarGroupUserMembership({
      sonarGroupId: this.$router.currentRoute.params.id,
      pageIndex: this.pageDataSonarGroupUsers.options.page,
      pageSize: this.pageDataSonarGroupUsers.options.itemsPerPage,
    });
    this.operationInProgress = false;
  }

  public toggleSelectedSonarUser(id: string): void {
    this.selectedSonarUserId = id;
  }

  public async addSonarUserDialogAction(): Promise<void> {
    this.operationInProgress = true;
    if (this.addSonarUserFormElement.validate()) {
      if (await this.addSonarUserToSonarGroup(this.selectedSonarUserId)) {
        this.closeAddSonarUserDialog();
      }
    }
    this.operationInProgress = false;
  }

  public closeAddSonarUserDialog(): void {
    this.addSonarUserForm = false;
    this.addSonarUserFormElement.reset();
    this.clearSonarGroupAlert();
  }

  public removeSonarUserDialog(sonarUser: InterfaceSonarUser): void {
    this.sonarUser = sonarUser;
    this.removeConfirmationForm = true;
  }

  public async removeSonarUser(sonarUser: InterfaceSonarUser): Promise<void> {
    this.operationInProgress = true;
    if (await this.removeSonarUserFromSonarGroup(sonarUser.id)) {
      this.closeRemoveConfirmationDialog();
    }
    this.operationInProgress = false;
  }

  public closeRemoveConfirmationDialog(): void {
    this.removeConfirmationForm = false;
    this.clearSonarGroupAlert();
  }

  public showUserNotFoundAlert(): boolean {
    if (this.alertPrimed) {
      return this.foundSonarUsers.length == 0;
    } else return false;
  }

  public safeUrl(): string {
    return sanitizeUrl(`${this.selectedSonarGroup.sonarServerUrl}/account`);
  }
}
