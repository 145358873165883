import { PageData } from "./PageData.model";

export interface InterfaceAzureUser {
  id: string;
  displayName: string;
  userPrincipalName: string;
}

export const emptyAzureUser = (): AzureUser => ({
  id: "",
  displayName: "",
  userPrincipalName: "",
});

export class AzureUser implements InterfaceAzureUser {
  constructor(
    public id: string,
    public displayName: string,
    public userPrincipalName: string
  ) {}
}

export interface InterfaceAzureUserSearchRequest {
  displayName: string;
  pageIndex: number;
  pageSize: number;
}

export interface InterfaceAzureUserSearchResult {
  paging: PageData;
  azureUsers: InterfaceAzureUser[];
}
