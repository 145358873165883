import {
  InterfaceCostAzureHub,
  emptyCostAzureHub,
} from "@/models/CostAzureHub.model";
import { Component, Ref, Vue } from "vue-property-decorator";
import { Alert } from "@/models/Alert.model";
import { namespace } from "vuex-class";
import { InterfaceCostCenter } from "@/models/CostCenter.model";

const costAzureHub = namespace("CostAzureHub");
const costCenters = namespace("CostCenters");

interface FetchParams {
  costCenter: string;
  billingMonth: string;
}

@Component({
  components: {},
})
export default class CostAzureHubDetails extends Vue {
  @Ref()
  changeCostAzureHubOrganizationFormElement!: HTMLFormElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public costAzureHub: InterfaceCostAzureHub = emptyCostAzureHub();

  @costAzureHub.State
  public azureHubCostDetailAlert!: Alert;

  @costAzureHub.State
  public selectedAzureHubCost!: InterfaceCostAzureHub;

  @costCenters.State
  public selectedCostCenter!: InterfaceCostCenter;

  @costAzureHub.State
  public dataFactoryConnections!: string[];

  @costAzureHub.State
  public subscriptionVnetPeerings!: string[];

  @costAzureHub.Mutation
  public setDataFactoryConnections!: (dataFactoryConnections: string[]) => void;

  @costAzureHub.Mutation
  public setSubscriptionVnetPeerings!: (
    subscriptionVnetPeerings: string[]
  ) => void;

  @costAzureHub.Action
  public getAzureHubCost!: (fetchParams: FetchParams) => Promise<boolean>;

  @costCenters.Action
  public getCostCenter!: (id: string) => Promise<boolean>;

  async mounted(): Promise<void> {
    this.setEmptyConnectionsList().then(() => {
      this.getCostCenter(this.$router.currentRoute.params.id).then(() => {
        const fetchParams: FetchParams = {
          costCenter: this.selectedCostCenter.name,
          billingMonth: this.$router.currentRoute.params.month,
        };
        this.getAzureHubCost(fetchParams).then(() => {
          const dataFactoryConnections =
            this.selectedAzureHubCost.hubCostDetails.dataFactoryConnections;

          if (dataFactoryConnections !== undefined) {
            this.setDataFactoryConnections(dataFactoryConnections);
          }

          const subscriptionVnetPeerings =
            this.selectedAzureHubCost.hubCostDetails.subscriptionVnetPeerings;

          if (subscriptionVnetPeerings !== undefined) {
            this.setSubscriptionVnetPeerings(subscriptionVnetPeerings);
          }
        });
      });
    });
  }

  public reload(): void {
    window.location.reload();
  }

  async setEmptyConnectionsList(): Promise<void> {
    this.setDataFactoryConnections([]);
    this.setSubscriptionVnetPeerings([]);
  }
}
