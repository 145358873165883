import Vue from "vue";
import { Component, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import SonarGroups from "@/views/sonarGroups/SonarGroups.vue";
import PortalGroups from "@/views/portalGroups/PortalGroups.vue";
import SonarProjects from "@/views/sonarProjects/SonarProjects.vue";
import {
  InterfaceSubscription,
  Subscription,
  emptySubscription,
} from "@/models/Subscription.model";
import { Alert } from "@/models/Alert.model";
import { PageData } from "@/models/PageData.model";
import { InterfaceManagementGroup } from "@/models/ManagementGroup.model";
import ServerSideAutocomplete from "../serverSideAutocomplete/ServerSideAutocomplete.vue";
import { InterfaceOrganization } from "@/models/Organization.model";
import { InterfaceCostCenter } from "@/models/CostCenter.model";
import {
  InterfaceAzureUser,
  InterfaceAzureUserSearchRequest,
  InterfaceAzureUserSearchResult,
} from "@/models/AzureUser.model";

const subscriptions = namespace("Subscriptions");
const managementGroups = namespace("ManagementGroups");
const organizations = namespace("Organizations");
const costCenters = namespace("CostCenters");
const azureUsers = namespace("AzureUsers");

interface FetchParams {
  search: string;
  page: number;
}
interface OrganizationCostCentersFetchParams {
  search: string;
  page: number;
  organizationId: string;
}

@Component({
  components: {
    SonarProjects,
    SonarGroups,
    PortalGroups,
    ServerSideAutocomplete,
  },
})
export default class SubscriptionDetails extends Vue {
  @Ref()
  editSubscriptionFormElement!: HTMLFormElement;
  @Ref()
  changeSubscriptionManagementGroupFormElement!: HTMLFormElement;
  @Ref()
  changeSubscriptionOrganizationFormElement!: HTMLFormElement;
  @Ref()
  costCenterAutocomplete!: HTMLFormElement;
  @Ref()
  editSubscriptionCostCenterFormElement!: HTMLFormElement;
  @Ref()
  addOwnerToSubscriptionFormElement!: HTMLFormElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  sonarGroupsDialog = false;
  showSonarGroups = false;
  showPortalGroups = false;
  public deleteConfirmationForm = false;
  public operationInProgress = false;
  public subscription: InterfaceSubscription = emptySubscription();
  public editSubscriptionForm = false;
  public changeSubscriptionManagementGroupForm = false;
  public changeSubscriptionOrganizationForm = false;
  public selectedOrganizationId = "";
  public editSubscriptionCostCenterForm = false;
  public addOwnerToSubscriptionForm = false;
  public azurePrincipalId = "";
  public searchOwnerToAdd = null;
  public subscriptionTypeSelectList: string[] = [
    "PRODUCTION",
    "NON_PRODUCTION",
    "SANDBOX",
  ];
  public nameOfSubscription = "";
  public cancelConfirmationForm = false;
  public cancelButton = false;
  public deleteButton = false;

  @subscriptions.State
  public selectedSubscription!: InterfaceSubscription;
  @subscriptions.State
  public subscriptionDetailAlert!: Alert;
  @subscriptions.State
  public subscriptionAlert!: Alert;

  @subscriptions.Mutation
  public clearSubscriptionAlert!: () => void;
  @subscriptions.Mutation
  public setAzurePrincipalId!: (data: string) => void;

  @subscriptions.Action
  public getSubscription!: (id: string) => Promise<boolean>;
  @subscriptions.Action
  public deleteSubscription!: (
    subscription: InterfaceSubscription
  ) => Promise<boolean>;
  @subscriptions.Action
  public cancelSubscription!: (
    subscription: InterfaceSubscription
  ) => Promise<boolean>;
  @subscriptions.Action
  public undoCancelSubscription!: (
    subscription: InterfaceSubscription
  ) => Promise<boolean>;
  @subscriptions.Action
  public addOwnerToSubscription!: (subscriptionId: string) => Promise<boolean>;
  @subscriptions.Action
  public updateSubscription!: (
    subscription: InterfaceSubscription
  ) => Promise<boolean>;
  @subscriptions.Action
  public changeSubscriptionOrganization!: (
    subscription: InterfaceSubscription
  ) => Promise<boolean>;
  @subscriptions.Action
  public changeSubscriptionManagementGroup!: (
    subscription: InterfaceSubscription
  ) => Promise<boolean>;
  @subscriptions.Action
  public updateSubscriptionCostCenter!: (
    subscription: InterfaceSubscription
  ) => Promise<boolean>;

  @managementGroups.State
  public pageDataManagementGroups!: PageData;
  @managementGroups.State
  public managementGroupsSelectList!: InterfaceManagementGroup[];
  @managementGroups.State
  public managementGroupsAutocompleteLoadMore!: boolean;
  mounted(): void {
    this.getSubscription(this.$router.currentRoute.params.id);
  }
  @managementGroups.Action
  public getManagementGroupSelectList!: (
    fetchParams: FetchParams
  ) => Promise<boolean>;

  @organizations.State
  public pageDataOrganizations!: PageData;
  @organizations.State
  public organizationsSelectList!: InterfaceOrganization[];
  @organizations.State
  public organizationAutocompleteLoadMore!: boolean;
  @organizations.Action
  public getOrganizationsSelectList!: (
    fetchParams: FetchParams
  ) => Promise<boolean>;

  @costCenters.State
  public costCenterAutocompleteLoadMore!: boolean;
  @costCenters.State
  public existingCostCenters!: InterfaceCostCenter[];
  @costCenters.Action
  public getOrganizationCostCenters!: (
    fetchParams: OrganizationCostCentersFetchParams
  ) => Promise<boolean>;
  @azureUsers.State
  public foundAzureUsers!: InterfaceAzureUser[];
  @azureUsers.Action
  public searchAzureUser!: (
    searchRequest: InterfaceAzureUserSearchRequest
  ) => Promise<InterfaceAzureUserSearchResult>;
  @azureUsers.Mutation
  public setFoundAzureUsers!: (data: InterfaceAzureUser[]) => void;
  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    name: (value: string): string | boolean => {
      const pattern = /^[a-zA-Z0-9-_]{3,50}$/;
      const onlyNumbers = /^\d+$/;
      return (
        (!onlyNumbers.test(value) && pattern.test(value)) ||
        "Invalid Subscription Name. Names must be a alphanumeric (without spaces) and at least 3 characters long and less than 50 characters."
      );
    },
  };

  public deleteSubscriptionDialog(): void {
    this.readSubscription(this.selectedSubscription);
    this.deleteConfirmationForm = true;
  }

  public closeDeleteSubscriptionDialog(): void {
    this.subscription = emptySubscription();
    this.deleteConfirmationForm = false;
    this.clearSubscriptionAlert();
  }

  public closeCancelSubscriptionDialog(): void {
    this.subscription = emptySubscription();
    this.nameOfSubscription = "";
    this.cancelConfirmationForm = false;
    this.clearSubscriptionAlert();
  }

  public async deleteSubscriptionDialogAction(
    subscription: InterfaceSubscription
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteSubscription(subscription)) {
      this.closeDeleteSubscriptionDialog();
      this.$router.push({
        name: "Subscriptions",
      });
    }
  }
  public cancelSubscriptionDialog(): void {
    this.readSubscription(this.selectedSubscription);
    this.cancelConfirmationForm = true;
  }

  public async cancelSubscriptionDialogAction(
    subscription: InterfaceSubscription
  ): Promise<void> {
    this.operationInProgress = true;

    if (await this.cancelSubscription(subscription)) {
      this.closeCancelSubscriptionDialog();
    }
    this.operationInProgress = false;
    this.LoadSubscription(subscription.id);
  }

  public async undoCancelSubscriptionDialogAction(
    subscription: InterfaceSubscription
  ): Promise<void> {
    this.operationInProgress = true;

    if (await this.undoCancelSubscription(subscription)) {
      this.closeCancelSubscriptionDialog();
    }
    this.operationInProgress = false;
    this.LoadSubscription(subscription.id);
  }

  private LoadSubscription(id: string) {
    this.getSubscription(id);
  }

  public readSubscription(subscription: InterfaceSubscription): void {
    this.subscription = new Subscription(
      subscription.id,
      subscription.name,
      subscription.initialOwnerId,
      subscription.subscriptionType,
      subscription.organizationId,
      subscription.organizationName,
      subscription.managementGroupId,
      subscription.managementGroupName,
      subscription.costCenterId,
      subscription.costCenterName,
      subscription.remoteId,
      subscription.subscriptionState
    );
  }

  public editSubscriptionDialog(): void {
    this.readSubscription(this.selectedSubscription);
    this.editSubscriptionForm = true;
  }
  public closeEditSubscriptionForm(): void {
    this.subscription = emptySubscription();
    this.editSubscriptionForm = false;
    this.clearSubscriptionAlert();
  }
  public async editSubscriptionDialogAction(
    subscription: InterfaceSubscription
  ): Promise<void> {
    if (this.editSubscriptionFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.updateSubscription(subscription)) {
        this.closeEditSubscriptionForm();
      }
      this.operationInProgress = false;
      this.LoadSubscription(subscription.id);
    }
  }
  public changeManagementGroupDialog(): void {
    this.readSubscription(this.selectedSubscription);
    this.changeSubscriptionManagementGroupForm = true;
  }
  public closeChangeSubscriptionManagementGroupForm(): void {
    this.subscription = emptySubscription();
    this.changeSubscriptionManagementGroupForm = false;
    this.clearSubscriptionAlert();
  }
  public async changeSubscriptionManagementGroupDialogAction(
    subscription: InterfaceSubscription
  ): Promise<void> {
    if (this.changeSubscriptionManagementGroupFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.changeSubscriptionManagementGroup(subscription)) {
        this.closeChangeSubscriptionManagementGroupForm();
      }
      this.operationInProgress = false;
    }
    this.LoadSubscription(subscription.id);
  }
  public async fetchCreateSubscriptionManagementGroupSelectList(
    search: string,
    page: number
  ): Promise<Array<unknown>> {
    this.pageDataManagementGroups.pageIndex = page;
    await this.getManagementGroupSelectList({
      search: search,
      page: page,
    });
    return this.managementGroupsSelectList;
  }
  public changeOrganizationDialog(): void {
    this.readSubscription(this.selectedSubscription);
    this.changeSubscriptionOrganizationForm = true;
  }
  public closeChangeSubscriptionOrganizationForm(): void {
    this.subscription = emptySubscription();
    this.changeSubscriptionOrganizationForm = false;
    this.clearSubscriptionAlert();
  }
  public async changeSubscriptionOrganizationDialogAction(
    subscription: InterfaceSubscription
  ): Promise<void> {
    if (this.changeSubscriptionOrganizationFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.changeSubscriptionOrganization(subscription)) {
        this.closeChangeSubscriptionOrganizationForm();
      }
      this.operationInProgress = false;
      this.LoadSubscription(subscription.id);
    }
  }
  public async fetchCreateSubscriptionOrganizationSelectList(
    search: string,
    page: number
  ): Promise<Array<unknown>> {
    this.pageDataOrganizations.pageIndex = page;
    await this.getOrganizationsSelectList({
      search: search,
      page: page,
    });
    return this.organizationsSelectList;
  }
  public setSelectedSubscriptionOrganizationId(): void {
    if (
      this.subscription.organizationId != undefined ||
      this.subscription.organizationId != null
    ) {
      this.selectedOrganizationId = this.subscription.organizationId;
      this.costCenterAutocomplete.fetchItems();
    }
  }
  public async fetchCreateSubscriptionCostCenterSelectList(
    search: string,
    page: number
  ): Promise<Array<unknown>> {
    if (this.selectedOrganizationId != "") {
      await this.getOrganizationCostCenters({
        search: search,
        page: page,
        organizationId: this.selectedOrganizationId,
      });
    }
    return this.existingCostCenters;
  }
  public async editSubscriptionCostCenterDialog(): Promise<void> {
    this.readSubscription(this.selectedSubscription);
    this.selectedOrganizationId = this.subscription.organizationId;
    this.editSubscriptionCostCenterForm = true;
  }
  public closeEditSubscriptionCostCenterForm(): void {
    this.editSubscriptionCostCenterFormElement.reset();
    this.editSubscriptionCostCenterForm = false;
    this.selectedOrganizationId = "";
    this.clearSubscriptionAlert();
    this.subscription = emptySubscription();
  }
  public async editSubscriptionCostCenterDialogAction(
    subscription: InterfaceSubscription
  ): Promise<void> {
    if (this.editSubscriptionCostCenterFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.updateSubscriptionCostCenter(subscription)) {
        this.closeEditSubscriptionCostCenterForm();
      }
      this.operationInProgress = false;
      this.LoadSubscription(subscription.id);
    }
  }
  public async addOwnerToSubscriptionDialog(): Promise<void> {
    this.readSubscription(this.selectedSubscription);
    this.addOwnerToSubscriptionForm = true;
  }
  public closeAddOwnerToSubscriptionForm(): void {
    this.addOwnerToSubscriptionForm = false;
    this.clearSubscriptionAlert();
    this.subscription = emptySubscription();
  }
  public async addOwnerToSubscriptionDialogAction(
    subscriptionId: string
  ): Promise<void> {
    if (this.addOwnerToSubscriptionFormElement.validate()) {
      this.operationInProgress = true;
      this.setAzurePrincipalId(this.azurePrincipalId);
      if (await this.addOwnerToSubscription(subscriptionId)) {
        this.closeAddOwnerToSubscriptionForm();
      }
      this.operationInProgress = false;
      this.LoadSubscription(subscriptionId);
    }
  }
  @Watch("searchOwnerToAdd")
  @Watch("searchAzureUsers")
  onPropertyChanged(value: string): void {
    let filteredValue = "";
    if (value && value.length > 2) {
      if (this.subscription.subscriptionType == "PRODUCTION") {
        filteredValue = "CSPX_A_" + value;
      } else {
        filteredValue = "CSTX_A_" + value;
      }
      const azureUserSearchRequest: InterfaceAzureUserSearchRequest = {
        displayName: filteredValue,
        pageIndex: 1,
        pageSize: 100,
      };
      this.searchAzureUser(azureUserSearchRequest);
    }
  }
  public OpenSubscriptionInAzurePortal(): void {
    const url = `https://portal.azure.com/#@corpdir.onmicrosoft.com/resource/subscriptions/${this.selectedSubscription.remoteId}/overview`;
    window.open(url, this.selectedSubscription.name);
  }
  public reload(): void {
    window.location.reload();
  }

  @Watch("nameOfSubscription")
  showDeleteButtonChanged(): void {
    if (
      this.nameOfSubscription === this.subscription.name &&
      this.cancelConfirmationForm === true
    ) {
      this.cancelButton = true;
    } else {
      this.cancelButton = false;
    }
    if (
      this.nameOfSubscription === this.subscription.name &&
      this.deleteConfirmationForm === true
    ) {
      this.deleteButton = true;
    } else {
      this.deleteButton = false;
    }
  }
}
