import { Alert } from "@/models/Alert.model";
import {
  InterfaceOrganization,
  InterfaceOrganizationSearchRequest,
  InterfaceOrganizationSearchResult,
} from "@/models/Organization.model";
import { PageData } from "@/models/PageData.model";
import {
  SearchByKeywordRequest,
  emptySearchByKeywordRequest,
} from "@/models/Search.model";
import {
  emptySonarGroup,
  InterfaceSonarGroup,
  SonarGroup,
} from "@/models/SonarGroup.model";

import Vue from "vue";
import { Component, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import SearchInput from "../searchInput/SearchInput.vue";
import { InterfaceSonarServer } from "@/models/SonarServer.model";

const sonarGroups = namespace("SonarGroups");
const organizations = namespace("Organizations");
const sonarServers = namespace("SonarServers");

interface SonarSelectList {
  text: string;
  value: string;
}

@Component({
  components: {
    SearchInput,
  },
})
export default class SonarGroups extends Vue {
  @Ref()
  createSonarGroupFormElement!: HTMLFormElement;
  @Ref()
  editSonarGroupFormElement!: HTMLFormElement;
  @Ref()
  createSonarGroupFormElementNameField!: HTMLFormElement;
  @Ref()
  editSonarGroupFormElementNameField!: HTMLFormElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public sonarGroup: InterfaceSonarGroup = emptySonarGroup();
  public createSonarGroupForm = false;
  public editSonarGroupForm = false;
  public deleteConfirmationForm = false;
  public permissions: string[] = ["READ", "DEVEL", "ADMIN"];
  public operationInProgress = false;
  public hidden = false;
  public searchOrganizations = null;
  public searchRequest: SearchByKeywordRequest = emptySearchByKeywordRequest();
  public sonarServerSelectList: SonarSelectList[] = [];
  public sonarServerId = "";
  public forceDelete = false;

  @sonarGroups.State
  public selectedSonarGroup!: InterfaceSonarGroup;

  @sonarGroups.State
  public pageDataSonarGroups!: PageData;

  @organizations.State
  public pageDataOrganizations!: PageData;

  @sonarGroups.State
  public existingSonarGroups!: InterfaceSonarGroup[];

  @sonarGroups.State
  public notExistingSonarGroup!: boolean;

  @organizations.State
  public existingOrganizations!: InterfaceOrganization[];

  @organizations.State
  public selectedOrganization!: InterfaceOrganization;

  @organizations.State
  public foundOrganizations!: InterfaceOrganization[];

  @sonarGroups.State
  public inProgress!: boolean;

  @sonarGroups.State
  public sonarGroupAlert!: Alert;

  @sonarGroups.State
  public sonarGroupTableAlert!: Alert;

  @sonarGroups.Mutation
  public setSonarServerId!: (data: string) => void;

  @sonarGroups.Mutation
  public setForceDelete!: (data: boolean) => void;

  @sonarGroups.Mutation
  public setNotExistingSonarGroup!: (data: boolean) => void;

  @organizations.Action
  public searchOrganization!: (
    searchRequest: InterfaceOrganizationSearchRequest
  ) => Promise<InterfaceOrganizationSearchResult>;

  @organizations.Action
  public getOrganizations!: (
    searchRequest: SearchByKeywordRequest
  ) => Promise<boolean>;

  @sonarGroups.Action
  public getSonarGroups!: (
    searchRequest: SearchByKeywordRequest
  ) => Promise<boolean>;

  @sonarGroups.Action
  public getOrganizationSonarGroupMembership!: (
    organizationId: string
  ) => Promise<boolean>;

  @sonarGroups.Action
  public storeSonarGroup!: (
    sonarGroup: InterfaceSonarGroup
  ) => Promise<boolean>;

  @sonarGroups.Action
  public updateSonarGroup!: (
    sonarGroup: InterfaceSonarGroup
  ) => Promise<boolean>;

  @sonarGroups.Action
  public deleteSonarGroup!: (
    sonarGroup: InterfaceSonarGroup
  ) => Promise<boolean>;

  @sonarGroups.Mutation
  public setPageData!: (pageData: PageData) => void;

  @sonarGroups.Mutation
  public clearSonarGroupAlert!: () => void;

  @sonarServers.State
  public pageDataSonarServers!: PageData;
  @sonarServers.State
  public existingSonarServers!: InterfaceSonarServer[];
  @sonarServers.Action
  public getSonarServers!: (pageData: PageData) => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const organizationId = [
      {
        text: "Organization ID",
        align: "start",
        value: "organizationId",
        sortable: false,
      },
    ];
    const organizationName = [
      {
        text: "Organization Name",
        align: "start",
        value: "organizationName",
        sortable: false,
      },
    ];
    const permission = [
      {
        text: "Permissions",
        align: "start",
        value: "permission",
        sortable: false,
      },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    if (this.$router.currentRoute.fullPath === "/sonar/groups") {
      if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
        return id.concat(
          name,
          organizationId,
          organizationName,
          permission,
          actions
        );
      }
      return name.concat(organizationName, permission, actions);
    }
    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return id.concat(name, permission, actions);
    }
    return name.concat(permission, actions);
  }

  loadSonarServers(): void {
    this.getSonarServers(this.pageDataSonarServers).then(() => {
      this.sonarServerSelectList = this.existingSonarServers.map((server) => {
        return { text: server.name, value: server.id };
      });
      this.sonarServerId = this.sonarServerSelectList[0].value;
      this.setSonarServerId(this.sonarServerId);
    });
  }

  @Watch("pageDataSonarGroups.options", {
    deep: true,
  })
  optionsChange(): void {
    this.searchRequest.pageData.options = this.pageDataSonarGroups.options;
    this.loadSonarServers();
    this.contextLoadSonarGroups();
  }
  @Watch("searchRequest.keyword", {
    deep: true,
  })
  @Watch("sonarServerId", {
    deep: true,
  })
  searchValueChange(): void {
    this.searchRequest.pageData.options = this.pageDataSonarGroups.options;
    this.setSonarServerId(this.sonarServerId);
    this.contextLoadSonarGroups();
  }

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    name: (value: string): string | boolean => {
      const pattern = /^[a-zA-Z0-9-_]{3,50}$/;
      const onlyNumbers = /^\d+$/;
      return (
        (!onlyNumbers.test(value) && pattern.test(value)) ||
        "Invalid Sonar Group Name. Names must be an alphanumeric (without spaces) at least 3 characters long and less than 50 characters."
      );
    },
  };

  private contextLoadSonarGroups() {
    if (this.$router.currentRoute.fullPath === "/sonar/groups") {
      this.getSonarGroups(this.searchRequest);
    } else if (
      this.$router.currentRoute.fullPath.startsWith("/orgs/organizations/")
    ) {
      this.getOrganizationSonarGroupMembership(
        this.$router.currentRoute.params.id
      );
    } else {
      // eslint-disable-next-line no-console
      console.error("No matched router path#4");
    }
  }

  public openSonarGroupDetails(sonarGroupId: string): void {
    this.$router.push({
      name: "SonarGroupDetails",
      params: { id: sonarGroupId },
    });
  }

  public editSonarGroupDialog(sonarGroup: InterfaceSonarGroup): void {
    this.sonarGroup = new SonarGroup(
      sonarGroup.id,
      sonarGroup.name,
      sonarGroup.organizationId,
      sonarGroup.organizationName,
      sonarGroup.permission,
      sonarGroup.sonarUsersCount,
      sonarGroup.sonarServerId,
      sonarGroup.sonarServerName,
      sonarGroup.sonarServerUrl
    );
    this.editSonarGroupForm = true;
  }

  public async editSonarGroupDialogAction(
    sonarGroup: InterfaceSonarGroup
  ): Promise<void> {
    if (this.editSonarGroupFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.updateSonarGroup(sonarGroup)) {
        this.closeEditSonarGroupForm();
      }
      this.operationInProgress = false;
      this.contextLoadSonarGroups();
    }
  }

  public deleteSonarGroupDialog(sonarGroup: InterfaceSonarGroup): void {
    this.sonarGroup = sonarGroup;
    this.deleteConfirmationForm = true;
  }

  public async deleteSonarGroupDialogAction(
    sonarGroup: InterfaceSonarGroup
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteSonarGroup(sonarGroup)) {
      this.closeDeleteSonarGroupDialog();
    }
    this.operationInProgress = false;
    this.contextLoadSonarGroups();
  }

  public async storeSonarGroupDialogAction(
    sonarGroup: InterfaceSonarGroup
  ): Promise<void> {
    if (this.createSonarGroupFormElement.validate()) {
      this.operationInProgress = true;
      if (
        this.$router.currentRoute.fullPath.startsWith("/orgs/organizations/")
      ) {
        sonarGroup.organizationId = this.selectedOrganization.id;
      }
      if (await this.storeSonarGroup(sonarGroup)) {
        this.closeCreateSonarGroupForm();
      }
      this.operationInProgress = false;
      this.contextLoadSonarGroups();
    }
  }

  public closeCreateSonarGroupForm(): void {
    this.sonarGroup = emptySonarGroup();
    if (this.$router.currentRoute.fullPath.startsWith("/orgs/organizations/")) {
      this.createSonarGroupFormElementNameField.reset();
    } else {
      this.createSonarGroupFormElement.reset();
    }
    this.createSonarGroupForm = false;
    this.clearSonarGroupAlert();
  }

  public closeEditSonarGroupForm(): void {
    this.sonarGroup = emptySonarGroup();
    this.editSonarGroupForm = false;
    this.clearSonarGroupAlert();
  }

  public closeDeleteSonarGroupDialog(): void {
    this.sonarGroup = emptySonarGroup();
    this.deleteConfirmationForm = false;
    this.clearSonarGroupAlert();
    this.setNotExistingSonarGroup(false);
    this.setForceDelete(false);
  }

  changeToggleForceDelete(value: boolean): void {
    this.setForceDelete(value);
  }

  @Watch("searchOrganizations")
  onPropertyChanged(value: string): void {
    if (value && value.length > 2) {
      const organizationSearchRequest: InterfaceOrganizationSearchRequest = {
        name: value,
        pageIndex: 1,
        pageSize: 100,
      };

      this.searchOrganization(organizationSearchRequest);
    }
  }
}
