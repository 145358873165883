export interface InterfaceRequest {
  id: string;
  name: string;
  requesterId: string;
  requesterUsername: string;
  description: string;
  sonarServerId: string;
  department: string;
  state: string;
  answer: string;
}

export interface InterfaceRequestAudit {
  rev: number;
  id: string;
  name: string;
  requesterId: string;
  requesterUsername: string;
  description: string;
  sonarServerId: string;
  department: string;
  state: number;
  answer: string;
  username: string;
  timestamp: string;
  revtype: number;
}

export const emptyRequest = (): Request => ({
  id: "",
  name: "",
  requesterId: "",
  requesterUsername: "",
  description: "",
  sonarServerId: "",
  department: "",
  state: "",
  answer: "",
});

export class Request implements InterfaceRequest {
  constructor(
    public id: string,
    public name: string,
    public requesterId: string,
    public requesterUsername: string,
    public description: string,
    public sonarServerId: string,
    public department: string,
    public state: string,
    public answer: string
  ) {}
}
