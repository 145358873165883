import Vue from "vue";
import { Component, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { Alert } from "@/models/Alert.model";
import {
  emptyKubecostEndpoint,
  InterfaceKubecostEndpoint,
  KubecostEndpoint,
} from "@/models/KubecostEndpoint.model";
import { PageData } from "@/models/PageData.model";
import { InterfaceSubscription } from "@/models/Subscription.model";
import ServerSideAutocomplete from "../serverSideAutocomplete/ServerSideAutocomplete.vue";

const kubecostEndpoints = namespace("KubecostEndpoints");
const subscriptions = namespace("Subscriptions");

interface FetchParams {
  search: string;
  page: number;
}

@Component({
  components: {
    ServerSideAutocomplete,
  },
})
export default class KubecostEndpoints extends Vue {
  @Ref()
  createKubecostEndpointFormElement!: HTMLFormElement;
  @Ref()
  createKubecostEndpointFormElementNameField!: HTMLFormElement;
  @Ref()
  editKubecostEndpointFormElement!: HTMLFormElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public selectedSubscriptionId = "";
  public kubecostEndpoint: InterfaceKubecostEndpoint = emptyKubecostEndpoint();
  public operationInProgress = false;
  public items: InterfaceKubecostEndpoint[] = [];
  public createKubecostEndpointForm = false;
  public editKubecostEndpointForm = false;
  public deleteConfirmationForm = false;

  @kubecostEndpoints.State
  public inProgress!: boolean;

  @kubecostEndpoints.State
  public kubecostEndpointTableAlert!: Alert;

  @kubecostEndpoints.State
  public kubecostEndpointAlert!: Alert;

  @kubecostEndpoints.State
  public pageDataKubecostEndpoints!: PageData;

  @kubecostEndpoints.State
  public existingKubecostEndpoints!: InterfaceKubecostEndpoint[];

  @kubecostEndpoints.Mutation
  public setKubecostEndpointAlert!: (alert: Alert) => void;

  @kubecostEndpoints.Mutation
  public clearKubecostEndpointAlert!: () => void;

  @kubecostEndpoints.Action
  public getKubecostEndpoints!: (pageData: PageData) => Promise<boolean>;

  @kubecostEndpoints.Action
  public storeKubecostEndpoint!: (
    kubecostEndpoint: InterfaceKubecostEndpoint
  ) => Promise<boolean>;

  @kubecostEndpoints.Action
  public updateKubecostEndpoint!: (
    kubecostEndpoint: InterfaceKubecostEndpoint
  ) => Promise<boolean>;

  @kubecostEndpoints.Action
  public deleteKubecostEndpoint!: (
    kubecostEndpoint: InterfaceKubecostEndpoint
  ) => Promise<boolean>;

  @subscriptions.State
  public pageDataSubscriptions!: PageData;

  @subscriptions.State
  public subscriptionsSelectList!: InterfaceSubscription[];

  @subscriptions.State
  public subscriptionAutocompleteLoadMore!: boolean;

  @subscriptions.Action
  public getSubscriptionsSelectList!: (
    fetchParams: FetchParams
  ) => Promise<boolean>;

  async mounted(): Promise<void> {
    await this.getKubecostEndpoints(this.pageDataKubecostEndpoints);
  }

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const sharedNamespaces = [
      {
        text: "Shared Namespaces",
        align: "start",
        value: "sharedNamespaces",
        sortable: false,
      },
    ];
    const url = [
      { text: "URL", align: "start", value: "url", sortable: false },
    ];
    const azureSubscriptionId = [
      {
        text: "Azure Subscription ID",
        align: "start",
        value: "azureSubscriptionId",
        sortable: false,
      },
    ];
    const azureSubscriptionName = [
      {
        text: "Azure Subscription Name",
        align: "start",
        value: "azureSubscriptionName",
        sortable: false,
      },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    return id.concat(
      name,
      sharedNamespaces,
      url,
      azureSubscriptionId,
      azureSubscriptionName,
      actions
    );
  }

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    name: (value: string): string | boolean => {
      const pattern = /^[a-zA-Z0-9-_]{3,50}$/;
      const onlyNumbers = /^\d+$/;
      return (
        (!onlyNumbers.test(value) && pattern.test(value)) ||
        "Invalid Kubecost Endpoint Name. Names must be a alphanumeric (without spaces) and at least 3 characters long and less than 50 characters."
      );
    },
    namespaceList: (value: string): string | boolean => {
      const pattern =
        /^([a-z0-9]([-a-z0-9]*[a-z0-9])?)(,([a-z0-9]([-a-z0-9]*[a-z0-9])?))*$/;
      return (
        pattern.test(value) ||
        "Invalid Namespace. Names must be a alphanumeric (without spaces) and at least 3 characters long and less than 50 characters."
      );
    },
    url: (value: string): string | boolean => {
      const pattern = /^((ht|f)tps?:\/\/)?[\w-]+(\.[\w-]+)+(:\d{1,5}\/?)?$/;
      return pattern.test(value) || "Invalid URL.";
    },
  };

  public async storeKubecostEndpointDialogAction(
    kubecostEndpoint: InterfaceKubecostEndpoint
  ): Promise<void> {
    if (this.createKubecostEndpointFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.storeKubecostEndpoint(kubecostEndpoint)) {
        this.closeCreateKubecostEndpointForm();
      }
      this.operationInProgress = false;
      this.getKubecostEndpoints(this.pageDataKubecostEndpoints);
    }
  }

  public closeCreateKubecostEndpointForm(): void {
    this.kubecostEndpoint = emptyKubecostEndpoint();
    if (this.$router.currentRoute.fullPath.startsWith("/sonar/servers/")) {
      this.createKubecostEndpointFormElementNameField.reset();
    } else {
      this.createKubecostEndpointFormElement.reset();
    }
    this.createKubecostEndpointForm = false;
    this.clearKubecostEndpointAlert();
  }

  public openKubecostEndpointDetails(kubecostEndpointId: string): void {
    this.$router.push({
      name: "KubecostEndpointDetails",
      params: { id: kubecostEndpointId },
    });
  }

  public editKubecostEndpointDialog(
    kubecostEndpoint: InterfaceKubecostEndpoint
  ): void {
    this.kubecostEndpoint = new KubecostEndpoint(
      kubecostEndpoint.id,
      kubecostEndpoint.name,
      kubecostEndpoint.sharedNamespaces,
      kubecostEndpoint.url,
      kubecostEndpoint.azureSubscriptionId,
      kubecostEndpoint.azureSubscriptionName
    );
    this.editKubecostEndpointForm = true;
  }

  public async editKubecostEndpointDialogAction(
    kubecostEndpoint: InterfaceKubecostEndpoint
  ): Promise<void> {
    if (this.editKubecostEndpointFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.updateKubecostEndpoint(kubecostEndpoint)) {
        this.closeEditKubecostEndpointForm();
      }
      this.operationInProgress = false;
      this.getKubecostEndpoints(this.pageDataKubecostEndpoints);
    }
  }

  public deleteKubecostEndpointDialog(
    kubecostEndpoint: InterfaceKubecostEndpoint
  ): void {
    this.kubecostEndpoint = kubecostEndpoint;
    this.deleteConfirmationForm = true;
  }

  public async deleteKubecostEndpointDialogAction(
    kubecostEndpoint: InterfaceKubecostEndpoint
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteKubecostEndpoint(kubecostEndpoint)) {
      this.closeDeleteKubecostEndpointDialog();
    }
    this.operationInProgress = false;
    this.getKubecostEndpoints(this.pageDataKubecostEndpoints);
  }

  public closeEditKubecostEndpointForm(): void {
    this.kubecostEndpoint = emptyKubecostEndpoint();
    this.editKubecostEndpointForm = false;
    this.clearKubecostEndpointAlert();
  }

  public closeDeleteKubecostEndpointDialog(): void {
    this.kubecostEndpoint = emptyKubecostEndpoint();
    this.deleteConfirmationForm = false;
    this.clearKubecostEndpointAlert();
  }

  public async fetchAzureSubscriptionSelectList(
    search: string,
    page: number
  ): Promise<Array<unknown>> {
    this.pageDataSubscriptions.pageIndex = page;
    await this.getSubscriptionsSelectList({
      search: search,
      page: page,
    });
    return this.subscriptionsSelectList;
  }

  public async setSelectedSubscriptionId(): Promise<void> {
    this.selectedSubscriptionId = "";
    if (
      this.kubecostEndpoint.azureSubscriptionId != undefined ||
      this.kubecostEndpoint.azureSubscriptionId != null
    ) {
      this.selectedSubscriptionId = this.kubecostEndpoint.azureSubscriptionId;
    }
  }
}
