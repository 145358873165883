import { Alert } from "@/models/Alert.model";
import { PageData } from "@/models/PageData.model";
import {
  InterfacePortalGroup,
  emptyPortalGroup,
  InterfacePortalGroupsSearchRequest,
  InterfacePortalGroupsSearchResult,
} from "@/models/PortalGroup.model";
import {
  InterfacePagedPortalUserMembership,
  InterfacePortalUser,
} from "@/models/PortalUser.model";
import Vue from "vue";
import Component from "vue-class-component";
import { Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const portalGroups = namespace("PortalGroups");
const portalUsers = namespace("PortalUsers");

@Component({})
export default class PortalUserGroupMembership extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public addPortalGroupForm = false;
  public removeConfirmationForm = false;
  public hidden = false;
  public selectedPortalGroupId = "";
  public portalGroup: InterfacePortalGroup = emptyPortalGroup();
  public findPortalGroups = null;
  public operationInProgress = false;

  @Ref()
  addPortalGroupFormElement!: HTMLFormElement;

  @portalUsers.Mutation
  public clearPortalUserAlert!: () => void;

  @portalGroups.State
  public existingPortalGroups!: InterfacePortalGroup[];

  @portalUsers.State
  public selectedPortalUser!: InterfacePortalUser;

  @portalGroups.State
  public pageDataPortalGroups!: PageData;

  @portalGroups.State
  public foundPortalGroups!: InterfacePortalGroup[];

  @portalUsers.State
  public portalUserGroupMembership!: InterfacePortalGroup[];

  @portalUsers.State
  public pageDataPortalGroupUsers!: PageData;

  @portalUsers.State
  public pageDataPortalUserGroups!: PageData;

  @portalUsers.State
  public portalUserAlert!: Alert;

  @portalGroups.Action
  public getPortalGroups!: (pageData: PageData) => Promise<boolean>;

  @portalGroups.Action
  public searchPortalGroups!: (
    searchRequest: InterfacePortalGroupsSearchRequest
  ) => Promise<InterfacePortalGroupsSearchResult>;

  @portalUsers.Action
  public getPortalUserGroupMembership!: (
    pagedPortalUserMembership: InterfacePagedPortalUserMembership
  ) => Promise<boolean>;

  @portalUsers.Action
  public addPortalUserToPortalGroup!: (
    portalGroupId: string
  ) => Promise<boolean>;

  @portalUsers.Action
  public removePortalUserFromPortalGroup!: (
    portalUserId: string
  ) => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return id.concat(name, actions);
    }
    return name.concat(actions);
  }

  @Watch("pageDataPortalUserGroups.options", {
    deep: true,
  })
  optionsChange(): void {
    this.getPortalGroups(this.pageDataPortalGroups);
    this.getPortalUserGroupMembership({
      portalUserId: this.$router.currentRoute.params.id,
      pageIndex: this.pageDataPortalUserGroups.options.page,
      pageSize: this.pageDataPortalUserGroups.options.itemsPerPage,
    });
  }

  public toggleSelectedPortalGroup(id: string): void {
    this.selectedPortalGroupId = id;
  }

  public async addPortalGroup(): Promise<void> {
    this.operationInProgress = true;
    if (await this.addPortalUserToPortalGroup(this.selectedPortalGroupId)) {
      this.closeAddPortalGroupDialog();
    }
    this.operationInProgress = false;
  }

  public closeAddPortalGroupDialog(): void {
    this.addPortalGroupForm = false;
    this.clearPortalUserAlert();
    this.addPortalGroupFormElement.reset();
  }

  public removePortalGroupDialog(portalGroup: InterfacePortalGroup): void {
    this.portalGroup = portalGroup;
    this.removeConfirmationForm = true;
  }

  public async removePortalGroup(
    portalGroup: InterfacePortalGroup
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.removePortalUserFromPortalGroup(portalGroup.id)) {
      this.closeRemovePortalGroupDialog();
    }
    this.operationInProgress = false;
  }

  public closeRemovePortalGroupDialog(): void {
    this.removeConfirmationForm = false;
    this.clearPortalUserAlert();
  }

  @Watch("findPortalGroups")
  onPropertyChange(value: string): void {
    if (value && value.length > 2) {
      const searchRequest: InterfacePortalGroupsSearchRequest = {
        name: value,
        pageIndex: 1,
        pageSize: 100,
      };

      this.searchPortalGroups(searchRequest);
    }
  }
}
