import {
  emptyPortalUser,
  InterfacePortalUser,
} from "@/models/PortalUser.model";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { PageData } from "@/models/PageData.model";
import router from "@/router";
import { Alert } from "@/models/Alert.model";

const portalUsers = namespace("PortalUsers");

@Component({})
export default class PortalUsers extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public portalUser: InterfacePortalUser = emptyPortalUser();
  public selectedPortalUser: InterfacePortalUser = emptyPortalUser();
  public hidden = false;

  public openPortalUserDetails(portalUserId: string): void {
    router.push({
      name: "PortalUserDetails",
      params: { id: portalUserId },
    });
  }

  @portalUsers.State
  public existingPortalUsers!: InterfacePortalUser[];

  @portalUsers.State
  public pageDataPortalUsers!: PageData;

  @portalUsers.State
  public inProgress!: boolean;

  @portalUsers.State
  public portalUserTableAlert!: Alert;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const username = [
      { text: "Login", align: "start", value: "username", sortable: false },
    ];
    const firstName = [
      {
        text: "First Name",
        align: "start",
        value: "firstName",
        sortable: false,
      },
    ];
    const lastName = [
      { text: "Last Name", align: "start", value: "lastName", sortable: false },
    ];
    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return id.concat(username, firstName, lastName);
    }
    return username;
  }

  @Watch("pageDataPortalUsers.options", {
    deep: true,
  })
  optionsChange(): void {
    this.getPortalUsers(this.pageDataPortalUsers);
  }

  @portalUsers.Mutation
  public setPageData!: (pageData: PageData) => void;

  @portalUsers.Action
  public getPortalUsers!: (pageData: PageData) => Promise<boolean>;
}
