import { Alert, emptyAlert } from "@/models/Alert.model";
import { InterfaceAuditLogRequest } from "@/models/Audit.model";
import { InterfaceSonarServerAudit } from "@/models/SonarServer.model";
import { InterfaceOrganizationAudit } from "@/models/Organization.model";
import { InterfacePortalGroupAudit } from "@/models/PortalGroup.model";
import { InterfacePortalUserAudit } from "@/models/PortalUser.model";
import { InterfaceSonarProjectAudit } from "@/models/SonarProject.model";
import { InterfaceSonarGroupAudit } from "@/models/SonarGroup.model";
import { InterfaceSonarUserAudit } from "@/models/SonarUser.model";
import { emptyAuditPageData, PageData } from "@/models/PageData.model";
import environmentConfig from "@/utils/environmentConfig";
import axios, { AxiosError, AxiosResponse } from "axios";
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import { InterfaceRequestAudit } from "@/models/Request.model";
import { InterfaceSubscriptionAudit } from "@/models/Subscription.model";
import { InterfaceManagementGroupAudit } from "@/models/ManagementGroup.model";
import { InterfaceCostCenterAudit } from "@/models/CostCenter.model";

const baseApiUrlRequests = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/requests/organizations/audit-logs`;

const baseApiUrlSonarServers = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/sonar-servers/audit-logs`;

const baseApiUrlOrganizations = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/organizations/audit-logs`;

const baseApiUrlPortalGroups = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/portal-groups/audit-logs`;

const baseApiUrlPortalUsers = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/portal-users/audit-logs`;

const baseApiUrlSonarProjects = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/sonar-projects/audit-logs`;

const baseApiUrlSonarGroups = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/sonar-groups/audit-logs`;

const baseApiUrlSonarUsers = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/sonar-users/audit-logs`;

const baseApiUrlSubscriptions = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/subscriptions/audit-logs`;

const baseApiUrlManagementGroups = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/management-groups/audit-logs`;

const baseApiUrlCostCenters = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/centers/audit-logs`;

@Module({ namespaced: true })
class AuditLogs extends VuexModule {
  public foundRequestAuditLogs: InterfaceRequestAudit[] = [];
  public foundSonarServerAuditLogs: InterfaceSonarServerAudit[] = [];
  public foundOrganizationAuditLogs: InterfaceOrganizationAudit[] = [];
  public foundPortalGroupAuditLogs: InterfacePortalGroupAudit[] = [];
  public foundPortalUserAuditLogs: InterfacePortalUserAudit[] = [];
  public foundSonarProjectAuditLogs: InterfaceSonarProjectAudit[] = [];
  public foundSonarGroupAuditLogs: InterfaceSonarGroupAudit[] = [];
  public foundSonarUserAuditLogs: InterfaceSonarUserAudit[] = [];
  public foundSubscriptionAuditLogs: InterfaceSubscriptionAudit[] = [];
  public foundManagementGroupAuditLogs: InterfaceManagementGroupAudit[] = [];
  public foundCostCenterAuditLogs: InterfaceCostCenterAudit[] = [];

  public pageDataRequestAuditLogs: PageData = emptyAuditPageData();
  public pageDataSonarServerAuditLogs: PageData = emptyAuditPageData();
  public pageDataOrganizationAuditLogs: PageData = emptyAuditPageData();
  public pageDataPortalGroupAuditLogs: PageData = emptyAuditPageData();
  public pageDataPortalUserAuditLogs: PageData = emptyAuditPageData();
  public pageDataSonarProjectAuditLogs: PageData = emptyAuditPageData();
  public pageDataSonarGroupAuditLogs: PageData = emptyAuditPageData();
  public pageDataSonarUserAuditLogs: PageData = emptyAuditPageData();
  public pageDataSubscriptionAuditLogs: PageData = emptyAuditPageData();
  public pageDataManagementGroupAuditLogs: PageData = emptyAuditPageData();
  public pageDataCostCenterAuditLogs: PageData = emptyAuditPageData();

  public auditLogsAlert: Alert = emptyAlert();
  public inProgress = false;
  public urlParams = "";

  // Data
  @Mutation
  public setRequestAuditLogs(data: InterfaceRequestAudit[]): void {
    this.foundRequestAuditLogs = data;
  }

  @Mutation
  public setSonarServerAuditLogs(data: InterfaceSonarServerAudit[]): void {
    this.foundSonarServerAuditLogs = data;
  }

  @Mutation
  public setOrganizationAuditLogs(data: InterfaceOrganizationAudit[]): void {
    this.foundOrganizationAuditLogs = data;
  }

  @Mutation
  public setPortalGroupAuditLogs(data: InterfacePortalGroupAudit[]): void {
    this.foundPortalGroupAuditLogs = data;
  }

  @Mutation
  public setPortalUserAuditLogs(data: InterfacePortalUserAudit[]): void {
    this.foundPortalUserAuditLogs = data;
  }

  @Mutation
  public setSonarProjectAuditLogs(data: InterfaceSonarProjectAudit[]): void {
    this.foundSonarProjectAuditLogs = data;
  }

  @Mutation
  public setSonarGroupAuditLogs(data: InterfaceSonarGroupAudit[]): void {
    this.foundSonarGroupAuditLogs = data;
  }

  @Mutation
  public setSonarUserAuditLogs(data: InterfaceSonarUserAudit[]): void {
    this.foundSonarUserAuditLogs = data;
  }

  @Mutation
  public setSubscriptionAuditLogs(data: InterfaceSubscriptionAudit[]): void {
    this.foundSubscriptionAuditLogs = data;
  }

  @Mutation
  public setManagementGroupAuditLogs(
    data: InterfaceManagementGroupAudit[]
  ): void {
    this.foundManagementGroupAuditLogs = data;
  }

  @Mutation
  public setCostCenterAuditLogs(data: InterfaceCostCenterAudit[]): void {
    this.foundCostCenterAuditLogs = data;
  }

  // PageData

  @Mutation
  public setPageDataRequestTotalItems(totalItems: number): void {
    this.pageDataRequestAuditLogs.totalItems = totalItems;
  }

  @Mutation
  public setPageDataSonarServerTotalItems(totalItems: number): void {
    this.pageDataSonarServerAuditLogs.totalItems = totalItems;
  }

  @Mutation
  public setPageDataOrganizationsTotalItems(totalItems: number): void {
    this.pageDataOrganizationAuditLogs.totalItems = totalItems;
  }

  @Mutation
  public setPageDataPortalGroupsTotalItems(totalItems: number): void {
    this.pageDataPortalGroupAuditLogs.totalItems = totalItems;
  }

  @Mutation
  public setPageDataPortalUsersTotalItems(totalItems: number): void {
    this.pageDataPortalUserAuditLogs.totalItems = totalItems;
  }

  @Mutation
  public setPageDataSonarProjectsTotalItems(totalItems: number): void {
    this.pageDataSonarProjectAuditLogs.totalItems = totalItems;
  }

  @Mutation
  public setPageDataSonarGroupsTotalItems(totalItems: number): void {
    this.pageDataSonarGroupAuditLogs.totalItems = totalItems;
  }

  @Mutation
  public setPageDataSonarUsersTotalItems(totalItems: number): void {
    this.pageDataSonarUserAuditLogs.totalItems = totalItems;
  }

  @Mutation
  public setPageDataSubscriptionsTotalItems(totalItems: number): void {
    this.pageDataSubscriptionAuditLogs.totalItems = totalItems;
  }

  @Mutation
  public setPageDataManagementGroupTotalItems(totalItems: number): void {
    this.pageDataManagementGroupAuditLogs.totalItems = totalItems;
  }

  @Mutation
  public setPageDataCostCenterTotalItems(totalItems: number): void {
    this.pageDataCostCenterAuditLogs.totalItems = totalItems;
  }

  // Props

  @Mutation
  public setAuditLogsAlert(data: Alert): void {
    this.auditLogsAlert = data;
  }

  @Mutation
  public clearAuditLogsAlert(): void {
    this.auditLogsAlert = emptyAlert();
  }

  @Mutation
  public setInProgress(data: boolean): void {
    this.inProgress = data;
  }

  @Mutation
  public setURLSearchParams(request: InterfaceAuditLogRequest): void {
    const params = new URLSearchParams({
      pageIndex: `${request.pageData.options.page}`,
      pageSize: `${request.pageData.options.itemsPerPage}`,
      sortBy: `${request.pageData.options.sortBy}`,
      sortDesc: `${request.pageData.options.sortDesc}`,
      keyword: `${request.keyword}`,
    });

    if (request.keyword.length == 0) {
      params.delete("keyword");
    }

    if (request.dateRange.length == 1) {
      params.set("fromDate", `${request.dateRange[0]}`);
    } else if (request.dateRange.length == 2) {
      params.set("fromDate", `${request.dateRange[0]}`);
      params.set("toDate", `${request.dateRange[1]}`);
    }

    this.urlParams = params.toString();
  }

  @Action
  public async searchRequestAuditLogs(
    request: InterfaceAuditLogRequest
  ): Promise<boolean> {
    this.context.commit("setRequestAuditLogs", []);
    this.context.commit("setURLSearchParams", request);
    this.context.commit("setInProgress", true);
    this.context.commit("clearAuditLogsAlert");
    return axios
      .get(`${baseApiUrlRequests}?${this.urlParams}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setRequestAuditLogs", response.data.list);
        this.context.commit(
          "setPageDataRequestTotalItems",
          response.data.paging.totalItems
        );
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        return this.setAxiosErrorAlert(error);
      });
  }

  @Action
  public async searchSonarServerAuditLogs(
    request: InterfaceAuditLogRequest
  ): Promise<boolean> {
    this.context.commit("setSonarServerAuditLogs", []);
    this.context.commit("setURLSearchParams", request);
    this.context.commit("setInProgress", true);
    this.context.commit("clearAuditLogsAlert");
    return axios
      .get(`${baseApiUrlSonarServers}?${this.urlParams}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSonarServerAuditLogs", response.data.list);
        this.context.commit(
          "setPageDataSonarServerTotalItems",
          response.data.paging.totalItems
        );
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        return this.setAxiosErrorAlert(error);
      });
  }

  @Action
  public async searchOrganizationAuditLogs(
    request: InterfaceAuditLogRequest
  ): Promise<boolean> {
    this.context.commit("setOrganizationAuditLogs", []);
    this.context.commit("setURLSearchParams", request);
    this.context.commit("setInProgress", true);
    this.context.commit("clearAuditLogsAlert");
    return axios
      .get(`${baseApiUrlOrganizations}?${this.urlParams}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setOrganizationAuditLogs", response.data.list);
        this.context.commit(
          "setPageDataOrganizationsTotalItems",
          response.data.paging.totalItems
        );
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        return this.setAxiosErrorAlert(error);
      });
  }

  @Action
  public async searchPortalGroupAuditLogs(
    request: InterfaceAuditLogRequest
  ): Promise<boolean> {
    this.context.commit("setPortalGroupAuditLogs", []);
    this.context.commit("setURLSearchParams", request);
    this.context.commit("setInProgress", true);
    this.context.commit("clearAuditLogsAlert");
    return axios
      .get(`${baseApiUrlPortalGroups}?${this.urlParams}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setPortalGroupAuditLogs", response.data.list);
        this.context.commit(
          "setPageDataPortalGroupsTotalItems",
          response.data.paging.totalItems
        );
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        return this.setAxiosErrorAlert(error);
      });
  }

  @Action
  public async searchPortalUserAuditLogs(
    request: InterfaceAuditLogRequest
  ): Promise<boolean> {
    this.context.commit("setPortalUserAuditLogs", []);
    this.context.commit("setURLSearchParams", request);
    this.context.commit("setInProgress", true);
    this.context.commit("clearAuditLogsAlert");
    return axios
      .get(`${baseApiUrlPortalUsers}?${this.urlParams}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setPortalUserAuditLogs", response.data.list);
        this.context.commit(
          "setPageDataPortalUsersTotalItems",
          response.data.paging.totalItems
        );
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        return this.setAxiosErrorAlert(error);
      });
  }

  @Action
  public async searchSonarProjectAuditLogs(
    request: InterfaceAuditLogRequest
  ): Promise<boolean> {
    this.context.commit("setSonarProjectAuditLogs", []);
    this.context.commit("setURLSearchParams", request);
    this.context.commit("setInProgress", true);
    this.context.commit("clearAuditLogsAlert");
    return axios
      .get(`${baseApiUrlSonarProjects}?${this.urlParams}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSonarProjectAuditLogs", response.data.list);
        this.context.commit(
          "setPageDataSonarProjectsTotalItems",
          response.data.paging.totalItems
        );
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        return this.setAxiosErrorAlert(error);
      });
  }

  @Action
  public async searchSonarGroupAuditLogs(
    request: InterfaceAuditLogRequest
  ): Promise<boolean> {
    this.context.commit("setSonarGroupAuditLogs", []);
    this.context.commit("setURLSearchParams", request);
    this.context.commit("setInProgress", true);
    this.context.commit("clearAuditLogsAlert");
    return axios
      .get(`${baseApiUrlSonarGroups}?${this.urlParams}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSonarGroupAuditLogs", response.data.list);
        this.context.commit(
          "setPageDataSonarGroupsTotalItems",
          response.data.paging.totalItems
        );
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        return this.setAxiosErrorAlert(error);
      });
  }

  @Action
  public async searchSonarUserAuditLogs(
    request: InterfaceAuditLogRequest
  ): Promise<boolean> {
    this.context.commit("setSonarUserAuditLogs", []);
    this.context.commit("setURLSearchParams", request);
    this.context.commit("setInProgress", true);
    this.context.commit("clearAuditLogsAlert");
    return axios
      .get(`${baseApiUrlSonarUsers}?${this.urlParams}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSonarUserAuditLogs", response.data.list);
        this.context.commit(
          "setPageDataSonarUsersTotalItems",
          response.data.paging.totalItems
        );
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        return this.setAxiosErrorAlert(error);
      });
  }

  @Action
  public async searchSubscriptionAuditLogs(
    request: InterfaceAuditLogRequest
  ): Promise<boolean> {
    this.context.commit("setSubscriptionAuditLogs", []);
    this.context.commit("setURLSearchParams", request);
    this.context.commit("setInProgress", true);
    this.context.commit("clearAuditLogsAlert");
    return axios
      .get(`${baseApiUrlSubscriptions}?${this.urlParams}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSubscriptionAuditLogs", response.data.list);
        this.context.commit(
          "setPageDataSubscriptionsTotalItems",
          response.data.paging.totalItems
        );
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        return this.setAxiosErrorAlert(error);
      });
  }

  @Action
  public async searchManagementGroupAuditLogs(
    request: InterfaceAuditLogRequest
  ): Promise<boolean> {
    this.context.commit("setManagementGroupAuditLogs", []);
    this.context.commit("setURLSearchParams", request);
    this.context.commit("setInProgress", true);
    this.context.commit("clearAuditLogsAlert");
    return axios
      .get(`${baseApiUrlManagementGroups}?${this.urlParams}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setManagementGroupAuditLogs", response.data.list);
        this.context.commit(
          "setPageDataManagementGroupTotalItems",
          response.data.paging.totalItems
        );
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        return this.setAxiosErrorAlert(error);
      });
  }

  @Action
  public async searchCostCenterAuditLogs(
    request: InterfaceAuditLogRequest
  ): Promise<boolean> {
    this.context.commit("setCostCenterAuditLogs", []);
    this.context.commit("setURLSearchParams", request);
    this.context.commit("setInProgress", true);
    this.context.commit("clearAuditLogsAlert");
    return axios
      .get(`${baseApiUrlCostCenters}?${this.urlParams}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setCostCenterAuditLogs", response.data.list);
        this.context.commit(
          "setPageDataCostCenterTotalItems",
          response.data.paging.totalItems
        );
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        return this.setAxiosErrorAlert(error);
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setAxiosErrorAlert(error: AxiosError<any>) {
    this.context.commit("setInProgress", false);
    this.context.commit("setAuditLogsAlert", new Alert(0, error.message));
    return false;
  }
}

export default AuditLogs;
