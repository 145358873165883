import { PageData } from "./PageData.model";

export interface InterfacePortalUser {
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  lastLogin: string;
  portalGroupsCount: number;
}

export interface InterfacePortalUserAudit {
  rev: number;
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  timestamp: string;
  revtype: number;
}

export const emptyPortalUser = (): PortalUser => ({
  id: "",
  username: "",
  email: "",
  firstName: "",
  lastName: "",
  lastLogin: "",
  portalGroupsCount: 1,
});

export class PortalUser implements InterfacePortalUser {
  constructor(
    public id: string,
    public username: string,
    public email: string,
    public firstName: string,
    public lastName: string,
    public lastLogin: string,
    public portalGroupsCount: number
  ) {}
}

export interface InterfacePagedPortalUserMembership {
  portalUserId: string;
  pageIndex: number;
  pageSize: number;
}

export interface InterfacePortalUsersSearchRequest {
  name: string;
  pageIndex: number;
  pageSize: number;
}

export interface InterfacePortalUsersSearchResult {
  paging: PageData;
  users: InterfacePortalUser[];
}
