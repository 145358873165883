import { Alert, emptyAlert } from "@/models/Alert.model";
import {
  InterfaceCostAzureSub,
  emptyCostAzureSub,
} from "@/models/CostAzureSub.model";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse } from "axios";
import environmentConfig from "@/utils/environmentConfig";

interface FetchParams {
  costCenter: string;
  billingMonth: string;
}

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/azure/subscriptions`;

@Module({ namespaced: true })
class CostAzureSub extends VuexModule {
  public selectedAzureSubCost: InterfaceCostAzureSub = emptyCostAzureSub();
  public azureSubCostDetailAlert: Alert = emptyAlert();
  public costAzureSubTableAlert: Alert = emptyAlert();
  public selectedAzureSubCostsList: InterfaceCostAzureSub[] = [];
  public billingMonth = "";
  public rangeDate: string[] = [];

  @Mutation
  public setSelectedAzureSubCost(data: InterfaceCostAzureSub): void {
    this.selectedAzureSubCost = data;
  }

  @Mutation
  public setAzureSubCostDetailAlert(data: Alert): void {
    this.azureSubCostDetailAlert = data;
  }

  @Mutation
  public setSelectedAzureSubCostsList(data: InterfaceCostAzureSub[]): void {
    this.selectedAzureSubCostsList = data;
  }

  @Mutation
  public setAzureSubCostListAlert(data: Alert): void {
    this.costAzureSubTableAlert = data;
  }

  @Mutation
  public clearAzureSubCostListAlert(): void {
    this.costAzureSubTableAlert = emptyAlert();
  }

  @Mutation
  public setBillingMonth(data: string): void {
    this.billingMonth = data;
  }
  @Mutation
  public setRangeDate(data: string[]): void {
    this.rangeDate = data;
  }

  @Action
  async getAzureSubCosts(): Promise<boolean> {
    const allDates: InterfaceCostAzureSub[] = [];
    try {
      await Promise.all(
        this.rangeDate.map((date) => {
          return axios
            .get(`${baseApiUrl}?month=${date}`)
            .then((response: AxiosResponse) => {
              // For each item in response data push to allDates
              response.data.forEach((item: InterfaceCostAzureSub) => {
                allDates.push(item);
              });
            });
        })
      );
      this.context.commit("setSelectedAzureSubCostsList", allDates);
      return true;
    } catch (error) {
      const axiosError = error as AxiosError;
      let alert: Alert;
      if (axiosError.response) {
        const data = axiosError.response.data as { message: string };
        alert = new Alert(axiosError.response.status, data.message);
      } else {
        alert = new Alert(0, axiosError.message);
      }
      this.context.commit("setAzureSubCostListAlert", alert, { root: true });
      return false;
    }
  }

  @Action
  public async getAzureSubCost(fetchParams: FetchParams): Promise<boolean> {
    const params = new URLSearchParams({
      costCenter: fetchParams.costCenter + "",
      billingMonth: fetchParams.billingMonth,
    }).toString();
    this.context.commit("setSelectedAzureSubCost", emptyCostAzureSub());
    return axios
      .get(`${baseApiUrl}/report?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSelectedAzureSubCost", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          const data = error.response.data as { message: string };
          alert = new Alert(error.response.status, data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setAzureSubCostDetailAlert", alert);
        return false;
      });
  }
}

export default CostAzureSub;
