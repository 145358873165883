import {
  emptySonarUser,
  InterfaceSonarUser,
  InterfacePagedSonarUserMembership,
  InterfaceSonarUserSearchRequest,
  InterfaceSonarUserSearchResult,
} from "@/models/SonarUser.model";
import { InterfaceSonarGroup } from "@/models/SonarGroup.model";
import { emptyPageData, PageData } from "@/models/PageData.model";
import axios, { AxiosError, AxiosResponse } from "axios";
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import environmentConfig from "@/utils/environmentConfig";
import { Alert, emptyAlert } from "@/models/Alert.model";
import { SearchByKeywordRequest } from "@/models/Search.model";

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/sonar-users`;

@Module({ namespaced: true })
class SonarUsers extends VuexModule {
  public existingSonarUsers: InterfaceSonarUser[] = [];
  public pageDataSonarUsers: PageData = emptyPageData();
  public selectedSonarUser: InterfaceSonarUser = emptySonarUser();
  public pageDataSonarUserGroups: PageData = emptyPageData();
  public sonarUserGroupMembership: InterfaceSonarGroup[] = [];
  public foundSonarUsers: InterfaceSonarUserSearchResult[] = [];
  public sonarUserAlert: Alert = emptyAlert();
  public sonarUserTableAlert: Alert = emptyAlert();
  public sonarUserDetailAlert: Alert = emptyAlert();
  public inProgress = false;
  public sonarServerId = "";

  @Mutation
  public setSonarUsers(data: InterfaceSonarUser[]): void {
    this.existingSonarUsers = data;
  }

  @Mutation
  public setPageData(pageData: PageData): void {
    this.pageDataSonarUsers.totalItems = pageData.totalItems;
  }

  @Mutation
  public setPageDataSonarUserGroups(pageData: PageData): void {
    this.pageDataSonarUserGroups.totalItems = pageData.totalItems;
  }

  @Mutation
  public setSelectedSonarUser(data: InterfaceSonarUser): void {
    this.selectedSonarUser = data;
  }

  @Mutation
  public setSonarUserGroupMembership(data: InterfaceSonarGroup[]): void {
    this.sonarUserGroupMembership = data;
  }

  @Mutation
  public setFoundSonarUsers(data: InterfaceSonarUserSearchResult[]): void {
    this.foundSonarUsers = data;
  }

  @Mutation
  public setSonarUserAlert(data: Alert): void {
    this.sonarUserAlert = data;
  }

  @Mutation
  public clearSonarUserAlert(): void {
    this.sonarUserAlert = emptyAlert();
  }

  @Mutation
  public setSonarUserTableAlert(data: Alert): void {
    this.sonarUserTableAlert = data;
  }

  @Mutation
  public clearSonarUserTableAlert(): void {
    this.sonarUserTableAlert = emptyAlert();
  }

  @Mutation
  public setSonarUserDetailAlert(data: Alert): void {
    this.sonarUserDetailAlert = data;
  }

  @Mutation
  public clearSonarUserDetailAlert(): void {
    this.sonarUserDetailAlert = emptyAlert();
  }

  @Mutation
  public setInProgress(data: boolean): void {
    this.inProgress = data;
  }
  @Mutation
  public setSonarServerId(data: string): void {
    this.sonarServerId = data;
  }

  @Action
  public handleError(error: AxiosError): boolean {
    let alert: Alert;
    if (error.response) {
      const data = error.response.data as { message: string };
      alert = new Alert(error.response.status, data.message);
    } else {
      alert = new Alert(0, error.message);
    }
    this.context.commit("setSonarUserAlert", alert);
    return false;
  }

  @Action
  public async getSonarUsers(
    searchRequest: SearchByKeywordRequest
  ): Promise<boolean> {
    this.context.commit("setInProgress", true);
    this.context.commit("clearSonarUserTableAlert");
    const params = new URLSearchParams({
      pageIndex: `${searchRequest.pageData.options.page}`,
      pageSize: `${searchRequest.pageData.options.itemsPerPage}`,
    }).toString();
    let searchKeywordWithSonarServerId = "";
    if (searchRequest.keyword != "" && searchRequest.keyword != undefined) {
      searchKeywordWithSonarServerId =
        "/search/" + this.sonarServerId + "/" + searchRequest.keyword;
    }
    return axios
      .get(`${baseApiUrl}${searchKeywordWithSonarServerId}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSonarUsers", response.data.list);
        this.context.commit("setPageData", response.data.paging);
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setInProgress", false);
        this.context.commit(
          "setSonarUserTableAlert",
          new Alert(0, error.message)
        );
        return false;
      });
  }

  @Action
  public async getSonarUser(id: string): Promise<boolean> {
    this.context.commit("setSelectedSonarUser", emptySonarUser());
    return axios
      .get(`${baseApiUrl}/${id}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSelectedSonarUser", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          const data = error.response.data as { message: string };
          alert = new Alert(error.response.status, data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setSonarUserDetailAlert", alert);
        return false;
      });
  }

  @Action
  public async getSonarUserGroupMembership(
    pagedSonarUserMembership: InterfacePagedSonarUserMembership
  ): Promise<boolean> {
    this.context.commit("setSonarUserGroupMembership", []);
    const params = new URLSearchParams({
      pageIndex: `${pagedSonarUserMembership.pageIndex}`,
      pageSize: `${pagedSonarUserMembership.pageSize}`,
    }).toString();
    return axios
      .get(
        `${baseApiUrl}/${pagedSonarUserMembership.sonarUserId}/groups?${params}`
      )
      .then((response: AxiosResponse) => {
        this.context.commit("setSonarUserGroupMembership", response.data.list);
        this.context.commit("setPageDataSonarUserGroups", response.data.paging);
        return true;
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
      });
  }

  @Action
  public async addSonarUserToSonarGroup(
    sonarGroupId: string
  ): Promise<boolean> {
    const pagedSonarUserMembership: InterfacePagedSonarUserMembership = {
      sonarUserId: this.selectedSonarUser.id,
      pageIndex: this.pageDataSonarUserGroups.pageIndex,
      pageSize: this.pageDataSonarUserGroups.pageSize,
    };
    return axios
      .put(
        `${environmentConfig(
          "VUE_APP_BACKEND_PATH"
        )}/api/sonar-groups/${sonarGroupId}/add-user/${
          this.selectedSonarUser.id
        }`
      )
      .then(() => {
        return this.context.dispatch(
          "getSonarUserGroupMembership",
          pagedSonarUserMembership
        );
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async removeSonarUserFromSonarGroup(
    sonarGroupId: string
  ): Promise<boolean> {
    const pagedSonarUserMembership: InterfacePagedSonarUserMembership = {
      sonarUserId: this.selectedSonarUser.id,
      pageIndex: this.pageDataSonarUserGroups.pageIndex,
      pageSize: this.pageDataSonarUserGroups.pageSize,
    };
    return axios
      .put(
        `${environmentConfig(
          "VUE_APP_BACKEND_PATH"
        )}/api/sonar-groups/${sonarGroupId}/remove-user/${
          this.selectedSonarUser.id
        }`
      )
      .then(() => {
        return this.context.dispatch(
          "getSonarUserGroupMembership",
          pagedSonarUserMembership
        );
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async deleteSonarUser(
    sonarUser: InterfaceSonarUser
  ): Promise<boolean> {
    return axios
      .delete(`${baseApiUrl}/${sonarUser.id}`)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async searchUsers(
    searchRequest: InterfaceSonarUserSearchRequest
  ): Promise<InterfaceSonarUserSearchResult> {
    this.context.commit("setFoundSonarUsers", []);
    const params = new URLSearchParams({
      pageIndex: `${searchRequest.pageIndex}`,
      pageSize: `${searchRequest.pageSize}`,
    }).toString();
    return axios
      .get(
        `${baseApiUrl}/search/${searchRequest.sonarServerId}/${searchRequest.username}?${params}`
      )
      .then((response: AxiosResponse) => {
        this.context.commit("setFoundSonarUsers", response.data.list);
        return <InterfaceSonarUserSearchResult>{
          paging: response.data.paging,
          users: response.data.list,
        };
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return <InterfaceSonarUserSearchResult>{};
      });
  }
}

export default SonarUsers;
