import { Alert } from "@/models/Alert.model";
import { PageData } from "@/models/PageData.model";
import { InterfacePortalUser } from "@/models/PortalUser.model";
import { InterfaceManagementGroup } from "@/models/ManagementGroup.model";
import { InterfaceOrganization } from "@/models/Organization.model";
import { InterfaceCostCenter } from "@/models/CostCenter.model";
import {
  emptyRequest,
  InterfaceRequest,
  SubscriptionRequest,
} from "@/models/SubscriptionRequests.model";
import router from "@/router";

import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ServerSideAutocomplete from "../serverSideAutocomplete/ServerSideAutocomplete.vue";
import {
  emptySearchByKeywordRequest,
  SearchByKeywordRequest,
} from "@/models/Search.model";
import SearchInput from "../searchInput/SearchInput.vue";

const subscriptionRequests = namespace("SubscriptionRequests");
const portalUsers = namespace("PortalUsers");
const managementGroups = namespace("ManagementGroups");
const organizations = namespace("Organizations");
const costCenters = namespace("CostCenters");

interface FetchParams {
  search: string;
  page: number;
}

interface OrganizationCostCentersFetchParams {
  search: string;
  page: number;
  organizationId: string;
}

@Component({
  components: {
    ServerSideAutocomplete,
    SearchInput,
  },
})
export default class SubscriptionRequests extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public request: InterfaceRequest = emptyRequest();
  public createRequestForm = false;
  public editRequestForm = false;
  public operationInProgress = false;
  public deleteRequestConfirmationForm = false;
  public syncInProgress = false;
  public syncInProgressText = "";
  public syncInProgressType = "info";
  public hidden = false;
  public subscriptionTypeSelectList: string[] = [
    "PRODUCTION",
    "NON_PRODUCTION",
    "SANDBOX",
  ];
  public searchRequest: SearchByKeywordRequest = emptySearchByKeywordRequest();

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    length: (v: string): string | boolean =>
      (v && v.length <= 250) || "Description must be less than 250 characters",
    name: (value: string): string | boolean => {
      const pattern = /^[a-zA-Z0-9-_]{3,50}$/;
      const onlyNumbers = /^\d+$/;
      return (
        (!onlyNumbers.test(value) && pattern.test(value)) ||
        "Invalid request name. Names must be an alphanumeric (without spaces) at least 3 characters long and less than 50 characters."
      );
    },
  };

  @Ref()
  createRequestFormElement!: HTMLFormElement;

  @Ref()
  editRequestFormElement!: HTMLFormElement;

  @Ref()
  createRequestFormElementNameField!: HTMLFormElement;

  @Ref()
  createDepartmentFormElementNameField!: HTMLFormElement;

  @Ref()
  createDescriptionFormElement!: HTMLFormElement;

  @Ref()
  editRequestFormElementNameField!: HTMLFormElement;

  @Ref()
  editDescriptionFormElementNameField!: HTMLFormElement;

  public selectedOrganizationId = "";

  @portalUsers.State
  public loggedInPortalUser!: InterfacePortalUser;

  @portalUsers.State
  public existingPortalUsers!: InterfacePortalUser[];

  @portalUsers.State
  public pageDataPortalUsers!: PageData;

  @portalUsers.Action
  public getPortalUsers!: (pageData: PageData) => Promise<boolean>;

  @portalUsers.Action
  public getLoggedInPortalUser!: () => Promise<boolean>;

  @managementGroups.State
  public pageDataManagementGroups!: PageData;

  @managementGroups.State
  public managementGroupsSelectList!: InterfaceManagementGroup[];

  @managementGroups.State
  public managementGroupsAutocompleteLoadMore!: boolean;

  @managementGroups.Action
  public getManagementGroupSelectList!: (
    fetchParams: FetchParams
  ) => Promise<boolean>;

  @organizations.State
  public organizationsSelectList!: InterfaceOrganization[];

  @organizations.State
  public organizationAutocompleteLoadMore!: boolean;

  @organizations.State
  public pageDataOrganizations!: PageData;

  @organizations.Action
  public getOrganizations!: (
    searchRequest: SearchByKeywordRequest
  ) => Promise<boolean>;

  @organizations.Action
  public getOrganizationsSelectList!: (
    fetchParams: FetchParams
  ) => Promise<boolean>;

  @costCenters.Action
  public getOrganizationCostCenters!: (
    fetchParams: OrganizationCostCentersFetchParams
  ) => Promise<boolean>;

  @costCenters.Action
  public getCostCenter!: (id: string) => Promise<boolean>;

  @costCenters.State
  public costCenterAutocompleteLoadMore!: boolean;

  @costCenters.State
  public existingCostCenters!: InterfaceCostCenter[];

  @costCenters.State
  public selectedCostCenter!: InterfaceCostCenter;

  @subscriptionRequests.State
  public existingSubscriptionRequests!: InterfaceRequest[];

  @subscriptionRequests.State
  public pageDataSubscriptionRequests!: PageData;

  @subscriptionRequests.State
  public inProgress!: boolean;

  @subscriptionRequests.State
  public requestAlert!: Alert;

  @subscriptionRequests.State
  public requestTableAlert!: Alert;

  @subscriptionRequests.Mutation
  public setPageData!: (pageData: PageData) => void;

  @subscriptionRequests.Mutation
  public clearRequestAlert!: () => void;

  @subscriptionRequests.Mutation
  public setRequestAlert!: (alert: Alert) => void;

  @subscriptionRequests.Action
  public getSubscriptionRequests!: (
    searchRequest: SearchByKeywordRequest
  ) => Promise<boolean>;

  @subscriptionRequests.Action
  public storeRequest!: (request: InterfaceRequest) => Promise<boolean>;

  @subscriptionRequests.Action
  public updateRequest!: (request: InterfaceRequest) => Promise<boolean>;

  @subscriptionRequests.Action
  public updateRequestState!: (request: InterfaceRequest) => Promise<boolean>;

  @subscriptionRequests.Action
  public updateRequestAccessToken!: (
    request: InterfaceRequest
  ) => Promise<boolean>;

  @subscriptionRequests.Action
  public deleteRequest!: (request: InterfaceRequest) => Promise<boolean>;

  public subscriptionStateBefore = "";

  public subscriptionStateList: { text: string }[] = [{ text: "" }];

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const requesterUsername = [
      {
        text: "Requester",
        align: "start",
        value: "requesterUsername",
        sortable: false,
      },
    ];
    const subscriptionType = [
      {
        text: "Type",
        align: "start",
        value: "subscriptionType",
        sortable: false,
      },
    ];
    const state = [
      {
        text: "State",
        align: "start",
        value: "state",
        sortable: false,
      },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return id.concat(
        requesterUsername,
        name,
        subscriptionType,
        state,
        actions
      );
    }
    return name.concat(subscriptionType, state, actions);
  }

  @Watch("pageDataSubscriptionRequests.options", {
    deep: true,
  })
  optionsChange(): void {
    this.searchRequest.pageData.options =
      this.pageDataSubscriptionRequests.options;
    this.searchRequest.pageData.options = this.pageDataPortalUsers.options;
    this.getSubscriptionRequests(this.searchRequest);
    this.loadAllResources();
  }
  @Watch("searchRequest.keyword", {
    deep: true,
  })
  searchValueChange(): void {
    this.searchRequest.pageData.options =
      this.pageDataSubscriptionRequests.options;
    this.searchRequest.pageData.options = this.pageDataPortalUsers.options;
    this.getSubscriptionRequests(this.searchRequest);
  }

  loadAllResources(): void {
    this.getOrganizations(this.searchRequest);
    this.getPortalUsers(this.pageDataPortalUsers);
  }

  public async fetchCreateSubscriptionManagementGroupSelectList(
    search: string,
    page: number
  ): Promise<Array<unknown>> {
    this.pageDataManagementGroups.pageIndex = page;
    await this.getManagementGroupSelectList({
      search: search,
      page: page,
    });
    return this.managementGroupsSelectList;
  }

  public async fetchOrganizationSelectList(
    search: string,
    page: number
  ): Promise<Array<unknown>> {
    this.pageDataOrganizations.pageIndex = page;
    await this.getOrganizationsSelectList({
      search: search,
      page: page,
    });
    return this.organizationsSelectList;
  }

  public async fetchCreateSubscriptionCostCenterSelectList(
    search: string,
    page: number
  ): Promise<Array<unknown>> {
    if (this.selectedOrganizationId != "") {
      await this.getOrganizationCostCenters({
        search: search,
        page: page,
        organizationId: this.selectedOrganizationId,
      });
    }
    return this.existingCostCenters;
  }

  public async setSelectedOrganizationId(): Promise<void> {
    this.selectedOrganizationId = "";
    if (
      this.request.organizationId != undefined ||
      this.request.organizationId != null
    ) {
      this.selectedOrganizationId = this.request.organizationId;
      if (
        this.selectedCostCenter != undefined &&
        this.selectedCostCenter != null &&
        this.request.costCenterId != null &&
        this.request.costCenterId != ""
      ) {
        await this.getCostCenter(this.request.costCenterId);
        if (
          this.selectedCostCenter.organizationId != this.request.organizationId
        ) {
          this.request.costCenterId = "";
        }
      }
    }
  }

  public openRequestDetails(requestId: string): void {
    router.push({
      name: "RequestDetails",
      params: { id: requestId },
    });
  }

  public async editRequestDialog(request: InterfaceRequest): Promise<void> {
    this.subscriptionStateBefore = request.state;
    if (
      this.subscriptionStateBefore === "PENDING" ||
      this.subscriptionStateBefore === "FAILED"
    ) {
      this.subscriptionStateList = [{ text: "APPROVED" }, { text: "REJECTED" }];
    } else if (this.subscriptionStateBefore === "APPROVED") {
      this.subscriptionStateList = [{ text: "APPROVED" }];
    } else {
      this.subscriptionStateList = [{ text: "CLOSED" }];
    }
    this.request = new SubscriptionRequest(
      request.id,
      request.name,
      request.requesterId,
      this.loggedInPortalUser.username,
      request.description,
      request.subscriptionType,
      request.subscriptionAction,
      request.managementGroupId,
      request.organizationId,
      request.costCenterId,
      request.state,
      request.answer
    );
    this.editRequestForm = true;
  }

  public async editRequestDialogAction(
    request: InterfaceRequest
  ): Promise<void> {
    if (this.editRequestFormElement.validate()) {
      this.operationInProgress = true;
      await this.updateRequest(request);
      if (request.state != this.subscriptionStateBefore) {
        await this.updateRequestState(request);
      }
      this.closeEditRequestForm();
      this.operationInProgress = false;
      this.getSubscriptionRequests(this.searchRequest);
    }
  }

  public deleteRequestDialog(request: InterfaceRequest): void {
    this.request = request;
    this.deleteRequestConfirmationForm = true;
  }

  public async deleteRequestDialogAction(
    request: InterfaceRequest
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteRequest(request)) {
      this.closeDeleteRequestDialog();
    }
    this.operationInProgress = false;
    this.getSubscriptionRequests(this.searchRequest);
  }

  public async storeRequestDialogAction(
    request: InterfaceRequest
  ): Promise<void> {
    if (this.createRequestFormElement.validate()) {
      this.operationInProgress = true;
      request.requesterId = this.loggedInPortalUser.id;
      request.requesterUsername = this.loggedInPortalUser.username;
      request.answer = "ANSWER: ";
      if (await this.storeRequest(request)) {
        this.closeCreateRequestForm();
        this.getSubscriptionRequests(this.searchRequest);
      }
      this.operationInProgress = false;
    }
  }

  public closeCreateRequestForm(): void {
    this.createRequestFormElement.reset();
    this.getLoggedInPortalUser();
    this.selectedOrganizationId = "";
    this.createRequestForm = false;
    this.clearRequestAlert();
  }

  public closeEditRequestForm(): void {
    const clearedRequest = emptyRequest();
    this.getLoggedInPortalUser();
    clearedRequest.requesterUsername = this.loggedInPortalUser.username;
    this.request = clearedRequest;
    this.selectedOrganizationId = "";
    this.editRequestFormElement.reset();
    this.editRequestForm = false;
    this.clearRequestAlert();
  }

  public closeDeleteRequestDialog(): void {
    const clearedRequest = emptyRequest();
    clearedRequest.requesterUsername = this.loggedInPortalUser.username;
    this.request = clearedRequest;
    this.request.requesterUsername = this.loggedInPortalUser.username;
    this.deleteRequestConfirmationForm = false;
    this.clearRequestAlert();
  }
}
