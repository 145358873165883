import {
  emptyPortalUser,
  InterfacePagedPortalUserMembership,
  InterfacePortalUser,
  InterfacePortalUsersSearchRequest,
  InterfacePortalUsersSearchResult,
} from "@/models/PortalUser.model";
import { emptyPageData, PageData } from "@/models/PageData.model";
import axios, { AxiosError, AxiosResponse } from "axios";
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import { InterfacePortalGroup } from "@/models/PortalGroup.model";
import environmentConfig from "@/utils/environmentConfig";
import { Alert, emptyAlert } from "@/models/Alert.model";

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/portal-users`;

@Module({ namespaced: true })
class PortalUsers extends VuexModule {
  public existingPortalUsers: InterfacePortalUser[] = [];
  public pageDataPortalUsers: PageData = emptyPageData();
  public selectedPortalUser: InterfacePortalUser = emptyPortalUser();
  public loggedInPortalUser: InterfacePortalUser = emptyPortalUser();
  public portalUserGroupMembership: InterfacePortalGroup[] = [];
  public pageDataPortalUserGroups: PageData = emptyPageData();
  public portalUserAlert: Alert = emptyAlert();
  public portalUserTableAlert: Alert = emptyAlert();
  public portalUserDetailAlert: Alert = emptyAlert();
  public inProgress = false;
  public foundPortalUsers: InterfacePortalUser[] = [];

  @Mutation
  public setPortalUsers(data: InterfacePortalUser[]): void {
    this.existingPortalUsers = data;
  }

  @Mutation
  public setSelectedPortalUser(data: InterfacePortalUser): void {
    this.selectedPortalUser = data;
  }

  @Mutation
  public setLoggedInPortalUser(data: InterfacePortalUser): void {
    this.loggedInPortalUser = data;
  }

  @Mutation
  public setPageData(pageData: PageData): void {
    this.pageDataPortalUsers.totalItems = pageData.totalItems;
  }

  @Mutation
  public setPageDataPortalUserGroups(pageData: PageData): void {
    this.pageDataPortalUserGroups.totalItems = pageData.totalItems;
  }

  @Mutation
  public setPortalUserGroupMembership(data: InterfacePortalGroup[]): void {
    this.portalUserGroupMembership = data;
  }

  @Mutation
  public setPortalUserAlert(data: Alert): void {
    this.portalUserAlert = data;
  }

  @Mutation
  public clearPortalUserAlert(): void {
    this.portalUserAlert = emptyAlert();
  }

  @Mutation
  public setPortalUserTableAlert(data: Alert): void {
    this.portalUserTableAlert = data;
  }

  @Mutation
  public clearPortalUserTableAlert(): void {
    this.portalUserTableAlert = emptyAlert();
  }

  @Mutation
  public setPortalUserDetailAlert(data: Alert): void {
    this.portalUserDetailAlert = data;
  }

  @Mutation
  public clearPortalUserDetailAlert(): void {
    this.portalUserDetailAlert = emptyAlert();
  }

  @Mutation
  public setInProgress(data: boolean): void {
    this.inProgress = data;
  }

  @Mutation
  public setFoundPortalUsers(data: InterfacePortalUser[]): void {
    this.foundPortalUsers = data;
  }

  @Action
  public handleError(error: AxiosError): boolean {
    let alert: Alert;
    if (error.response) {
      const data = error.response.data as { message: string };
      alert = new Alert(error.response.status, data.message);
    } else {
      alert = new Alert(0, error.message);
    }
    this.context.commit("setPortalUserAlert", alert);
    return false;
  }

  @Action
  public async getPortalUsers(pageData: PageData): Promise<boolean> {
    this.context.commit("setPortalUsers", []);
    this.context.commit("setInProgress", true);
    this.context.commit("clearPortalUserTableAlert");
    const params = new URLSearchParams({
      pageIndex: `${pageData.options.page}`,
      pageSize: `${pageData.options.itemsPerPage}`,
    }).toString();
    return axios
      .get(`${baseApiUrl}` + "?" + params)
      .then((response: AxiosResponse) => {
        this.context.commit("setPortalUsers", response.data.list);
        this.context.commit("setPageData", response.data.paging);
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setInProgress", false);
        this.context.commit(
          "setPortalUserTableAlert",
          new Alert(0, error.message)
        );
        return false;
      });
  }

  @Action
  public async getPortalUser(id: string): Promise<boolean> {
    this.context.commit("setSelectedPortalUser", emptyPortalUser());
    return axios
      .get(`${baseApiUrl}/${id}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSelectedPortalUser", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          const data = error.response.data as { message: string };
          alert = new Alert(error.response.status, data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setPortalUserDetailAlert", alert);
        return false;
      });
  }

  @Action
  public async getPortalUserGroupMembership(
    pagedPortalUserMembership: InterfacePagedPortalUserMembership
  ): Promise<boolean> {
    this.context.commit("setPortalUserGroupMembership", []);
    const params = new URLSearchParams({
      pageIndex: `${pagedPortalUserMembership.pageIndex}`,
      pageSize: `${pagedPortalUserMembership.pageSize}`,
    }).toString();
    return axios
      .get(
        `${baseApiUrl}/${pagedPortalUserMembership.portalUserId}/groups?${params}`
      )
      .then((response: AxiosResponse) => {
        this.context.commit("setPortalUserGroupMembership", response.data.list);
        this.context.commit(
          "setPageDataPortalUserGroups",
          response.data.paging
        );
        return true;
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
      });
  }

  @Action
  public async addPortalUserToPortalGroup(
    portalGroupId: string
  ): Promise<boolean> {
    const pagedPortalUserMembership: InterfacePagedPortalUserMembership = {
      portalUserId: this.selectedPortalUser.id,
      pageIndex: this.pageDataPortalUserGroups.pageIndex,
      pageSize: this.pageDataPortalUserGroups.pageSize,
    };
    return axios
      .put(
        `${environmentConfig(
          "VUE_APP_BACKEND_PATH"
        )}/api/portal-groups/${portalGroupId}/add-user/${
          this.selectedPortalUser.id
        }`
      )
      .then(() => {
        this.context.dispatch("getPortalUser", this.selectedPortalUser.id);
        return this.context.dispatch(
          "getPortalUserGroupMembership",
          pagedPortalUserMembership
        );
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async removePortalUserFromPortalGroup(
    portalGroupId: string
  ): Promise<boolean> {
    const pagedPortalUserMembership: InterfacePagedPortalUserMembership = {
      portalUserId: this.selectedPortalUser.id,
      pageIndex: this.pageDataPortalUserGroups.pageIndex,
      pageSize: this.pageDataPortalUserGroups.pageSize,
    };
    return axios
      .put(
        `${environmentConfig(
          "VUE_APP_BACKEND_PATH"
        )}/api/portal-groups/${portalGroupId}/remove-user/${
          this.selectedPortalUser.id
        }`
      )
      .then(() => {
        this.context.dispatch("getPortalUser", this.selectedPortalUser.id);
        return this.context.dispatch(
          "getPortalUserGroupMembership",
          pagedPortalUserMembership
        );
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async getLoggedInPortalUser(): Promise<boolean> {
    this.context.commit("setLoggedInPortalUser", emptyPortalUser());
    return axios
      .get(`${baseApiUrl}/current`)
      .then((response: AxiosResponse) => {
        this.context.commit("setLoggedInPortalUser", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
      });
  }

  @Action
  public async searchPortalUsers(
    searchRequest: InterfacePortalUsersSearchRequest
  ): Promise<InterfacePortalUsersSearchResult> {
    this.context.commit("setFoundPortalUsers", []);
    const params = new URLSearchParams({
      pageIndex: `${searchRequest.pageIndex}`,
      pageSize: `${searchRequest.pageSize}`,
    }).toString();
    return axios
      .get(`${baseApiUrl}/search/${searchRequest.name}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setFoundPortalUsers", response.data.list);
        return <InterfacePortalUsersSearchResult>{
          paging: response.data.paging,
          users: response.data.list,
        };
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return <InterfacePortalUsersSearchResult>{};
      });
  }
}

export default PortalUsers;
