import { PageData } from "./PageData.model";

export interface InterfaceSonarGroup {
  id: string;
  name: string;
  organizationId: string;
  permission: string;
  organizationName: string;
  sonarServerId: string;
  sonarServerName: string;
  sonarServerUrl: string;
  sonarUsersCount: number;
}

export interface InterfaceSonarGroupAudit {
  rev: number;
  id: string;
  name: string;
  organizationId: string;
  permission: string;
  username: string;
  timestamp: string;
  revtype: number;
}

export const emptySonarGroup = (): SonarGroup => ({
  id: "",
  name: "",
  organizationId: "",
  organizationName: "",
  permission: "READ",
  sonarUsersCount: 0,
  sonarServerId: "",
  sonarServerName: "",
  sonarServerUrl: "",
});

export class SonarGroup implements InterfaceSonarGroup {
  constructor(
    public id: string,
    public name: string,
    public organizationId: string,
    public organizationName: string,
    public permission: string,
    public sonarUsersCount: number,
    public sonarServerId: string,
    public sonarServerName: string,
    public sonarServerUrl: string
  ) {}
}

export interface InterfacePagedSonarGroupMembership {
  sonarGroupId: string;
  pageIndex: number;
  pageSize: number;
}

export interface InterfaceSonarGroupSearchRequest {
  name: string;
  sonarServerId: string;
  pageIndex: number;
  pageSize: number;
}

export interface InterfaceSonarGroupSearchResult {
  paging: PageData;
  groups: InterfaceSonarGroup[];
}
