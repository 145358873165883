import {
  emptyServiceAgreementListDownloadRequest,
  InterfaceServiceAgreementListDownloadRequest,
} from "./../../models/ServiceAgreement.model";
import {
  InterfacePortalUsersSearchRequest,
  InterfacePortalUsersSearchResult,
  PortalUser,
  InterfacePortalUser,
} from "@/models/PortalUser.model";
import {
  emptyServiceAgreement,
  InterfaceServiceAgreement,
} from "@/models/ServiceAgreement.model";
import Vue from "vue";
import { Component, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { PageData } from "@/models/PageData.model";
import { Alert } from "@/models/Alert.model";
import { InterfaceOrganization } from "@/models/Organization.model";
import SearchInput from "../searchInput/SearchInput.vue";

import ServerSideAutocomplete from "../serverSideAutocomplete/ServerSideAutocomplete.vue";
import {
  emptySearchServiceAgreementByKeywordAndDateRangeRequest,
  SearchServiceAgreementByKeywordAndDateRangeRequest,
} from "@/models/Search.model";

const serviceAgreements = namespace("ServiceAgreements");
const portalUsers = namespace("PortalUsers");
const organizations = namespace("Organizations");

interface FetchParams {
  search: string;
  page: number;
}

@Component({
  components: {
    ServerSideAutocomplete,
    SearchInput,
  },
})
export default class ServiceAgreements extends Vue {
  @Ref()
  createServiceAgreementFormElement!: HTMLFormElement;
  @Ref()
  createServiceAgreementFormElementServiceAgreementIdField!: HTMLFormElement;
  @Ref()
  editServiceAgreementFormElement!: HTMLFormElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  searchStartDate = new Date(new Date().getFullYear(), 0, 2)
    .toISOString()
    .slice(0, 10);
  searchEndDate = new Date(new Date().getFullYear(), 11, 32)
    .toISOString()
    .slice(0, 10);
  public serviceAgreement: InterfaceServiceAgreement = emptyServiceAgreement();
  public createServiceAgreementForm = false;
  public editServiceAgreementForm = false;
  public deleteConfirmationForm = false;
  public hidden = false;
  public operationInProgress = false;
  public portalUserItems: PortalUser[] = [];
  public findPortalUsers = null;
  public searchRequest: SearchServiceAgreementByKeywordAndDateRangeRequest =
    emptySearchServiceAgreementByKeywordAndDateRangeRequest();
  public downloadRequest: InterfaceServiceAgreementListDownloadRequest =
    emptyServiceAgreementListDownloadRequest();

  startDate = new Date().toISOString().slice(0, 10);
  endDate = new Date().toISOString().slice(0, 10);
  public selectedStartDate = new Date().toISOString().slice(0, 10);
  public selectedEndDate = new Date().toISOString().slice(0, 10);
  public selectedSearchStartDate = new Date(new Date().getFullYear(), 0, 2)
    .toISOString()
    .slice(0, 10);
  public selectedSearchEndDate = new Date(new Date().getFullYear(), 11, 32)
    .toISOString()
    .slice(0, 10);
  public startDateMenu = false;
  public endDateMenu = false;
  public searchStartDateMenu = false;
  public searchEndDateMenu = false;
  public rangeDate: string[] = [];
  public searchRangeDate: string[] = [];
  public activePicker: string | null = null;
  public menu = false;

  public currencyTypeSelectList: string[] = ["EUR", "USD"];
  public paymentScheduleTypeSelectList: string[] = [
    "MONTHLY",
    "QUARTERLY",
    "ONE_TIME",
  ];
  public statusTypeSelectList: string[] = [
    "DRAFT",
    "PENDING",
    "APPROVED",
    "REJECTED",
    "COMPLETED",
    "FAILED",
    "CLOSED",
  ];

  @serviceAgreements.State
  public existingServiceAgreements!: InterfaceServiceAgreement[];

  @serviceAgreements.State
  public selectedServiceAgreement!: InterfaceServiceAgreement;

  @serviceAgreements.State
  public pageDataServiceAgreements!: PageData;

  @serviceAgreements.State
  public serviceAgreementAlert!: Alert;

  @serviceAgreements.State
  public serviceAgreementTableAlert!: Alert;

  @serviceAgreements.State
  public inProgress!: boolean;

  @portalUsers.State
  public loggedInPortalUser!: InterfacePortalUser;

  @portalUsers.State
  public foundPortalUsers!: InterfacePortalUser[];

  @serviceAgreements.Mutation
  public setPageData!: (pageData: PageData) => void;

  @serviceAgreements.Mutation
  public clearServiceAgreementAlert!: () => void;

  @serviceAgreements.Mutation
  public setServiceAgreementAlert!: (alert: Alert) => void;

  @serviceAgreements.Action
  public getServiceAgreements!: (
    searchRequest: SearchServiceAgreementByKeywordAndDateRangeRequest
  ) => Promise<boolean>;

  @serviceAgreements.Action
  public getServiceAgreement!: (id: string) => Promise<boolean>;

  @organizations.State
  public pageDataOrganizations!: PageData;

  @organizations.State
  public organizationsSelectList!: InterfaceOrganization[];

  @organizations.State
  public organizationAutocompleteLoadMore!: boolean;

  @organizations.Action
  public getOrganizationsSelectList!: (
    fetchParams: FetchParams
  ) => Promise<boolean>;

  @serviceAgreements.Action
  public storeServiceAgreement!: (
    serviceAgreement: InterfaceServiceAgreement
  ) => Promise<boolean>;

  @serviceAgreements.Action
  public updateServiceAgreement!: (
    serviceAgreement: InterfaceServiceAgreement
  ) => Promise<boolean>;

  @serviceAgreements.Action
  public deleteServiceAgreement!: (
    serviceAgreement: InterfaceServiceAgreement
  ) => Promise<boolean>;

  @serviceAgreements.Action
  public downloadExcelReport!: (
    downloadRequest: InterfaceServiceAgreementListDownloadRequest
  ) => Promise<void>;

  @portalUsers.Action
  public searchPortalUsers!: (
    searchRequest: InterfacePortalUsersSearchRequest
  ) => Promise<InterfacePortalUsersSearchResult>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const serviceAgreementId = [
      {
        text: "Service Agreement ID",
        align: "start",
        value: "serviceAgreementId",
        sortable: false,
      },
    ];
    const c4mbId = [
      {
        text: "C4MB ID",
        align: "start",
        value: "c4mbId",
        sortable: false,
      },
    ];
    const serviceName = [
      {
        text: "Service Name",
        align: "start",
        value: "serviceName",
        sortable: false,
      },
    ];
    const companyName = [
      {
        text: "Company Name",
        align: "start",
        value: "companyName",
        sortable: false,
      },
    ];
    const departmentName = [
      {
        text: "Department Name",
        align: "start",
        value: "departmentName",
        sortable: false,
      },
    ];

    const costCenter = [
      {
        text: "Cost Center",
        align: "start",
        value: "costCenter",
        sortable: false,
      },
    ];
    const customerContactPerson = [
      {
        text: "Customer Contact Person ID",
        align: "start",
        value: "customerContactPerson",
        sortable: false,
      },
    ];
    const customerContactPersonEmailAddress = [
      {
        text: "Customer Contact Person Email",
        align: "start",
        value: "customerContactPersonEmailAddress",
        sortable: false,
      },
    ];
    const costCenterResponsible = [
      {
        text: "Responsible ID",
        align: "start",
        value: "costCenterResponsible",
        sortable: false,
      },
    ];
    const costCenterResponsibleEmailAddress = [
      {
        text: "Responsible Email",
        align: "start",
        value: "costCenterResponsibleEmailAddress",
        sortable: false,
      },
    ];

    const costCenterAdditionalResponsible = [
      {
        text: "Additional Responsible ID",
        align: "start",
        value: "costCenterAdditionalResponsible",
        sortable: false,
      },
    ];
    const costCenterAdditionalResponsibleEmailAddress = [
      {
        text: "Additional Responsible Email",
        align: "start",
        value: "costCenterAdditionalResponsibleEmailAddress",
        sortable: false,
      },
    ];
    const organizationId = [
      {
        text: "Organization ID",
        align: "start",
        value: "organizationId",
        sortable: false,
      },
    ];
    const organizationName = [
      {
        text: "Organization Name",
        align: "start",
        value: "organizationName",
        sortable: false,
      },
    ];
    const amount = [
      {
        text: "Amount",
        align: "start",
        value: "amount",
        sortable: false,
      },
    ];
    const currency = [
      {
        text: "Currency",
        align: "start",
        value: "currency",
        sortable: false,
      },
    ];
    const billingPeriodStart = [
      {
        text: "Billing Period Start",
        align: "start",
        value: "billingPeriodStart",
        sortable: false,
      },
    ];
    const billingPeriodEnd = [
      {
        text: "Billing Period End",
        align: "start",
        value: "billingPeriodEnd",
        sortable: false,
      },
    ];
    const status = [
      {
        text: "Status",
        align: "start",
        value: "status",
        sortable: false,
      },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    if (this.$router.currentRoute.fullPath === "/costs/service-agreements") {
      if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
        return id.concat(
          serviceAgreementId,
          c4mbId,
          serviceName,
          companyName,
          departmentName,
          costCenter,
          customerContactPerson,
          customerContactPersonEmailAddress,
          costCenterResponsible,
          costCenterResponsibleEmailAddress,
          costCenterAdditionalResponsible,
          costCenterAdditionalResponsibleEmailAddress,
          organizationId,
          organizationName,
          amount,
          currency,
          billingPeriodStart,
          billingPeriodEnd,
          status,
          actions
        );
      }
      return serviceAgreementId.concat(
        c4mbId,
        serviceName,
        companyName,
        departmentName,
        costCenter,
        customerContactPerson,
        customerContactPersonEmailAddress,
        costCenterResponsible,
        costCenterResponsibleEmailAddress,
        costCenterAdditionalResponsible,
        costCenterAdditionalResponsibleEmailAddress,
        organizationName,
        amount,
        currency,
        billingPeriodStart,
        billingPeriodEnd,
        status,
        actions
      );
    }
    return serviceAgreementId;
  }

  @Watch("pageDataServiceAgreements.options", {
    deep: true,
  })
  @Watch("searchRequest.keyword", {
    deep: true,
  })
  @Watch("selectedSearchStartDate", {
    deep: true,
  })
  @Watch("selectedSearchEndDate", {
    deep: true,
  })
  async update(): Promise<void> {
    this.searchRequest.startDate = this.selectedSearchStartDate;
    this.searchRequest.endDate = this.selectedSearchEndDate;
    this.searchRequest.pageData.options =
      this.pageDataServiceAgreements.options;
    this.contextLoadServiceAgreements();
  }

  @Watch("findPortalUsers")
  onPropertyChange(value: string): void {
    if (value && value.length > 2) {
      const searchRequest: InterfacePortalUsersSearchRequest = {
        name: value,
        pageIndex: 1,
        pageSize: 100,
      };
      this.searchPortalUsers(searchRequest).then(() => {
        this.portalUserItems = this.foundPortalUsers;
      });
    }
  }

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    companyName: (value: string): string | boolean => {
      const pattern = /^[0-9a-zA-Z_. -]{3,1000}$/;
      return (
        pattern.test(value) ||
        "Invalid value. Company Names must be a alphanumeric and at least 3 characters long and less than 1000 characters. The following symbols are allowed: . - _"
      );
    },
    departmentName: (value: string): string | boolean => {
      const pattern = /^[0-9a-zA-Z_\s\n-.,:/]{3,500}$/;
      return (
        pattern.test(value) ||
        "Invalid value. Department Names must be a alphanumeric and at least 3 characters long and less than 500 characters. The following symbols are allowed: . , - _ : /"
      );
    },
    name: (value: string): string | boolean => {
      const pattern = /^[a-zA-Z0-9-_/]{3,50}$/;
      return (
        pattern.test(value) ||
        "Invalid value. Names must be a alphanumeric (without spaces) and at least 3 characters long and less than 50 characters."
      );
    },
    alphanumericAndSymbols: (value: string): string | boolean => {
      const pattern = /^[a-zA-Z0-9_-\s\S.,:/]{3,500}$/;
      return (
        pattern.test(value) ||
        "Invalid value. Value must be a alphanumeric and at least 3 characters long and less than 500 characters."
      );
    },
    email: (value: string): string | boolean => {
      const pattern =
        /^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/;
      return pattern.test(value) || "Invalid Email Address.";
    },
  };

  private contextLoadServiceAgreements() {
    if (this.$router.currentRoute.fullPath === "/costs/service-agreements") {
      this.loadAllServiceAgreements();
    } else {
      // eslint-disable-next-line no-console
      console.error("No matched router path");
    }
  }

  loadAllServiceAgreements(): void {
    this.getServiceAgreements(this.searchRequest);
  }

  public openServiceAgreementDetails(serviceAgreementId: string): void {
    this.$router.push({
      name: "ServiceAgreementDetails",
      params: { id: serviceAgreementId },
    });
  }

  public editServiceAgreementDialog(
    serviceAgreement: InterfaceServiceAgreement
  ): void {
    this.serviceAgreement = serviceAgreement;
    this.editServiceAgreementForm = true;
  }

  public async editServiceAgreementDialogAction(
    serviceAgreement: InterfaceServiceAgreement
  ): Promise<void> {
    if (serviceAgreement.status === "APPROVED") {
      serviceAgreement.dateSigned = new Date();
    }
    if (this.editServiceAgreementFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.updateServiceAgreement(serviceAgreement)) {
        this.closeEditServiceAgreementForm();
      }
      this.operationInProgress = false;
      this.contextLoadServiceAgreements();
    }
  }

  public deleteServiceAgreementDialog(
    serviceAgreement: InterfaceServiceAgreement
  ): void {
    this.serviceAgreement = serviceAgreement;
    this.deleteConfirmationForm = true;
  }

  public async deleteServiceAgreementDialogAction(
    serviceAgreement: InterfaceServiceAgreement
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteServiceAgreement(serviceAgreement)) {
      this.closeDeleteServiceAgreementDialog();
    }
    this.operationInProgress = false;
    this.contextLoadServiceAgreements();
  }

  public async storeServiceAgreementDialogAction(
    serviceAgreement: InterfaceServiceAgreement
  ): Promise<void> {
    if (this.createServiceAgreementFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.storeServiceAgreement(serviceAgreement)) {
        this.closeCreateServiceAgreementForm();
      }
      this.operationInProgress = false;
      this.contextLoadServiceAgreements();
    }
  }

  public async fetchCreateServiceAgreementOrganizationSelectList(
    search: string,
    page: number
  ): Promise<Array<unknown>> {
    this.pageDataOrganizations.pageIndex = page;
    await this.getOrganizationsSelectList({
      search: search,
      page: page,
    });
    return this.organizationsSelectList;
  }

  public closeCreateServiceAgreementForm(): void {
    this.serviceAgreement = emptyServiceAgreement();
    this.createServiceAgreementFormElement.reset();
    this.createServiceAgreementForm = false;
    this.clearServiceAgreementAlert();
  }

  public closeEditServiceAgreementForm(): void {
    this.serviceAgreement = emptyServiceAgreement();
    this.editServiceAgreementForm = false;
    this.clearServiceAgreementAlert();
  }

  public closeDeleteServiceAgreementDialog(): void {
    this.serviceAgreement = emptyServiceAgreement();
    this.deleteConfirmationForm = false;
    this.clearServiceAgreementAlert();
  }

  // Create SA Start Date
  cancelStartDate(): void {
    this.startDateMenu = false;
    this.selectedStartDate = this.startDate;
  }

  selectStartDate(): void {
    this.startDate = this.selectedStartDate;
    this.serviceAgreement.billingPeriodStart = new Date(this.selectedStartDate);
    this.startDateMenu = false;
  }

  // Create SA End Date
  minEndDate(): string {
    return this.startDate;
  }

  selectEndDate(): void {
    this.endDate = this.selectedEndDate;
    this.serviceAgreement.billingPeriodEnd = new Date(this.selectedEndDate);
    this.endDateMenu = false;
  }

  cancelEndDate(): void {
    this.endDateMenu = false;
    this.selectedEndDate = this.endDate;
  }

  // Edit SA Start Date
  cancelEditStartDate(): void {
    this.startDateMenu = false;
    this.selectedStartDate = this.startDate;
  }

  selectEditStartDate(): void {
    this.startDateMenu = false;
  }

  // Edit SA End Date
  selectEditEndDate(): void {
    this.endDateMenu = false;
  }

  cancelEditEndDate(): void {
    this.endDateMenu = false;
    this.selectedEndDate = this.endDate;
  }

  // Search SA Start Date
  cancelSearchStartDate(): void {
    this.searchStartDateMenu = false;
    this.selectedSearchStartDate = this.searchStartDate;
  }

  selectSearchStartDate(): void {
    this.searchStartDate = this.selectedSearchStartDate;
    this.searchStartDateMenu = false;
  }

  // Search SA End Date
  minSearchEndDate(): string {
    return this.searchStartDate;
  }

  selectSearchEndDate(): void {
    this.searchEndDate = this.selectedSearchEndDate;
    this.searchEndDateMenu = false;
  }

  cancelSearchEndDate(): void {
    this.searchEndDateMenu = false;
    this.selectedSearchEndDate = this.searchEndDate;
  }

  // Download Excel Report based on search date range
  async downloadExcel(): Promise<void> {
    this.downloadExcelReport(this.searchRequest);
  }
}
