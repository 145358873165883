import { InterfaceSonarGroupAudit } from "@/models/SonarGroup.model";

import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { PageData } from "@/models/PageData.model";
import {
  emptyAuditLogRequest,
  InterfaceAuditLogRequest,
} from "@/models/Audit.model";
import { Alert } from "@/models/Alert.model";

const auditLogs = namespace("AuditLogs");

@Component({})
export default class AuditLogsSonarGroups extends Vue {
  public datePicker = false;
  public dateRange: string[] = [];
  public auditLogRequest: InterfaceAuditLogRequest = emptyAuditLogRequest();

  @auditLogs.State
  public foundSonarGroupAuditLogs!: InterfaceSonarGroupAudit[];

  @auditLogs.State
  public pageDataSonarGroupAuditLogs!: PageData;

  @auditLogs.State
  public auditLogsAlert!: Alert;

  @auditLogs.State
  public inProgress!: boolean;

  @auditLogs.Action
  public searchSonarGroupAuditLogs!: (
    request: InterfaceAuditLogRequest
  ) => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const organizationId = [
      {
        text: "Organization ID",
        align: "start",
        value: "organizationId",
        sortable: false,
      },
    ];
    const permission = [
      {
        text: "Permission",
        align: "start",
        value: "permission",
        sortable: false,
      },
    ];
    const username = [
      {
        text: "User",
        align: "start",
        value: "username",
        sortable: false,
      },
    ];
    const revtstmp = [
      { text: "Time", align: "start", value: "revtstmp", sortable: true },
    ];
    const revtype = [
      { text: "Action", align: "start", value: "revtype", sortable: false },
    ];
    return revtype.concat(
      id,
      name,
      organizationId,
      permission,
      username,
      revtstmp
    );
  }

  @Watch("pageDataSonarGroupAuditLogs.options", {
    deep: true,
  })
  optionsChange(): void {
    this.auditLogRequest.pageData.options =
      this.pageDataSonarGroupAuditLogs.options;
    this.auditLogRequest.dateRange = this.dateRange;
    this.searchSonarGroupAuditLogs(this.auditLogRequest);
  }

  @Watch("auditLogRequest.keyword", {
    deep: true,
  })
  searchStringChange(): void {
    this.auditLogRequest.dateRange = this.dateRange;
    this.searchSonarGroupAuditLogs(this.auditLogRequest);
  }

  public setDateRange(dateRange: string[]): void {
    this.auditLogRequest.dateRange = dateRange;
    this.datePicker = false;
    this.searchSonarGroupAuditLogs(this.auditLogRequest);
  }

  public clearDateRange(): void {
    this.datePicker = false;
    this.dateRange = [];
    this.auditLogRequest.dateRange = [];
    this.searchSonarGroupAuditLogs(this.auditLogRequest);
  }
}
