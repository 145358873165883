import {
  emptyPortalGroup,
  InterfacePortalGroup,
  InterfacePagedPortalGroupMembership,
  InterfacePortalGroupsSearchRequest,
  InterfacePortalGroupsSearchResult,
} from "@/models/PortalGroup.model";
import { emptyPageData, PageData } from "@/models/PageData.model";
import axios, { AxiosError, AxiosResponse } from "axios";
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import { InterfacePortalUser } from "@/models/PortalUser.model";
import { InterfaceSonarServer } from "@/models/SonarServer.model";
import { InterfaceOrganization } from "@/models/Organization.model";
import environmentConfig from "@/utils/environmentConfig";
import { Alert, emptyAlert } from "@/models/Alert.model";

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/portal-groups`;

@Module({ namespaced: true })
class PortalGroups extends VuexModule {
  public existingPortalGroups: InterfacePortalGroup[] = [];
  public pageDataPortalGroups: PageData = emptyPageData();
  public selectedPortalGroup: InterfacePortalGroup = emptyPortalGroup();
  public pageDataPortalGroupUsers: PageData = emptyPageData();
  public portalGroupUserMembership: InterfacePortalUser[] = [];
  public pageDataPortalGroupServers: PageData = emptyPageData();
  public portalGroupServerMembership: InterfaceSonarServer[] = [];
  public pageDataPortalGroupOrganizations: PageData = emptyPageData();
  public portalGroupOrganizationMembership: InterfaceOrganization[] = [];
  public pageDataPortalUserGroups: PageData = emptyPageData();
  public portalGroupAlert: Alert = emptyAlert();
  public portalGroupTableAlert: Alert = emptyAlert();
  public portalGroupDetailAlert: Alert = emptyAlert();
  public inProgress = false;
  public foundPortalGroups: InterfacePortalGroup[] = [];

  @Mutation
  public setPortalGroups(data: InterfacePortalGroup[]): void {
    this.existingPortalGroups = data;
  }

  @Mutation
  public setPageData(pageData: PageData): void {
    this.pageDataPortalGroups.totalItems = pageData.totalItems;
  }

  @Mutation
  public setPageDataPortalGroupUsers(pageData: PageData): void {
    this.pageDataPortalGroupUsers.totalItems = pageData.totalItems;
  }

  @Mutation
  public setPageDataPortalGroupServers(pageData: PageData): void {
    this.pageDataPortalGroupServers.totalItems = pageData.totalItems;
  }

  @Mutation
  public setPageDataPortalGroupOrganizations(pageData: PageData): void {
    this.pageDataPortalGroupOrganizations.totalItems = pageData.totalItems;
  }

  @Mutation
  public setSelectedPortalGroup(data: InterfacePortalGroup): void {
    this.selectedPortalGroup = data;
  }

  @Mutation
  public setPortalGroupUserMembership(data: InterfacePortalUser[]): void {
    this.portalGroupUserMembership = data;
  }

  @Mutation
  public setPortalGroupServerMembership(data: InterfaceSonarServer[]): void {
    this.portalGroupServerMembership = data;
  }

  @Mutation
  public setPortalGroupOrganizationMembership(
    data: InterfaceOrganization[]
  ): void {
    this.portalGroupOrganizationMembership = data;
  }

  @Mutation
  public setPageDataPortalUserGroups(pageData: PageData): void {
    this.pageDataPortalUserGroups = pageData;
  }

  @Mutation
  public setPortalGroupAlert(data: Alert): void {
    this.portalGroupAlert = data;
  }

  @Mutation
  public clearPortalGroupAlert(): void {
    this.portalGroupAlert = emptyAlert();
  }

  @Mutation
  public setPortalGroupTableAlert(data: Alert): void {
    this.portalGroupTableAlert = data;
  }

  @Mutation
  public clearPortalGroupTableAlert(): void {
    this.portalGroupTableAlert = emptyAlert();
  }

  @Mutation
  public setPortalGroupDetailAlert(data: Alert): void {
    this.portalGroupDetailAlert = data;
  }

  @Mutation
  public clearPortalGroupDetailAlert(): void {
    this.portalGroupDetailAlert = emptyAlert();
  }

  @Mutation
  public setInProgress(data: boolean): void {
    this.inProgress = data;
  }

  @Mutation
  public setFoundPortalGroups(data: InterfacePortalGroup[]): void {
    this.foundPortalGroups = data;
  }

  @Action
  public handleError(error: AxiosError): boolean {
    let alert: Alert;
    if (error.response) {
      const data = error.response.data as { message: string };
      alert = new Alert(error.response.status, data.message);
    } else {
      alert = new Alert(0, error.message);
    }
    this.context.commit("setPortalGroupAlert", alert);
    return false;
  }

  @Action
  public async storePortalGroup(
    portalGroup: InterfacePortalGroup
  ): Promise<boolean> {
    return axios
      .post(`${baseApiUrl}`, {
        name: portalGroup.name,
      })
      .then((response) => {
        this.context.commit("setSelectedPortalGroup", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async getPortalGroups(pageData: PageData): Promise<boolean> {
    this.context.commit("setPortalGroups", []);
    this.context.commit("setInProgress", true);
    this.context.commit("clearPortalGroupTableAlert");
    const params = new URLSearchParams({
      pageIndex: `${pageData.options.page}`,
      pageSize: `${pageData.options.itemsPerPage}`,
    }).toString();
    return axios
      .get(`${baseApiUrl}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setPortalGroups", response.data.list);
        this.context.commit("setPageData", response.data.paging);
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setInProgress", false);
        this.context.commit(
          "setPortalGroupTableAlert",
          new Alert(0, error.message)
        );
        return false;
      });
  }

  @Action
  public async getPortalGroup(id: string): Promise<boolean> {
    this.context.commit("setSelectedPortalGroup", emptyPortalGroup());
    this.context.commit("clearPortalGroupDetailAlert");
    return axios
      .get(`${baseApiUrl}/${id}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSelectedPortalGroup", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          const data = error.response.data as { message: string };
          alert = new Alert(error.response.status, data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setPortalGroupDetailAlert", alert);
        return false;
      });
  }

  @Action
  public async getPortalGroupUserMembership(
    pagedPortalGroupMembership: InterfacePagedPortalGroupMembership
  ): Promise<boolean> {
    this.context.commit("setPortalGroupUserMembership", []);
    const params = new URLSearchParams({
      pageIndex: `${pagedPortalGroupMembership.pageIndex}`,
      pageSize: `${pagedPortalGroupMembership.pageSize}`,
    }).toString();
    return axios
      .get(
        `${baseApiUrl}/${pagedPortalGroupMembership.portalGroupId}/users?${params}`
      )
      .then((response: AxiosResponse) => {
        this.context.commit("setPortalGroupUserMembership", response.data.list);
        this.context.commit(
          "setPageDataPortalGroupUsers",
          response.data.paging
        );
        return true;
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
      });
  }

  @Action
  public async addPortalUserToPortalGroup(
    portalUserId: string
  ): Promise<boolean> {
    const pagedPortalGroupMembership: InterfacePagedPortalGroupMembership = {
      portalGroupId: this.selectedPortalGroup.id,
      pageIndex: this.pageDataPortalGroupUsers.pageIndex,
      pageSize: this.pageDataPortalGroupUsers.pageSize,
    };
    return axios
      .put(
        `${baseApiUrl}/${this.selectedPortalGroup.id}/add-user/${portalUserId}`
      )
      .then(() => {
        this.context.dispatch("getPortalGroup", this.selectedPortalGroup.id);
        return this.context.dispatch(
          "getPortalGroupUserMembership",
          pagedPortalGroupMembership
        );
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async removePortalUserFromPortalGroup(
    portalUserId: string
  ): Promise<boolean> {
    const pagedPortalGroupMembership: InterfacePagedPortalGroupMembership = {
      portalGroupId: this.selectedPortalGroup.id,
      pageIndex: this.pageDataPortalGroupUsers.pageIndex,
      pageSize: this.pageDataPortalGroupUsers.pageSize,
    };
    return axios
      .put(
        `${baseApiUrl}/${this.selectedPortalGroup.id}/remove-user/${portalUserId}`
      )
      .then(() => {
        this.context.dispatch("getPortalGroup", this.selectedPortalGroup.id);
        return this.context.dispatch(
          "getPortalGroupUserMembership",
          pagedPortalGroupMembership
        );
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async getSonarServerPortalGroupMembership(
    sonarServerId: string
  ): Promise<boolean> {
    this.context.commit("setPortalGroups", []);
    const params = new URLSearchParams({
      pageIndex: `1`,
      pageSize: `100`,
    }).toString();
    return axios
      .get(
        `${environmentConfig(
          "VUE_APP_BACKEND_PATH"
        )}/api/sonar-servers/${sonarServerId}/groups?${params}`
      )
      .then((response: AxiosResponse) => {
        this.context.commit("setPortalGroups", response.data.list);
        this.context.commit("setPageData", response.data.paging);
        return true;
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
      });
  }

  @Action
  public async getPortalGroupServerMembership(
    pagedPortalGroupSonarServerMembership: InterfacePagedPortalGroupMembership
  ): Promise<boolean> {
    this.context.commit("setPortalGroupServerMembership", []);
    const params = new URLSearchParams({
      pageIndex: `${pagedPortalGroupSonarServerMembership.pageIndex}`,
      pageSize: `${pagedPortalGroupSonarServerMembership.pageSize}`,
    }).toString();
    return axios
      .get(
        `${baseApiUrl}/${pagedPortalGroupSonarServerMembership.portalGroupId}/servers?${params}`
      )
      .then((response: AxiosResponse) => {
        this.context.commit(
          "setPortalGroupServerMembership",
          response.data.list
        );
        this.context.commit(
          "setPageDataPortalGroupServers",
          response.data.paging
        );
        return true;
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
      });
  }

  @Action
  public async addSonarServerToPortalGroup(
    sonarServerId: string
  ): Promise<boolean> {
    const pagedPortalGroupMembership: InterfacePagedPortalGroupMembership = {
      portalGroupId: this.selectedPortalGroup.id,
      pageIndex: this.pageDataPortalGroupServers.pageIndex,
      pageSize: this.pageDataPortalGroupServers.pageSize,
    };
    return axios
      .put(
        `${baseApiUrl}/${this.selectedPortalGroup.id}/add-server/${sonarServerId}`
      )
      .then(() => {
        this.context.dispatch("getPortalGroup", this.selectedPortalGroup.id);
        return this.context.dispatch(
          "getPortalGroupServerMembership",
          pagedPortalGroupMembership
        );
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async removeSonarServerFromPortalGroup(
    sonarServerId: string
  ): Promise<boolean> {
    const pagedPortalGroupMembership: InterfacePagedPortalGroupMembership = {
      portalGroupId: this.selectedPortalGroup.id,
      pageIndex: this.pageDataPortalGroupServers.pageIndex,
      pageSize: this.pageDataPortalGroupServers.pageSize,
    };
    return axios
      .put(
        `${baseApiUrl}/${this.selectedPortalGroup.id}/remove-server/${sonarServerId}`
      )
      .then(() => {
        this.context.dispatch("getPortalGroup", this.selectedPortalGroup.id);
        return this.context.dispatch(
          "getPortalGroupServerMembership",
          pagedPortalGroupMembership
        );
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async getOrganizationPortalGroupMembership(
    organizationId: string
  ): Promise<boolean> {
    this.context.commit("setPortalGroups", []);
    const params = new URLSearchParams({
      pageIndex: `${this.pageDataPortalGroupOrganizations.pageIndex}`,
      pageSize: `${this.pageDataPortalGroupOrganizations.pageIndex}`,
    }).toString();
    return axios
      .get(
        `${environmentConfig(
          "VUE_APP_BACKEND_PATH"
        )}/api/organizations/${organizationId}/portal-groups?${params}`
      )
      .then((response: AxiosResponse) => {
        this.context.commit("setPortalGroups", response.data.list);
        this.context.commit("setPageData", response.data.paging);
        return true;
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
      });
  }

  @Action
  public async getPortalGroupOrganizationMembership(
    pagedPortalGroupMembership: InterfacePagedPortalGroupMembership
  ): Promise<boolean> {
    this.context.commit("setPortalGroupOrganizationMembership", []);
    const params = new URLSearchParams({
      pageIndex: `${pagedPortalGroupMembership.pageIndex}`,
      pageSize: `${pagedPortalGroupMembership.pageSize}`,
    }).toString();
    return axios
      .get(
        `${baseApiUrl}/${pagedPortalGroupMembership.portalGroupId}/organizations?${params}`
      )
      .then((response: AxiosResponse) => {
        this.context.commit(
          "setPortalGroupOrganizationMembership",
          response.data.list
        );
        this.context.commit(
          "setPageDataPortalGroupOrganizations",
          response.data.paging
        );
        return true;
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
      });
  }

  @Action
  public async addOrganizationToPortalGroup(
    organizationId: string
  ): Promise<boolean> {
    const pagedPortalGroupMembership: InterfacePagedPortalGroupMembership = {
      portalGroupId: this.selectedPortalGroup.id,
      pageIndex: this.pageDataPortalGroupOrganizations.pageIndex,
      pageSize: this.pageDataPortalGroupOrganizations.pageSize,
    };
    return axios
      .put(
        `${baseApiUrl}/${this.selectedPortalGroup.id}/add-organization/${organizationId}`
      )
      .then(() => {
        this.context.dispatch("getPortalGroup", this.selectedPortalGroup.id);
        return this.context.dispatch(
          "getPortalGroupOrganizationMembership",
          pagedPortalGroupMembership
        );
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async removeOrganizationFromPortalGroup(
    organizationId: string
  ): Promise<boolean> {
    const pagedPortalGroupMembership: InterfacePagedPortalGroupMembership = {
      portalGroupId: this.selectedPortalGroup.id,
      pageIndex: this.pageDataPortalGroupOrganizations.pageIndex,
      pageSize: this.pageDataPortalGroupOrganizations.pageSize,
    };
    return axios
      .put(
        `${baseApiUrl}/${this.selectedPortalGroup.id}/remove-organization/${organizationId}`
      )
      .then(() => {
        this.context.dispatch("getPortalGroup", this.selectedPortalGroup.id);
        return this.context.dispatch(
          "getPortalGroupOrganizationMembership",
          pagedPortalGroupMembership
        );
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async updatePortalGroup(
    PortalGroup: InterfacePortalGroup
  ): Promise<boolean> {
    return axios
      .put(`${baseApiUrl}/${PortalGroup.id}`, PortalGroup)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async deletePortalGroup(
    PortalGroup: InterfacePortalGroup
  ): Promise<boolean> {
    return axios
      .delete(`${baseApiUrl}/${PortalGroup.id}`)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async searchPortalGroups(
    searchRequest: InterfacePortalGroupsSearchRequest
  ): Promise<InterfacePortalGroupsSearchResult> {
    this.context.commit("setFoundPortalGroups", []);
    const params = new URLSearchParams({
      pageIndex: `${searchRequest.pageIndex}`,
      pageSize: `${searchRequest.pageSize}`,
    }).toString();
    return axios
      .get(`${baseApiUrl}/search/${searchRequest.name}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setFoundPortalGroups", response.data.list);
        return <InterfacePortalGroupsSearchResult>{
          paging: response.data.paging,
          groups: response.data.list,
        };
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return <InterfacePortalGroupsSearchResult>{};
      });
  }
}

export default PortalGroups;
