import { emptyAuditPageData, PageData } from "./PageData.model";

export interface InterfaceAuditLogRequest {
  keyword: string;
  dateRange: string[];
  pageData: PageData;
}

export const emptyAuditLogRequest = (): InterfaceAuditLogRequest => ({
  keyword: "",
  dateRange: [],
  pageData: emptyAuditPageData(),
});

export class AuditLogRequest implements InterfaceAuditLogRequest {
  constructor(
    public keyword: string,
    public dateRange: string[],
    public pageData: PageData
  ) {}
}
