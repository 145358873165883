import { PageData } from "./PageData.model";

export interface InterfaceManagementGroup {
  id: string;
  name: string;
  parentId: string;
  parentName: string;
}

export interface InterfaceManagementGroupAudit {
  rev: number;
  id: string;
  name: string;
  parentManagementGroupId: string;
  username: string;
  timestamp: string;
  revtype: number;
}

export const emptyManagementGroup = (): ManagementGroup => ({
  id: "",
  name: "",
  parentId: "",
  parentName: "",
});

export class ManagementGroup implements InterfaceManagementGroup {
  constructor(
    public id: string,
    public name: string,
    public parentId: string,
    public parentName: string
  ) {}
}

export interface InterfaceManagementGroupSearchRequest {
  name: string;
  pageIndex: number;
  pageSize: number;
}

export interface InterfaceManagementGroupSearchResult {
  paging: PageData;
  managementGroups: InterfaceManagementGroup[];
}
