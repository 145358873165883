import Vue from "vue";
import { Component, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Alert } from "@/models/Alert.model";
import {
  InterfaceManagementGroup,
  ManagementGroup,
  emptyManagementGroup,
} from "@/models/ManagementGroup.model";
import { PageData } from "@/models/PageData.model";

const managementGroups = namespace("ManagementGroups");

interface ManagementGroupSelectList {
  text: string;
  value: string;
}

@Component({ components: {} })
export default class ManagementGroupDetails extends Vue {
  @Ref()
  editManagementGroupFormElement!: HTMLFormElement;
  @Ref()
  moveManagementGroupFormElement!: HTMLFormElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public managementGroup: InterfaceManagementGroup = emptyManagementGroup();
  public deleteConfirmationForm = false;
  public editManagementGroupForm = false;
  public moveManagementGroupForm = false;
  public operationInProgress = false;
  public managementGroupSelectList: ManagementGroupSelectList[] = [];

  @managementGroups.State
  public selectedManagementGroup!: InterfaceManagementGroup;

  @managementGroups.State
  public existingManagementGroups!: InterfaceManagementGroup[];

  @managementGroups.State
  public managementGroupAlert!: Alert;

  @managementGroups.State
  public managementGroupDetailAlert!: Alert;

  @managementGroups.State
  public pageDataManagementGroups!: PageData;

  @managementGroups.Mutation
  public clearManagementGroupAlert!: () => void;

  @managementGroups.Action
  public getManagementGroup!: (id: string) => Promise<boolean>;

  @managementGroups.Action
  public moveManagementGroup!: (
    managementGroup: InterfaceManagementGroup
  ) => Promise<boolean>;

  @managementGroups.Action
  public deleteManagementGroup!: (
    managementGroup: InterfaceManagementGroup
  ) => Promise<boolean>;

  @managementGroups.Action
  public updateManagementGroup!: (
    managementGroup: InterfaceManagementGroup
  ) => Promise<boolean>;

  @managementGroups.Action
  public getManagementGroups!: (pageData: PageData) => Promise<boolean>;

  mounted(): void {
    this.getManagementGroup(this.$router.currentRoute.params.id);
    this.getManagementGroups(this.pageDataManagementGroups).then(() => {
      this.managementGroupSelectList = this.existingManagementGroups.map(
        (managementGroup) => {
          return { text: managementGroup.name, value: managementGroup.id };
        }
      );
    });
  }

  public reload(): void {
    window.location.reload();
  }

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    name: (value: string): string | boolean => {
      const pattern = /^[a-zA-Z0-9-_]{3,50}$/;
      const onlyNumbers = /^\d+$/;
      return (
        (!onlyNumbers.test(value) && pattern.test(value)) ||
        "Invalid ManagementGroup Name. Names must be a alphanumeric (without spaces) and at least 3 characters long and less than 50 characters."
      );
    },
  };

  public readManagementGroup(managementGroup: InterfaceManagementGroup): void {
    this.managementGroup = new ManagementGroup(
      managementGroup.id,
      managementGroup.name,
      managementGroup.parentId,
      managementGroup.parentName
    );
  }

  public deleteManagementGroupDialog(): void {
    this.readManagementGroup(this.selectedManagementGroup);
    this.deleteConfirmationForm = true;
  }

  public editManagementGroupDialog(): void {
    this.readManagementGroup(this.selectedManagementGroup);
    this.editManagementGroupForm = true;
  }

  public moveManagementGroupDialog(): void {
    this.readManagementGroup(this.selectedManagementGroup);
    this.moveManagementGroupForm = true;
  }

  private contextLoadManagementGroup(id: string) {
    this.getManagementGroup(id);
  }

  public async moveManagementGroupDialogAction(
    managementGroup: InterfaceManagementGroup
  ): Promise<void> {
    if (this.moveManagementGroupFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.moveManagementGroup(managementGroup)) {
        this.closeMoveManagementGroupForm();
      }
      this.operationInProgress = false;
      this.contextLoadManagementGroup(managementGroup.id);
    }
  }

  public closeEditManagementGroupForm(): void {
    this.managementGroup = emptyManagementGroup();
    this.editManagementGroupForm = false;
    this.clearManagementGroupAlert();
  }

  public closeMoveManagementGroupForm(): void {
    this.managementGroup = emptyManagementGroup();
    this.moveManagementGroupForm = false;
    this.clearManagementGroupAlert();
  }
  public closeDeleteManagementGroupDialog(): void {
    this.managementGroup = emptyManagementGroup();
    this.deleteConfirmationForm = false;
    this.clearManagementGroupAlert();
  }

  public async deleteManagementGroupDialogAction(
    managementGroup: InterfaceManagementGroup
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteManagementGroup(managementGroup)) {
      this.closeDeleteManagementGroupDialog();
    }
    this.operationInProgress = false;
    this.contextLoadManagementGroup(managementGroup.id);
  }
  public async editManagementGroupDialogAction(
    managementGroup: InterfaceManagementGroup
  ): Promise<void> {
    if (this.editManagementGroupFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.updateManagementGroup(managementGroup)) {
        this.closeEditManagementGroupForm();
      }
      this.operationInProgress = false;
      this.contextLoadManagementGroup(managementGroup.id);
    }
  }
}
