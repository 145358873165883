export interface InterfaceSonarProject {
  id: string;
  name: string;
  organizationId: string;
  organizationName: string;
}

export interface InterfaceSonarProjectAudit {
  rev: number;
  id: string;
  name: string;
  organziationId: string;
  username: string;
  timestamp: string;
  revtype: number;
}

export const emptySonarProject = (
  organizationId = "",
  organizationName = ""
): SonarProject => ({
  id: "",
  name: "",
  organizationId: organizationId,
  organizationName: organizationName,
});

export class SonarProject implements InterfaceSonarProject {
  constructor(
    public id: string,
    public name: string,
    public organizationId: string,
    public organizationName: string
  ) {}
}
