import Vue from "vue";
import { Component } from "vue-property-decorator";
import { namespace } from "vuex-class";

import Organizations from "@/views/organizations/Organizations.vue";
import PortalGroups from "@/views/portalGroups/PortalGroups.vue";
import { InterfaceSonarServer } from "@/models/SonarServer.model";
import { Alert } from "@/models/Alert.model";

const sonarServers = namespace("SonarServers");

@Component({ components: { Organizations, PortalGroups } })
export default class SonarServerDetails extends Vue {
  showPortalGroups = false;

  @sonarServers.State
  public selectedSonarServer!: InterfaceSonarServer;

  @sonarServers.State
  public sonarServerDetailAlert!: Alert;

  @sonarServers.Action
  public getSonarServer!: (id: string) => Promise<boolean>;

  mounted(): void {
    this.getSonarServer(this.$router.currentRoute.params.id);
  }

  public reload(): void {
    window.location.reload();
  }
}
