export interface InterfaceKubecostEndpoint {
  id: string;
  name: string;
  sharedNamespaces: string;
  url: string;
  azureSubscriptionId: string;
  azureSubscriptionName: string;
}

export const emptyKubecostEndpoint = (): InterfaceKubecostEndpoint => ({
  id: "",
  name: "",
  sharedNamespaces: "",
  url: "",
  azureSubscriptionId: "",
  azureSubscriptionName: "",
});

export class KubecostEndpoint implements InterfaceKubecostEndpoint {
  constructor(
    public id: string,
    public name: string,
    public sharedNamespaces: string,
    public url: string,
    public azureSubscriptionId: string,
    public azureSubscriptionName: string
  ) {}
}
