import { PageData } from "./PageData.model";

export interface InterfaceSonarUser {
  id: string;
  username: string;
  sonarServerId: string;
  sonarServerName: string;
}

export interface InterfaceSonarUserAudit {
  rev: number;
  id: string;
  name: string;
  sonarServerId: string;
  username: string;
  timestamp: string;
  revtype: number;
}

export const emptySonarUser = (): SonarUser => ({
  id: "",
  username: "",
  sonarServerId: "",
  sonarServerName: "",
});

export class SonarUser implements InterfaceSonarUser {
  constructor(
    public id: string,
    public username: string,
    public sonarServerId: string,
    public sonarServerName: string
  ) {}
}

export interface InterfacePagedSonarUserMembership {
  sonarUserId: string;
  pageIndex: number;
  pageSize: number;
}

export interface InterfaceSonarUserSearchRequest {
  username: string;
  sonarServerId: string;
  pageIndex: number;
  pageSize: number;
}

export interface InterfaceSonarUserSearchResult {
  paging: PageData;
  users: InterfaceSonarUser[];
}
