import { InterfaceCostCenterAudit } from "@/models/CostCenter.model";

import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { PageData } from "@/models/PageData.model";
import {
  emptyAuditLogRequest,
  InterfaceAuditLogRequest,
} from "@/models/Audit.model";
import { Alert } from "@/models/Alert.model";

const auditLogs = namespace("AuditLogs");

@Component({})
export default class AuditLogsCostCenters extends Vue {
  public datePicker = false;
  public dateRange: string[] = [];
  public auditLogRequest: InterfaceAuditLogRequest = emptyAuditLogRequest();

  @auditLogs.State
  public foundCostCenterAuditLogs!: InterfaceCostCenterAudit[];

  @auditLogs.State
  public pageDataCostCenterAuditLogs!: PageData;

  @auditLogs.State
  public auditLogsAlert!: Alert;

  @auditLogs.State
  public inProgress!: boolean;

  @auditLogs.Action
  public searchCostCenterAuditLogs!: (
    request: InterfaceAuditLogRequest
  ) => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      {
        text: "Cost Center ID",
        align: "start",
        value: "name",
        sortable: false,
      },
    ];
    const responsibleId = [
      {
        text: "Responsible ID",
        align: "start",
        value: "responsibleId",
        sortable: false,
      },
    ];
    const responsibleEmail = [
      {
        text: "Responsible Email",
        align: "start",
        value: "responsibleEmail",
        sortable: false,
      },
    ];
    const username = [
      {
        text: "User",
        align: "start",
        value: "username",
        sortable: false,
      },
    ];
    const revtstmp = [
      { text: "Time", align: "start", value: "revtstmp", sortable: true },
    ];
    const revtype = [
      { text: "Action", align: "start", value: "revtype", sortable: false },
    ];
    return revtype.concat(
      id,
      name,
      responsibleId,
      responsibleEmail,
      username,
      revtstmp
    );
  }

  @Watch("pageDataCostCenterAuditLogs.options", {
    deep: true,
  })
  optionsChange(): void {
    this.auditLogRequest.pageData.options =
      this.pageDataCostCenterAuditLogs.options;
    this.auditLogRequest.dateRange = this.dateRange;
    this.searchCostCenterAuditLogs(this.auditLogRequest);
  }

  @Watch("auditLogRequest.keyword", {
    deep: true,
  })
  searchStringChange(): void {
    this.auditLogRequest.dateRange = this.dateRange;
    this.searchCostCenterAuditLogs(this.auditLogRequest);
  }

  public setDateRange(dateRange: string[]): void {
    this.auditLogRequest.dateRange = dateRange;
    this.datePicker = false;
    this.searchCostCenterAuditLogs(this.auditLogRequest);
  }

  public clearDateRange(): void {
    this.datePicker = false;
    this.dateRange = [];
    this.auditLogRequest.dateRange = [];
    this.searchCostCenterAuditLogs(this.auditLogRequest);
  }
}
