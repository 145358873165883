import {
  InterfacePortalUsersSearchRequest,
  InterfacePortalUsersSearchResult,
  PortalUser,
  InterfacePortalUser,
} from "@/models/PortalUser.model";
import {
  emptyCostCenter,
  InterfaceCostCenter,
  CostCenter,
} from "@/models/CostCenter.model";
import Vue from "vue";
import { Component, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { PageData } from "@/models/PageData.model";
import { Alert } from "@/models/Alert.model";
import { InterfaceOrganization } from "@/models/Organization.model";
import SearchInput from "../searchInput/SearchInput.vue";

import ServerSideAutocomplete from "../serverSideAutocomplete/ServerSideAutocomplete.vue";
import {
  emptySearchByKeywordRequest,
  SearchByKeywordRequest,
} from "@/models/Search.model";

const costCenters = namespace("CostCenters");
const portalUsers = namespace("PortalUsers");
const organizations = namespace("Organizations");

interface FetchParams {
  search: string;
  page: number;
}

@Component({
  components: {
    ServerSideAutocomplete,
    SearchInput,
  },
})
export default class CostCenters extends Vue {
  @Ref()
  createCostCenterFormElement!: HTMLFormElement;
  @Ref()
  createCostCenterFormElementCostCenterIdField!: HTMLFormElement;
  @Ref()
  editCostCenterFormElement!: HTMLFormElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public costCenter: InterfaceCostCenter = emptyCostCenter();
  public createCostCenterForm = false;
  public editCostCenterForm = false;
  public deleteConfirmationForm = false;
  public hidden = false;
  public operationInProgress = false;
  public portalUserItems: PortalUser[] = [];
  public findPortalUsers = null;
  public searchRequest: SearchByKeywordRequest = emptySearchByKeywordRequest();

  @costCenters.State
  public existingCostCenters!: InterfaceCostCenter[];

  @costCenters.State
  public selectedCostCenter!: InterfaceCostCenter;

  @costCenters.State
  public pageDataCostCenters!: PageData;

  @costCenters.State
  public costCenterAlert!: Alert;

  @costCenters.State
  public costCenterTableAlert!: Alert;

  @costCenters.State
  public inProgress!: boolean;

  @portalUsers.State
  public loggedInPortalUser!: InterfacePortalUser;

  @portalUsers.State
  public foundPortalUsers!: InterfacePortalUser[];

  @costCenters.Mutation
  public setPageData!: (pageData: PageData) => void;

  @costCenters.Mutation
  public clearCostCenterAlert!: () => void;

  @costCenters.Mutation
  public setCostCenterAlert!: (alert: Alert) => void;

  @costCenters.Action
  public getCostCenters!: (
    searchRequest: SearchByKeywordRequest
  ) => Promise<boolean>;

  @costCenters.Action
  public getCostCenter!: (id: string) => Promise<boolean>;

  @organizations.State
  public pageDataOrganizations!: PageData;

  @organizations.State
  public organizationsSelectList!: InterfaceOrganization[];

  @organizations.State
  public organizationAutocompleteLoadMore!: boolean;

  @organizations.Action
  public getOrganizationsSelectList!: (
    fetchParams: FetchParams
  ) => Promise<boolean>;

  @costCenters.Action
  public storeCostCenter!: (
    costCenter: InterfaceCostCenter
  ) => Promise<boolean>;

  @costCenters.Action
  public updateCostCenter!: (
    costCenter: InterfaceCostCenter
  ) => Promise<boolean>;

  @costCenters.Action
  public deleteCostCenter!: (
    costCenter: InterfaceCostCenter
  ) => Promise<boolean>;

  @portalUsers.Action
  public searchPortalUsers!: (
    searchRequest: InterfacePortalUsersSearchRequest
  ) => Promise<InterfacePortalUsersSearchResult>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      {
        text: "Cost Center ID",
        align: "start",
        value: "name",
        sortable: false,
      },
    ];
    const responsibleId = [
      {
        text: "Responsible ID",
        align: "start",
        value: "responsibleId",
        sortable: false,
      },
    ];
    const responsibleEmail = [
      {
        text: "Responsible Email",
        align: "start",
        value: "responsibleEmail",
        sortable: false,
      },
    ];
    const organizationId = [
      {
        text: "Organization ID",
        align: "start",
        value: "organizationId",
        sortable: false,
      },
    ];
    const organizationName = [
      {
        text: "Organization Name",
        align: "start",
        value: "organizationName",
        sortable: false,
      },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    if (this.$router.currentRoute.fullPath === "/costs/centers") {
      if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
        return id.concat(
          name,
          responsibleId,
          responsibleEmail,
          organizationId,
          organizationName,
          actions
        );
      }
      return name.concat(
        responsibleId,
        responsibleEmail,
        organizationName,
        actions
      );
    }
    return name;
  }

  @Watch("pageDataCostCenters.options", {
    deep: true,
  })
  optionsChange(): void {
    this.searchRequest.pageData.options = this.pageDataCostCenters.options;
    this.contextLoadCostCenters();
  }
  @Watch("searchRequest.keyword", {
    deep: true,
  })
  searchValueChange(): void {
    this.searchRequest.pageData.options = this.pageDataCostCenters.options;
    this.contextLoadCostCenters();
  }

  @Watch("findPortalUsers")
  onPropertyChange(value: string): void {
    if (value && value.length > 2) {
      const searchRequest: InterfacePortalUsersSearchRequest = {
        name: value,
        pageIndex: 1,
        pageSize: 100,
      };
      this.searchPortalUsers(searchRequest).then(() => {
        this.portalUserItems = this.foundPortalUsers;
      });
    }
  }

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    name: (value: string): string | boolean => {
      const pattern = /^[a-zA-Z0-9-_/]{3,50}$/;
      return (
        pattern.test(value) ||
        "Invalid CostCenter Name. Names must be a alphanumeric (without spaces) and at least 3 characters long and less than 50 characters."
      );
    },
    email: (value: string): string | boolean => {
      const pattern =
        /^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/;
      return pattern.test(value) || "Invalid Email Address.";
    },
  };

  private contextLoadCostCenters() {
    if (this.$router.currentRoute.fullPath === "/costs/centers") {
      this.loadAllCostCenters();
    } else {
      // eslint-disable-next-line no-console
      console.error("No matched router path");
    }
  }

  loadAllCostCenters(): void {
    this.getCostCenters(this.searchRequest);
  }

  public openCostCenterDetails(costCenterId: string): void {
    this.$router.push({
      name: "CostCenterDetails",
      params: { id: costCenterId },
    });
  }

  public editCostCenterDialog(costCenter: InterfaceCostCenter): void {
    this.getCostCenter(costCenter.id).then(() => {
      this.portalUserItems = this.selectedCostCenter.notificationUsers;
    });
    this.costCenter = new CostCenter(
      costCenter.id,
      costCenter.name,
      costCenter.responsibleId,
      costCenter.responsibleEmail,
      costCenter.organizationId,
      costCenter.organizationName,
      costCenter.notificationUsers
    );
    this.editCostCenterForm = true;
  }

  public async editCostCenterDialogAction(
    costCenter: InterfaceCostCenter
  ): Promise<void> {
    if (this.editCostCenterFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.updateCostCenter(costCenter)) {
        this.closeEditCostCenterForm();
      }
      this.operationInProgress = false;
      this.contextLoadCostCenters();
    }
  }

  public deleteCostCenterDialog(costCenter: InterfaceCostCenter): void {
    this.costCenter = costCenter;
    this.deleteConfirmationForm = true;
  }

  public async deleteCostCenterDialogAction(
    costCenter: InterfaceCostCenter
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteCostCenter(costCenter)) {
      this.closeDeleteCostCenterDialog();
    }
    this.operationInProgress = false;
    this.contextLoadCostCenters();
  }

  public async storeCostCenterDialogAction(
    costCenter: InterfaceCostCenter
  ): Promise<void> {
    if (this.createCostCenterFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.storeCostCenter(costCenter)) {
        this.closeCreateCostCenterForm();
      }
      this.operationInProgress = false;
      this.contextLoadCostCenters();
    }
  }

  public async fetchCreateCostCenterOrganizationSelectList(
    search: string,
    page: number
  ): Promise<Array<unknown>> {
    this.pageDataOrganizations.pageIndex = page;
    await this.getOrganizationsSelectList({
      search: search,
      page: page,
    });
    return this.organizationsSelectList;
  }

  public closeCreateCostCenterForm(): void {
    this.costCenter = emptyCostCenter();
    this.createCostCenterFormElement.reset();
    this.createCostCenterForm = false;
    this.clearCostCenterAlert();
  }

  public closeEditCostCenterForm(): void {
    this.costCenter = emptyCostCenter();
    this.editCostCenterForm = false;
    this.clearCostCenterAlert();
  }

  public closeDeleteCostCenterDialog(): void {
    this.costCenter = emptyCostCenter();
    this.deleteConfirmationForm = false;
    this.clearCostCenterAlert();
  }
}
