import { Alert, emptyAlert } from "@/models/Alert.model";
import { emptyPageData, PageData } from "@/models/PageData.model";
import { SearchByKeywordRequest } from "@/models/Search.model";
import {
  emptyRequest,
  InterfaceRequest,
} from "@/models/SubscriptionRequests.model";
import environmentConfig from "@/utils/environmentConfig";
import axios, { AxiosError, AxiosResponse } from "axios";
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/requests/subscriptions`;

@Module({ namespaced: true })
class SubscriptionRequests extends VuexModule {
  public selectedRequest: InterfaceRequest = emptyRequest();
  public existingSubscriptionRequests: InterfaceRequest[] = [];
  public pageDataSubscriptionRequests: PageData = emptyPageData();
  public pageDataMaintenance: PageData = emptyPageData();
  public requestAlert: Alert = emptyAlert();
  public requestTableAlert: Alert = emptyAlert();
  public requestDetailAlert: Alert = emptyAlert();
  public inProgress = false;

  @Mutation
  public setSubscriptionRequests(data: InterfaceRequest[]): void {
    this.existingSubscriptionRequests = data;
  }

  @Mutation
  public setPageData(pageData: PageData): void {
    this.pageDataSubscriptionRequests.totalItems = pageData.totalItems;
    this.pageDataSubscriptionRequests.totalPages = pageData.totalPages;
  }

  @Mutation
  public setPageDataMaintenance(pageData: PageData): void {
    this.pageDataMaintenance.totalItems = pageData.totalItems;
    this.pageDataMaintenance.totalPages = pageData.totalPages;
  }

  @Mutation
  public setSelectedRequest(data: InterfaceRequest): void {
    this.selectedRequest = data;
  }

  @Mutation
  public setRequestAlert(data: Alert): void {
    this.requestAlert = data;
  }

  @Mutation
  public clearRequestAlert(): void {
    this.requestAlert = emptyAlert();
  }

  @Mutation
  public setRequestTableAlert(data: Alert): void {
    this.requestTableAlert = data;
  }

  @Mutation
  public clearRequestTableAlert(): void {
    this.requestTableAlert = emptyAlert();
  }

  @Mutation
  public setRequestDetailAlert(data: Alert): void {
    this.requestDetailAlert = data;
  }

  @Mutation
  public clearRequestDetailAlert(): void {
    this.requestDetailAlert = emptyAlert();
  }

  @Mutation
  public setInProgress(data: boolean): void {
    this.inProgress = data;
  }

  @Action
  public handleError(error: AxiosError): boolean {
    let alert: Alert;
    if (error.response) {
      const data = error.response.data as { message: string };
      alert = new Alert(error.response.status, data.message);
    } else {
      alert = new Alert(0, error.message);
    }
    this.context.commit("setRequestAlert", alert);
    return false;
  }

  @Action
  public async storeRequest(request: InterfaceRequest): Promise<boolean> {
    return axios
      .post(`${baseApiUrl}`, {
        name: request.name,
        description: request.description,
        requesterId: request.requesterId,
        requesterUsername: request.requesterUsername,
        managementGroupId: request.managementGroupId,
        organizationId: request.organizationId,
        costCenterId: request.costCenterId,
        state: "PENDING",
        subscriptionType: request.subscriptionType,
        subscriptionAction: "CREATE",
        answer: request.answer,
      })
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async getSubscriptionRequests(
    searchRequest: SearchByKeywordRequest
  ): Promise<boolean> {
    const params = new URLSearchParams({
      pageIndex: `${searchRequest.pageData.options.page}`,
      pageSize: `${searchRequest.pageData.options.itemsPerPage}`,
    }).toString();
    this.context.commit("setInProgress", true);
    this.context.commit("clearRequestTableAlert");
    let searchKeyword = "";
    if (searchRequest.keyword != "" && searchRequest.keyword != undefined) {
      searchKeyword = "/search/" + searchRequest.keyword;
    }
    return axios
      .get(`${baseApiUrl}${searchKeyword}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSubscriptionRequests", response.data.list);
        this.context.commit("setPageData", response.data.paging);
        this.context.commit("setPageDataMaintenance", response.data.paging);
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setInProgress", false);
        this.context.commit(
          "setRequestTableAlert",
          new Alert(0, error.message)
        );
        return false;
      });
  }

  @Action
  public async getRequest(id: string): Promise<boolean> {
    this.context.commit("setSelectedRequest", emptyRequest());
    this.context.commit("clearRequestDetailAlert");
    return axios
      .get(`${baseApiUrl}/${id}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSelectedRequest", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          const data = error.response.data as { message: string };
          alert = new Alert(error.response.status, data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setRequestDetailAlert", alert);
        return false;
      });
  }

  @Action
  public async updateRequest(request: InterfaceRequest): Promise<boolean> {
    request.subscriptionAction = "CREATE";
    return axios
      .put(`${baseApiUrl}/${request.id}`, request)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async updateRequestState(request: InterfaceRequest): Promise<boolean> {
    request.subscriptionAction = "CREATE";
    return axios
      .patch(`${baseApiUrl}/${request.id}/state/${request.state}`, request)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async deleteRequest(request: InterfaceRequest): Promise<boolean> {
    return axios
      .delete(`${baseApiUrl}/${request.id}`)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }
}

export default SubscriptionRequests;
