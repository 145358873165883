import Vue from "vue";
import { Component } from "vue-property-decorator";
import SonarUserGroupMembership from "@/views/sonarUsers/SonarUserGroupMembership.vue";
import { namespace } from "vuex-class";
import {
  InterfacePagedSonarUserMembership,
  InterfaceSonarUser,
} from "@/models/SonarUser.model";
import { Alert } from "@/models/Alert.model";
import { PageData } from "@/models/PageData.model";

const sonarUsers = namespace("SonarUsers");

@Component({ components: { SonarUserGroupMembership } })
export default class SonarUserDetails extends Vue {
  @sonarUsers.State
  public sonarUserDetailAlert!: Alert;

  @sonarUsers.State
  public selectedSonarUser!: InterfaceSonarUser;

  @sonarUsers.State
  public pageDataSonarUserGroups!: PageData;

  @sonarUsers.Action
  public getSonarUser!: (id: string) => Promise<boolean>;

  @sonarUsers.Action
  public getSonarUserGroupMembership!: (
    pagedSonarUserMembership: InterfacePagedSonarUserMembership
  ) => Promise<boolean>;

  @sonarUsers.Mutation
  public clearSonarUserDetailAlert!: () => void;

  mounted(): void {
    this.getSonarUser(this.$router.currentRoute.params.id);
  }

  public async reload(): Promise<void> {
    if (await this.getSonarUser(this.$router.currentRoute.params.id)) {
      this.clearSonarUserDetailAlert();
      this.getSonarUserGroupMembership({
        sonarUserId: this.$router.currentRoute.params.id,
        pageIndex: this.pageDataSonarUserGroups.options.page,
        pageSize: this.pageDataSonarUserGroups.options.itemsPerPage,
      });
    }
  }
}
