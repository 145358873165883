import { Alert } from "@/models/Alert.model";
import { PageData } from "@/models/PageData.model";
import { InterfacePortalUser } from "@/models/PortalUser.model";
import { InterfaceSonarServer } from "@/models/SonarServer.model";
import { InterfaceOrganization } from "@/models/Organization.model";
import {
  emptyRequest,
  InterfaceRequest,
  Request,
} from "@/models/Request.model";
import {
  InterfacePortalGroup,
  InterfacePagedPortalGroupMembership,
} from "@/models/PortalGroup.model";
import router from "@/router";

import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import {
  emptySearchByKeywordRequest,
  SearchByKeywordRequest,
} from "@/models/Search.model";

const requests = namespace("Requests");
const portalGroups = namespace("PortalGroups");
const portalUsers = namespace("PortalUsers");
const sonarServers = namespace("SonarServers");
const organizations = namespace("Organizations");

interface SonarSelectList {
  text: string;
  value: string;
}

@Component({})
export default class Requests extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public request: InterfaceRequest = emptyRequest();
  public createRequestForm = false;
  public editRequestForm = false;
  public operationInProgress = false;
  public deleteRequestConfirmationForm = false;
  public updateRequestAccessTokenForm = false;
  public syncInProgress = false;
  public syncInProgressText = "";
  public syncInProgressType = "info";
  public hidden = false;
  public sonarServerSelectList: SonarSelectList[] = [];

  public sonarServerListEnabled = true;
  public selectedSonarServer: SonarSelectList | undefined;
  public organizationCreatedState = 0;
  public portalGroupCreatedState = 0;
  public userAssignedToPortalGroupState = 0;
  public searchRequest: SearchByKeywordRequest = emptySearchByKeywordRequest();

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    length: (v: string): string | boolean =>
      (v && v.length <= 250) || "Description must be less than 250 characters",
    name: (value: string): string | boolean => {
      const pattern = /^[a-zA-Z 0-9/,#._-]{3,50}$/;
      const onlyNumbers = /^\d+$/;
      return (
        (!onlyNumbers.test(value) && pattern.test(value)) ||
        "Invalid request name. Names must be an alphanumeric (without spaces) at least 3 characters long and less than 50 characters."
      );
    },
  };

  @Ref()
  createRequestFormElement!: HTMLFormElement;

  @Ref()
  editRequestFormElement!: HTMLFormElement;

  @Ref()
  createRequestFormElementNameField!: HTMLFormElement;

  @Ref()
  createDepartmentFormElementNameField!: HTMLFormElement;

  @Ref()
  createDescriptionFormElementNameField!: HTMLFormElement;

  @Ref()
  editRequestFormElementNameField!: HTMLFormElement;

  @Ref()
  editDepartmentFormElementNameField!: HTMLFormElement;

  @Ref()
  editDescriptionFormElementNameField!: HTMLFormElement;

  @portalGroups.State
  public existingPortalGroups!: InterfacePortalGroup[];

  @portalGroups.State
  public pageDataPortalGroups!: PageData;

  @portalGroups.State
  public portalGroupUserMembership!: InterfacePortalUser[];

  @portalGroups.Action
  public getPortalGroups!: (pageData: PageData) => Promise<boolean>;

  @portalGroups.Action
  public getPortalGroupUserMembership!: (
    pagedPortalGroupMembership: InterfacePagedPortalGroupMembership
  ) => Promise<boolean>;

  @portalGroups.Action
  public storePortalGroup!: (
    portalGroup: InterfacePortalGroup
  ) => Promise<boolean>;

  @portalGroups.Action
  public addPortalUserToPortalGroup!: (
    portalGroupId: string
  ) => Promise<boolean>;

  @portalGroups.Action
  public addSonarServerToPortalGroup!: (
    sonarServerId: string
  ) => Promise<boolean>;

  @portalGroups.Action
  public addOrganizationToPortalGroup!: (
    organizationId: string
  ) => Promise<boolean>;

  @portalUsers.State
  public loggedInPortalUser!: InterfacePortalUser;

  @portalUsers.State
  public existingPortalUsers!: InterfacePortalUser[];

  @portalUsers.State
  public pageDataPortalUsers!: PageData;

  @portalUsers.Action
  public getPortalUsers!: (pageData: PageData) => Promise<boolean>;

  @sonarServers.State
  public existingSonarServers!: InterfaceSonarServer[];

  @sonarServers.State
  public pageDataSonarServers!: PageData;

  @sonarServers.Action
  public getSonarServers!: (pageData: PageData) => Promise<boolean>;

  @organizations.State
  public existingOrganizations!: InterfaceOrganization[];

  @organizations.State
  public pageDataOrganizations!: PageData;

  @organizations.Action
  public getOrganizations!: (
    searchRequest: SearchByKeywordRequest
  ) => Promise<boolean>;

  @organizations.Action
  public storeOrganization!: (
    organization: InterfaceOrganization
  ) => Promise<boolean>;

  @requests.State
  public existingRequests!: InterfaceRequest[];

  @requests.State
  public pageDataRequests!: PageData;

  @requests.State
  public inProgress!: boolean;

  @requests.State
  public requestAlert!: Alert;

  @requests.State
  public requestTableAlert!: Alert;

  @requests.Mutation
  public setPageData!: (pageData: PageData) => void;

  @requests.Mutation
  public clearRequestAlert!: () => void;

  @requests.Mutation
  public setRequestAlert!: (alert: Alert) => void;

  @requests.Action
  public getRequests!: (pageData: PageData) => Promise<boolean>;

  @requests.Action
  public storeRequest!: (request: InterfaceRequest) => Promise<boolean>;

  @requests.Action
  public updateRequest!: (request: InterfaceRequest) => Promise<boolean>;

  @requests.Action
  public updateRequestAccessToken!: (
    request: InterfaceRequest
  ) => Promise<boolean>;

  @requests.Action
  public deleteRequest!: (request: InterfaceRequest) => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const department = [
      {
        text: "Department",
        align: "start",
        value: "department",
        sortable: false,
      },
    ];
    const requesterUsername = [
      {
        text: "Requester",
        align: "start",
        value: "requesterUsername",
        sortable: false,
      },
    ];

    const state = [
      {
        text: "State",
        align: "start",
        value: "state",
        sortable: false,
      },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return id.concat(requesterUsername, name, department, state, actions);
    }
    return name.concat(department, state, actions);
  }

  @Watch("pageDataRequests.options", {
    deep: true,
  })
  optionsChange(): void {
    this.getRequests(this.pageDataRequests);
    this.loadAllResources();
  }

  loadAllResources(): void {
    this.getPortalGroups(this.pageDataPortalGroups);
    this.getOrganizations(this.searchRequest);
    this.getPortalUsers(this.pageDataPortalUsers);
    this.getSonarServers(this.pageDataSonarServers).then(() => {
      this.sonarServerSelectList = this.existingSonarServers.map((server) => {
        return { text: server.name, value: server.id };
      });
    });
  }

  public openRequestDetails(requestId: string): void {
    router.push({
      name: "RequestDetails",
      params: { id: requestId },
    });
  }

  public editRequestDialog(request: InterfaceRequest): void {
    this.organizationCreatedState = 1;
    this.portalGroupCreatedState = 1;
    this.userAssignedToPortalGroupState = 1;
    this.sonarServerListEnabled = true;
    this.request = new Request(
      request.id,
      request.name,
      request.requesterId,
      request.requesterUsername,
      request.description,
      request.sonarServerId,
      request.department,
      request.state,
      request.answer
    );
    this.checkResources(request.name);
    this.editRequestForm = true;
  }

  public async checkResources(organization: string): Promise<void> {
    const foundOrganization = this.existingOrganizations.find(
      (d) => d.name === organization
    );
    const foundSonarServer = this.existingSonarServers.find(
      (d) => d.id === foundOrganization?.sonarServerId
    );
    this.selectedSonarServer = this.sonarServerSelectList.find(
      (d) => d.text === foundSonarServer?.name
    );
    const foundPortalGroup = this.existingPortalGroups.find(
      (d) => d.name === organization + "-group"
    );
    if (
      typeof foundSonarServer != "undefined" &&
      this.selectedSonarServer != undefined
    ) {
      this.sonarServerListEnabled = false;
    } else {
      this.sonarServerListEnabled = true;
    }
    if (typeof foundOrganization != "undefined") {
      this.organizationCreatedState = 2;
    } else {
      this.organizationCreatedState = 0;
    }
    if (typeof foundPortalGroup != "undefined") {
      this.portalGroupCreatedState = 2;
      await this.getPortalGroupUserMembership({
        portalGroupId: foundPortalGroup.id,
        pageIndex: 1,
        pageSize: 100,
      });
      const foundMembership = this.portalGroupUserMembership.find(
        (d) => d.username == this.request.requesterUsername
      );
      if (typeof foundMembership != "undefined") {
        this.userAssignedToPortalGroupState = 2;
      } else {
        this.userAssignedToPortalGroupState = 0;
      }
    } else {
      this.portalGroupCreatedState = 0;
      this.userAssignedToPortalGroupState = 0;
    }
  }

  public async editRequestDialogAction(
    request: InterfaceRequest
  ): Promise<void> {
    if (this.editRequestFormElement.validate()) {
      this.operationInProgress = true;
      request.sonarServerId = this.sonarServerSelectList[0].value;
      if (await this.updateRequest(request)) {
        this.closeEditRequestForm();
      }
      this.operationInProgress = false;
      this.getRequests(this.pageDataRequests);
    }
  }

  public deleteRequestDialog(request: InterfaceRequest): void {
    this.request = request;
    this.deleteRequestConfirmationForm = true;
  }

  public async deleteRequestDialogAction(
    request: InterfaceRequest
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteRequest(request)) {
      this.closeDeleteRequestDialog();
    }
    this.operationInProgress = false;
    this.getRequests(this.pageDataRequests);
  }

  public updateRequestTokenDialog(request: InterfaceRequest): void {
    this.request = new Request(
      request.id,
      request.name,
      request.requesterId,
      request.requesterUsername,
      request.description,
      request.sonarServerId,
      request.department,
      request.state,
      request.answer
    );
    this.updateRequestAccessTokenForm = true;
  }

  public async storeRequestDialogAction(
    request: InterfaceRequest
  ): Promise<void> {
    if (this.createRequestFormElement.validate()) {
      this.operationInProgress = true;
      request.requesterId = this.loggedInPortalUser.id;
      request.requesterUsername = this.loggedInPortalUser.username;
      request.answer = "ANSWER: ";
      if (await this.storeRequest(request)) {
        this.closeCreateRequestForm();
        this.getRequests(this.pageDataRequests);
      }
      this.operationInProgress = false;
    }
  }

  public closeCreateRequestForm(): void {
    this.createRequestFormElementNameField.reset();
    this.createDepartmentFormElementNameField.reset();
    this.createDescriptionFormElementNameField.reset();
    this.createRequestForm = false;
    this.clearRequestAlert();
  }

  public closeEditRequestForm(): void {
    this.editRequestFormElementNameField.reset();
    this.editDepartmentFormElementNameField.reset();
    this.editDescriptionFormElementNameField.reset();
    this.editRequestForm = false;
    this.clearRequestAlert();
  }

  public closeDeleteRequestDialog(): void {
    const clearedRequest = emptyRequest();
    clearedRequest.requesterUsername = this.loggedInPortalUser.username;
    this.request = clearedRequest;
    this.deleteRequestConfirmationForm = false;
    this.clearRequestAlert();
  }
}
