import { emptyPageData, PageData } from "./PageData.model";

export interface SearchByKeywordRequest {
  keyword: string;
  pageData: PageData;
}

export const emptySearchByKeywordRequest = (): SearchByKeywordRequest => ({
  keyword: "",
  pageData: emptyPageData(),
});

export interface SearchServiceAgreementByKeywordAndDateRangeRequest {
  keyword: string;
  startDate: string;
  endDate: string;
  pageData: PageData;
}

export const emptySearchServiceAgreementByKeywordAndDateRangeRequest =
  (): SearchServiceAgreementByKeywordAndDateRangeRequest => ({
    keyword: "",
    startDate: "",
    endDate: "",
    pageData: emptyPageData(),
  });
