import { Alert, emptyAlert } from "@/models/Alert.model";
import {
  InterfaceCostKubecost,
  emptyCostKubecost,
} from "@/models/CostKubecost.model";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse } from "axios";
import environmentConfig from "@/utils/environmentConfig";

interface FetchParams {
  costCenter: string;
  billingMonth: string;
}

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/azure/kubecost`;

@Module({ namespaced: true })
class CostKubecost extends VuexModule {
  public selectedKubecostCost: InterfaceCostKubecost = emptyCostKubecost();
  public kubecostCostDetailAlert: Alert = emptyAlert();
  public costKubecostTableAlert: Alert = emptyAlert();
  public selectedKubecostCostsList: InterfaceCostKubecost[] = [];
  public billingMonth = "";
  public rangeDate: string[] = [];

  @Mutation
  public setSelectedKubecostCost(data: InterfaceCostKubecost): void {
    this.selectedKubecostCost = data;
  }

  @Mutation
  public setKubecostCostDetailAlert(data: Alert): void {
    this.kubecostCostDetailAlert = data;
  }

  @Mutation
  public setSelectedKubecostCostsList(data: InterfaceCostKubecost[]): void {
    this.selectedKubecostCostsList = data;
  }

  @Mutation
  public setKubecostCostListAlert(data: Alert): void {
    this.costKubecostTableAlert = data;
  }

  @Mutation
  public clearKubecostCostListAlert(): void {
    this.costKubecostTableAlert = emptyAlert();
  }

  @Mutation
  public setBillingMonth(data: string): void {
    this.billingMonth = data;
  }
  @Mutation
  public setRangeDate(data: string[]): void {
    this.rangeDate = data;
  }

  @Action
  async getKubecostCosts(): Promise<boolean> {
    const allDates: InterfaceCostKubecost[] = [];
    try {
      await Promise.all(
        this.rangeDate.map((date) => {
          return axios
            .get(`${baseApiUrl}?month=${date}`)
            .then((response: AxiosResponse) => {
              // For each item in response data push to allDates
              response.data.forEach((item: InterfaceCostKubecost) => {
                allDates.push(item);
              });
            });
        })
      );
      this.context.commit("setSelectedKubecostCostsList", allDates);
      return true;
    } catch (error) {
      const axiosError = error as AxiosError;
      let alert: Alert;
      if (axiosError.response) {
        const data = axiosError.response.data as { message: string };
        alert = new Alert(axiosError.response.status, data.message);
      } else {
        alert = new Alert(0, axiosError.message);
      }
      this.context.commit("setKubecostCostListAlert", alert, { root: true });
      return false;
    }
  }

  @Action
  public async getKubecostCost(fetchParams: FetchParams): Promise<boolean> {
    const params = new URLSearchParams({
      costCenter: fetchParams.costCenter + "",
      billingMonth: fetchParams.billingMonth,
    }).toString();
    this.context.commit("setSelectedKubecostCost", emptyCostKubecost());
    return axios
      .get(`${baseApiUrl}/report?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSelectedKubecostCost", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          const data = error.response.data as { message: string };
          alert = new Alert(error.response.status, data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setKubecostCostDetailAlert", alert);
        return false;
      });
  }
}

export default CostKubecost;
