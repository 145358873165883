import { Alert } from "@/models/Alert.model";
import { PageData } from "@/models/PageData.model";
import {
  InterfacePortalGroup,
  InterfacePagedPortalGroupMembership,
} from "@/models/PortalGroup.model";
import {
  emptyPortalUser,
  InterfacePortalUser,
  InterfacePortalUsersSearchRequest,
  InterfacePortalUsersSearchResult,
} from "@/models/PortalUser.model";
import Vue from "vue";
import Component from "vue-class-component";
import { Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const portalGroups = namespace("PortalGroups");
const portalUsers = namespace("PortalUsers");

@Component({})
export default class PortalGroupUserMembership extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public addPortalUserForm = false;
  public removeConfirmationForm = false;
  public hidden = false;
  public selectedPortalUserId = "";
  public portalUser: InterfacePortalUser = emptyPortalUser();
  public findPortalUsers = null;
  public operationInProgress = false;

  @Ref()
  addPortalUserFormElement!: HTMLFormElement;

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
  };

  @portalGroups.Mutation
  public clearPortalGroupAlert!: () => void;

  @portalUsers.State
  public existingPortalUsers!: InterfacePortalUser[];

  @portalGroups.State
  public selectedPortalGroup!: InterfacePortalGroup;

  @portalUsers.State
  public pageDataPortalUsers!: PageData;

  @portalUsers.State
  public foundPortalUsers!: InterfacePortalUser[];

  @portalGroups.State
  public portalGroupUserMembership!: InterfacePortalUser[];

  @portalGroups.State
  public pageDataPortalUserGroups!: PageData;

  @portalGroups.State
  public pageDataPortalGroupUsers!: PageData;

  @portalGroups.State
  public portalGroupAlert!: Alert;

  @portalUsers.Action
  public getPortalUsers!: (pageData: PageData) => Promise<boolean>;

  @portalUsers.Action
  public searchPortalUsers!: (
    searchRequest: InterfacePortalUsersSearchRequest
  ) => Promise<InterfacePortalUsersSearchResult>;

  @portalGroups.Action
  public getPortalGroupUserMembership!: (
    pagedPortalGroupMembership: InterfacePagedPortalGroupMembership
  ) => Promise<boolean>;

  @portalGroups.Action
  public addPortalUserToPortalGroup!: (
    portalGroupId: string
  ) => Promise<boolean>;

  @portalGroups.Action
  public removePortalUserFromPortalGroup!: (
    portalUserId: string
  ) => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return id.concat(name, actions);
    }
    return name.concat(actions);
  }

  @Watch("pageDataPortalGroupUsers.options", {
    deep: true,
  })
  optionsChange(): void {
    this.getPortalUsers(this.pageDataPortalUsers);
    this.getPortalGroupUserMembership({
      portalGroupId: this.$router.currentRoute.params.id,
      pageIndex: this.pageDataPortalGroupUsers.options.page,
      pageSize: this.pageDataPortalGroupUsers.options.itemsPerPage,
    });
  }

  public toggleSelectedPortalUser(id: string): void {
    this.selectedPortalUserId = id;
  }

  public async addPortalUserDialogAction(): Promise<void> {
    this.operationInProgress = true;
    if (await this.addPortalUserToPortalGroup(this.selectedPortalUserId)) {
      this.closeAddPortalUserForm();
    }
    this.operationInProgress = false;
  }

  public closeAddPortalUserForm(): void {
    this.addPortalUserForm = false;
    this.addPortalUserFormElement.reset();
    this.clearPortalGroupAlert();
  }

  public removePortalUserDialog(portalUser: InterfacePortalUser): void {
    this.portalUser = portalUser;
    this.removeConfirmationForm = true;
  }

  public async removePortalUser(
    portalUser: InterfacePortalUser
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.removePortalUserFromPortalGroup(portalUser.id)) {
      this.closeRemovePortalUserDialog();
    }
    this.operationInProgress = false;
  }

  public closeRemovePortalUserDialog(): void {
    this.removeConfirmationForm = false;
    this.clearPortalGroupAlert();
  }

  @Watch("findPortalUsers")
  onPropertyChange(value: string): void {
    if (value && value.length > 2) {
      const searchRequest: InterfacePortalUsersSearchRequest = {
        name: value,
        pageIndex: 1,
        pageSize: 100,
      };

      this.searchPortalUsers(searchRequest);
    }
  }
}
