import { DataOptions } from "vuetify";

export interface InterfacePageData {
  pageIndex: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
  options: DataOptions;
}

export const emptyPageData = (): PageData => ({
  pageIndex: 1,
  pageSize: 25,
  totalItems: 1,
  totalPages: 1,
  options: {
    page: 1,
    itemsPerPage: 25,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  },
});

export const emptyAuditPageData = (): PageData => ({
  pageIndex: 1,
  pageSize: 25,
  totalItems: 1,
  totalPages: 1,
  options: {
    page: 1,
    itemsPerPage: 25,
    sortBy: ["revtstmp"],
    sortDesc: [true],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: true,
  },
});

export class PageData implements InterfacePageData {
  constructor(
    public pageIndex: number,
    public pageSize: number,
    public totalItems: number,
    public totalPages: number,
    public options: DataOptions
  ) {}
}
