import {
  emptyManagementGroup,
  InterfaceManagementGroup,
  ManagementGroup,
} from "@/models/ManagementGroup.model";

import Vue from "vue";
import { Component, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { PageData } from "@/models/PageData.model";
import { InterfacePortalUser } from "@/models/PortalUser.model";
import { Alert } from "@/models/Alert.model";

import ServerSideAutocomplete from "../serverSideAutocomplete/ServerSideAutocomplete.vue";

const managementGroups = namespace("ManagementGroups");
const portalUsers = namespace("PortalUsers");

interface ManagementGroupSelectList {
  text: string;
  value: string;
}

interface FetchParams {
  search: string;
  page: number;
}

@Component({
  components: {
    ServerSideAutocomplete,
  },
})
export default class ManagementGroups extends Vue {
  @Ref()
  createManagementGroupFormElement!: HTMLFormElement;
  @Ref()
  createManagementGroupFormElementNameField!: HTMLFormElement;
  @Ref()
  createManagementGroupFormElementRemoteIdField!: HTMLFormElement;
  @Ref()
  editManagementGroupFormElement!: HTMLFormElement;
  @Ref()
  moveManagementGroupFormElement!: HTMLFormElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public managementGroup: InterfaceManagementGroup = emptyManagementGroup();
  public createManagementGroupForm = false;
  public editManagementGroupForm = false;
  public moveManagementGroupForm = false;
  public deleteConfirmationForm = false;
  public hidden = false;
  public operationInProgress = false;
  public managementGroupSelectList: ManagementGroupSelectList[] = [];
  public toggleRemoteIdField = false;

  @managementGroups.State
  public existingManagementGroups!: InterfaceManagementGroup[];

  @managementGroups.State
  public managementGroupsSelectList!: InterfaceManagementGroup[];

  @managementGroups.State
  public pageDataManagementGroups!: PageData;

  @managementGroups.State
  public managementGroupAlert!: Alert;

  @managementGroups.State
  public managementGroupTableAlert!: Alert;

  @managementGroups.State
  public inProgress!: boolean;

  @portalUsers.State
  public loggedInPortalUser!: InterfacePortalUser;

  @managementGroups.State
  public managementGroupsAutocompleteLoadMore!: boolean;

  @managementGroups.Mutation
  public setPageData!: (pageData: PageData) => void;

  @managementGroups.Mutation
  public clearManagementGroupAlert!: () => void;

  @managementGroups.Mutation
  public setManagementGroupAlert!: (alert: Alert) => void;

  @managementGroups.Action
  public getManagementGroups!: (pageData: PageData) => Promise<boolean>;

  @managementGroups.Mutation
  public setToggleRemoteIdField!: (data: boolean) => void;

  @managementGroups.Action
  public storeManagementGroup!: (
    managementGroup: InterfaceManagementGroup
  ) => Promise<boolean>;

  @managementGroups.Action
  public updateManagementGroup!: (
    managementGroup: InterfaceManagementGroup
  ) => Promise<boolean>;

  @managementGroups.Action
  public moveManagementGroup!: (
    managementGroup: InterfaceManagementGroup
  ) => Promise<boolean>;

  @managementGroups.Action
  public deleteManagementGroup!: (
    managementGroup: InterfaceManagementGroup
  ) => Promise<boolean>;

  @managementGroups.Action
  public getManagementGroupSelectList!: (
    fetchParams: FetchParams
  ) => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const parentName = [
      { text: "Parent Name", align: "start", value: "name", sortable: false },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return id.concat(name, parentName, actions);
    }
    return name;
  }

  @Watch("pageDataManagementGroups.options", {
    deep: true,
  })
  optionsChange(): void {
    this.contextLoadManagementGroups();
  }

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    name: (value: string): string | boolean => {
      const pattern = /^[a-zA-Z0-9-_]{3,50}$/;
      const onlyNumbers = /^\d+$/;
      return (
        (!onlyNumbers.test(value) && pattern.test(value)) ||
        "Invalid ManagementGroup Name. Names must be a alphanumeric (without spaces) and at least 3 characters long and less than 50 characters."
      );
    },
  };

  private contextLoadManagementGroups() {
    if (this.$router.currentRoute.fullPath === "/azure/groups") {
      this.loadAllManagementGroups();
    } else {
      // eslint-disable-next-line no-console
      console.error("No matched router path");
    }
  }

  loadAllManagementGroups(): void {
    this.getManagementGroups(this.pageDataManagementGroups).then(() => {
      this.managementGroupSelectList = this.existingManagementGroups.map(
        (managementGroup) => {
          return { text: managementGroup.name, value: managementGroup.id };
        }
      );
    });
  }
  changeToggleRemoteIdField(value: boolean): void {
    this.setToggleRemoteIdField(value);
  }

  public openManagementGroupDetails(managementGroupId: string): void {
    this.$router.push({
      name: "ManagementGroupDetails",
      params: { id: managementGroupId },
    });
  }

  public readManagementGroup(managementGroup: InterfaceManagementGroup): void {
    this.managementGroup = new ManagementGroup(
      managementGroup.id,
      managementGroup.name,
      managementGroup.parentId,
      managementGroup.parentName
    );
  }

  public editManagementGroupDialog(
    managementGroup: InterfaceManagementGroup
  ): void {
    this.readManagementGroup(managementGroup);
    this.editManagementGroupForm = true;
  }

  public async editManagementGroupDialogAction(
    managementGroup: InterfaceManagementGroup
  ): Promise<void> {
    if (this.editManagementGroupFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.updateManagementGroup(managementGroup)) {
        this.closeEditManagementGroupForm();
      }
      this.operationInProgress = false;
      this.contextLoadManagementGroups();
    }
  }

  public deleteManagementGroupDialog(
    managementGroup: InterfaceManagementGroup
  ): void {
    this.managementGroup = managementGroup;
    this.deleteConfirmationForm = true;
  }

  public async deleteManagementGroupDialogAction(
    managementGroup: InterfaceManagementGroup
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteManagementGroup(managementGroup)) {
      this.closeDeleteManagementGroupDialog();
    }
    this.operationInProgress = false;
    this.contextLoadManagementGroups();
  }

  public async storeManagementGroupDialogAction(
    managementGroup: InterfaceManagementGroup
  ): Promise<void> {
    if (this.createManagementGroupFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.storeManagementGroup(managementGroup)) {
        this.closeCreateManagementGroupForm();
      }
      this.operationInProgress = false;
      this.contextLoadManagementGroups();
    }
  }

  public async fetchCreateManagementGroupSelectList(
    search: string,
    page: number
  ): Promise<Array<unknown>> {
    this.pageDataManagementGroups.pageIndex = page;
    await this.getManagementGroupSelectList({
      search: search,
      page: page,
    });
    return this.managementGroupsSelectList;
  }

  public closeCreateManagementGroupForm(): void {
    this.managementGroup = emptyManagementGroup();
    this.createManagementGroupFormElement.reset();
    this.createManagementGroupForm = false;
    this.clearManagementGroupAlert();
  }

  public closeEditManagementGroupForm(): void {
    this.managementGroup = emptyManagementGroup();
    this.editManagementGroupForm = false;
    this.clearManagementGroupAlert();
  }

  public closeDeleteManagementGroupDialog(): void {
    this.managementGroup = emptyManagementGroup();
    this.deleteConfirmationForm = false;
    this.clearManagementGroupAlert();
  }
}
