import { InterfaceSubscription } from "@/models/Subscription.model";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { PageData } from "@/models/PageData.model";
import { Alert } from "@/models/Alert.model";
import { sanitizeUrl } from "@braintree/sanitize-url";

const subscriptions = namespace("Subscriptions");

interface FetchParams {
  pageData: PageData;
  parentId: string;
}

@Component({})
export default class CostCenterSubscriptions extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  showCostCenterSubscriptions = false;

  @subscriptions.State
  public subscriptionsByCostCenter!: InterfaceSubscription[];

  @subscriptions.State
  public pageDataSubscriptionsByCostCenter!: PageData;

  @subscriptions.State
  public subscriptionTableAlert!: Alert;

  @subscriptions.State
  public inProgress!: boolean;

  @subscriptions.Action
  public getSubscriptionsByCostCenter!: (
    fetchParams: FetchParams
  ) => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const remoteId = [
      { text: "Remote ID", align: "start", value: "remoteId", sortable: false },
    ];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const type = [
      {
        text: "Subscription Type",
        align: "start",
        value: "subscriptionType",
        sortable: false,
      },
    ];
    const managementGroupName = [
      {
        text: "Management Group Name",
        align: "start",
        value: "managementGroupName",
        sortable: false,
      },
    ];

    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return id.concat(remoteId, name, type, managementGroupName);
    }
    return remoteId.concat(name, type, managementGroupName);
  }

  @Watch("pageDataSubscriptionsByCostCenter.options", {
    deep: true,
  })
  optionsChange(): void {
    this.loadAllSubscriptions();
  }

  loadAllSubscriptions(): void {
    this.getSubscriptionsByCostCenter({
      pageData: this.pageDataSubscriptionsByCostCenter,
      parentId: this.$router.currentRoute.params.id,
    });
  }
  safeUrl(item: InterfaceSubscription): string {
    return sanitizeUrl(
      `https://portal.azure.com/#@corpdir.onmicrosoft.com/resource/subscriptions/${item.remoteId}/overview`
    );
  }
}
