import Vue from "vue";
import { Component } from "vue-property-decorator";
import Organizations from "@/views/organizations/Organizations.vue";
import SonarUsers from "@/views/sonarUsers/SonarUsers.vue";
import SonarGroupUserMembership from "@/views/sonarGroups/SonarGroupUserMembership.vue";
import { namespace } from "vuex-class";
import {
  InterfacePagedSonarGroupMembership,
  InterfaceSonarGroup,
} from "@/models/SonarGroup.model";
import { Alert } from "@/models/Alert.model";
import { PageData } from "@/models/PageData.model";

const sonarGroups = namespace("SonarGroups");

@Component({
  components: { Organizations, SonarUsers, SonarGroupUserMembership },
})
export default class SonarGroupDetails extends Vue {
  @sonarGroups.State
  public sonarGroupDetailAlert!: Alert;

  @sonarGroups.State
  public selectedSonarGroup!: InterfaceSonarGroup;

  @sonarGroups.State
  public pageDataSonarGroupUsers!: PageData;

  @sonarGroups.Action
  public getSonarGroup!: (id: string) => Promise<boolean>;

  @sonarGroups.Action
  public getSonarGroupUserMembership!: (
    pagedSonarGroupMembership: InterfacePagedSonarGroupMembership
  ) => Promise<boolean>;

  @sonarGroups.Mutation
  public clearSonarGroupDetailAlert!: () => void;

  mounted(): void {
    this.getSonarGroup(this.$router.currentRoute.params.id);
  }

  public async reload(): Promise<void> {
    if (await this.getSonarGroup(this.$router.currentRoute.params.id)) {
      this.clearSonarGroupDetailAlert();
      this.getSonarGroupUserMembership({
        sonarGroupId: this.$router.currentRoute.params.id,
        pageIndex: this.pageDataSonarGroupUsers.options.page,
        pageSize: this.pageDataSonarGroupUsers.options.itemsPerPage,
      });
    }
  }
}
