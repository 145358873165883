export interface InterfaceRequest {
  id: string;
  name: string;
  requesterId: string;
  requesterUsername: string;
  description: string;
  subscriptionType: string;
  subscriptionAction: string;
  managementGroupId: string;
  organizationId: string;
  costCenterId: string;
  state: string;
  answer: string;
}

export interface InterfaceRequestAudit {
  rev: number;
  id: string;
  name: string;
  requesterId: string;
  requesterUsername: string;
  description: string;
  subscriptionType: string;
  subscriptionAction: string;
  managementGroupId: string;
  organizationId: string;
  costCenterId: string;
  state: number;
  answer: string;
  username: string;
  timestamp: string;
  revtype: number;
}

export const emptyRequest = (): SubscriptionRequest => ({
  id: "",
  name: "",
  requesterId: "",
  requesterUsername: "",
  description: "",
  subscriptionType: "",
  subscriptionAction: "",
  managementGroupId: "",
  organizationId: "",
  costCenterId: "",
  state: "",
  answer: "",
});

export class SubscriptionRequest implements InterfaceRequest {
  constructor(
    public id: string,
    public name: string,
    public requesterId: string,
    public requesterUsername: string,
    public description: string,
    public subscriptionType: string,
    public subscriptionAction: string,
    public managementGroupId: string,
    public organizationId: string,
    public costCenterId: string,
    public state: string,
    public answer: string
  ) {}
}
