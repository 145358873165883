import Vue from "vue";
import { Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import SonarGroups from "@/views/sonarGroups/SonarGroups.vue";
import PortalGroups from "@/views/portalGroups/PortalGroups.vue";
import SonarProjects from "@/views/sonarProjects/SonarProjects.vue";
import { InterfaceOrganization } from "@/models/Organization.model";
import { Alert } from "@/models/Alert.model";

const organizations = namespace("Organizations");

@Component({ components: { SonarProjects, SonarGroups, PortalGroups } })
export default class OrganizationDetails extends Vue {
  sonarGroupsDialog = false;

  @organizations.State
  public selectedOrganization!: InterfaceOrganization;

  @organizations.State
  public organizationDetailAlert!: Alert;

  @organizations.Action
  public getOrganization!: (id: string) => Promise<boolean>;

  mounted(): void {
    this.getOrganization(this.$router.currentRoute.params.id);
  }

  public reload(): void {
    window.location.reload();
  }
}
