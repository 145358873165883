import {
  emptyOrganization,
  InterfaceOrganization,
  Organization,
} from "@/models/Organization.model";

import Vue from "vue";
import { Component, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { PageData } from "@/models/PageData.model";
import { InterfaceSonarServer } from "@/models/SonarServer.model";
import { InterfacePortalUser } from "@/models/PortalUser.model";
import { Alert } from "@/models/Alert.model";
import SearchInput from "../searchInput/SearchInput.vue";
import {
  emptySearchByKeywordRequest,
  SearchByKeywordRequest,
} from "@/models/Search.model";

const sonarServers = namespace("SonarServers");
const organizations = namespace("Organizations");
const portalUsers = namespace("PortalUsers");

interface SonarSelectList {
  text: string;
  value: string;
}

@Component({
  components: {
    SearchInput,
  },
})
export default class Organizations extends Vue {
  @Ref()
  createOrganizationFormElement!: HTMLFormElement;
  @Ref()
  createOrganizationFormElementNameField!: HTMLFormElement;
  @Ref()
  editOrganizationFormElement!: HTMLFormElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public organization: InterfaceOrganization = emptyOrganization();
  public createOrganizationForm = false;
  public editOrganizationForm = false;
  public deleteConfirmationForm = false;
  public sonarServerSelectList: SonarSelectList[] = [];
  public hidden = false;
  public operationInProgress = false;
  public searchRequest: SearchByKeywordRequest = emptySearchByKeywordRequest();

  @organizations.State
  public existingOrganizations!: InterfaceOrganization[];

  @organizations.State
  public pageDataOrganizations!: PageData;

  @organizations.State
  public organizationAlert!: Alert;

  @organizations.State
  public organizationTableAlert!: Alert;

  @organizations.State
  public inProgress!: boolean;

  @sonarServers.State
  public existingSonarServers!: InterfaceSonarServer[];

  @sonarServers.State
  public selectedSonarServer!: InterfaceSonarServer;

  @sonarServers.State
  public pageDataSonarServers!: PageData;

  @portalUsers.State
  public loggedInPortalUser!: InterfacePortalUser;

  @organizations.Mutation
  public setPageData!: (pageData: PageData) => void;

  @organizations.Mutation
  public clearOrganizationAlert!: () => void;

  @organizations.Mutation
  public setOrganizationAlert!: (alert: Alert) => void;

  @sonarServers.Action
  public getSonarServers!: (pageData: PageData) => Promise<boolean>;

  @organizations.Action
  public getOrganizations!: (
    searchRequest: SearchByKeywordRequest
  ) => Promise<boolean>;

  @organizations.Action
  public getSonarServerOrganizationMembership!: (
    sonarServerId: string
  ) => Promise<boolean>;

  @organizations.Action
  public storeOrganization!: (
    organization: InterfaceOrganization
  ) => Promise<boolean>;

  @organizations.Action
  public updateOrganization!: (
    organization: InterfaceOrganization
  ) => Promise<boolean>;

  @organizations.Action
  public deleteOrganization!: (
    organization: InterfaceOrganization
  ) => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const serverId = [
      {
        text: "Sonar Server ID",
        align: "start",
        value: "sonarServerId",
        sortable: false,
      },
    ];
    const serverName = [
      {
        text: "Sonar Server Name",
        align: "start",
        value: "sonarServerName",
        sortable: false,
      },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    if (this.$router.currentRoute.fullPath === "/orgs/organizations") {
      if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
        return id.concat(name, serverId, serverName, actions);
      }
      return name.concat(serverName);
    }
    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return id.concat(name, actions);
    }
    return name;
  }
  @Watch("searchRequest.keyword", {
    deep: true,
  })
  searchValueChange(): void {
    this.searchRequest.pageData.options = this.pageDataOrganizations.options;
    this.contextLoadOrganizations();
  }
  @Watch("pageDataOrganizations.options", {
    deep: true,
  })
  optionsChange(): void {
    this.searchRequest.pageData.options = this.pageDataOrganizations.options;
    this.contextLoadOrganizations();
  }

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    name: (value: string): string | boolean => {
      const pattern = /^[a-zA-Z0-9-_]{3,50}$/;
      const onlyNumbers = /^\d+$/;
      return (
        (!onlyNumbers.test(value) && pattern.test(value)) ||
        "Invalid Organization Name. Names must be a alphanumeric (without spaces) and at least 3 characters long and less than 50 characters."
      );
    },
  };

  private contextLoadOrganizations() {
    if (this.$router.currentRoute.fullPath === "/orgs/organizations") {
      this.loadAllOrganizations();
    } else if (
      this.$router.currentRoute.fullPath.startsWith("/sonar/servers/")
    ) {
      this.getSonarServerOrganizationMembership(
        this.$router.currentRoute.params.id
      );
    } else {
      // eslint-disable-next-line no-console
      console.error("No matched router path#1");
    }
  }

  loadAllOrganizations(): void {
    this.getOrganizations(this.searchRequest);
    this.getSonarServers(this.pageDataSonarServers).then(() => {
      this.sonarServerSelectList = this.existingSonarServers.map((server) => {
        return { text: server.name, value: server.id };
      });
    });
  }

  public openOrganizationDetails(organizationId: string): void {
    this.$router.push({
      name: "OrganizationDetails",
      params: { id: organizationId },
    });
  }

  public editOrganizationDialog(organization: InterfaceOrganization): void {
    this.organization = new Organization(
      organization.id,
      organization.name,
      organization.sonarServerId,
      organization.sonarServerName,
      organization.sonarProjectsCount,
      organization.sonarGroupsCount,
      organization.portalGroupsCount,
      organization.costCentersCount,
      organization.subscriptionsCount
    );
    this.editOrganizationForm = true;
  }

  public async editOrganizationDialogAction(
    organization: InterfaceOrganization
  ): Promise<void> {
    if (this.editOrganizationFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.updateOrganization(organization)) {
        this.closeEditOrganizationForm();
      }
      this.operationInProgress = false;
      this.contextLoadOrganizations();
    }
  }

  public deleteOrganizationDialog(organization: InterfaceOrganization): void {
    this.organization = organization;
    this.deleteConfirmationForm = true;
  }

  public async deleteOrganizationDialogAction(
    organization: InterfaceOrganization
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteOrganization(organization)) {
      this.closeDeleteOrganizationDialog();
    }
    this.operationInProgress = false;
    this.contextLoadOrganizations();
  }

  public async storeOrganizationDialogAction(
    organization: InterfaceOrganization
  ): Promise<void> {
    if (this.createOrganizationFormElement.validate()) {
      this.operationInProgress = true;
      if (this.$router.currentRoute.fullPath.startsWith("/sonar/servers/")) {
        organization.sonarServerId = this.selectedSonarServer.id;
      }
      if (await this.storeOrganization(organization)) {
        this.closeCreateOrganizationForm();
      }
      this.operationInProgress = false;
      this.contextLoadOrganizations();
    }
  }

  public closeCreateOrganizationForm(): void {
    this.organization = emptyOrganization();
    if (this.$router.currentRoute.fullPath.startsWith("/sonar/servers/")) {
      this.createOrganizationFormElementNameField.reset();
    } else {
      this.createOrganizationFormElement.reset();
    }
    this.createOrganizationForm = false;
    this.clearOrganizationAlert();
  }

  public closeEditOrganizationForm(): void {
    this.organization = emptyOrganization();
    this.editOrganizationForm = false;
    this.clearOrganizationAlert();
  }

  public closeDeleteOrganizationDialog(): void {
    this.organization = emptyOrganization();
    this.deleteConfirmationForm = false;
    this.clearOrganizationAlert();
  }

  public cannotDeleteOrganization(
    organization: InterfaceOrganization
  ): boolean {
    if (
      organization.sonarProjectsCount > 0 ||
      organization.sonarGroupsCount > 0 ||
      organization.portalGroupsCount > 0
    )
      return true;
    else return false;
  }
}
