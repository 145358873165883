import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { Alert } from "@/models/Alert.model";
import {
  emptySearchByKeywordRequest,
  SearchByKeywordRequest,
} from "@/models/Search.model";
import moment from "moment";
import { InterfaceCostAzureHub } from "@/models/CostAzureHub.model";

const costAzureHub = namespace("CostAzureHub");

@Component({})
export default class CostAzureHub extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;

  public searchRequest: SearchByKeywordRequest = emptySearchByKeywordRequest();
  public items: InterfaceCostAzureHub[] = [];
  startDate = new Date(new Date().setMonth(new Date().getMonth() - 2))
    .toISOString()
    .slice(0, 7);
  endDate = new Date(new Date().setMonth(new Date().getMonth() - 1))
    .toISOString()
    .slice(0, 7);
  public selectedStartDate = new Date(
    new Date().setMonth(new Date().getMonth() - 2)
  )
    .toISOString()
    .slice(0, 7);
  public selectedEndDate = new Date(
    new Date().setMonth(new Date().getMonth() - 1)
  )
    .toISOString()
    .slice(0, 7);

  public endDateMenu = false;
  public startDateMenu = false;
  public rangeDate: string[] = [];
  public operationInProgress = false;

  @costAzureHub.State
  public costAzureHubTableAlert!: Alert;

  @costAzureHub.State
  public selectedAzureHubCostsList!: InterfaceCostAzureHub[];

  @costAzureHub.Mutation
  public setRangeDate!: (data: string[]) => void;

  @costAzureHub.Action
  public getAzureHubCosts!: () => Promise<boolean>;

  async mounted(): Promise<void> {
    this.setRangeDate(this.getDatesSelected());
    await this.getAzureHubCosts();
  }

  @Watch("startDate", {
    deep: true,
  })
  @Watch("endDate", {
    deep: true,
  })
  async update(): Promise<void> {
    this.setRangeDate(this.getDatesSelected());
    await this.getAzureHubCosts();
  }

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const costCenter = [
      {
        text: "Cost Center",
        align: "start",
        value: "costCenter",
        sortable: false,
      },
    ];
    const costCenterResponsible = [
      {
        text: "Cost Center Responsible",
        align: "start",
        value: "costCenterResponsible",
        sortable: false,
      },
    ];
    const costCenterResponsibleEmailAddress = [
      {
        text: "Cost Center Responsible Email",
        align: "start",
        value: "costCenterResponsibleEmailAddress",
        sortable: false,
      },
    ];
    const totalCost = [
      {
        text: "Cost",
        align: "start",
        value: "totalCost",
        sortable: false,
      },
    ];
    const billingMonth = [
      {
        text: "Billing Month",
        align: "start",
        value: "billingMonth",
        sortable: false,
      },
    ];

    return costCenter.concat(
      costCenterResponsible,
      costCenterResponsibleEmailAddress,
      totalCost,
      billingMonth
    );
  }

  cancelStartDate(): void {
    this.startDateMenu = false;
    this.selectedStartDate = this.startDate;
  }

  selectStartDate(): void {
    this.startDateMenu = false;
    this.startDate = this.selectedStartDate;
  }

  minEndDate(): string {
    return this.startDate;
  }

  selectEndDate(): void {
    this.endDateMenu = false;
    this.endDate = this.selectedEndDate;
  }

  cancelEndDate(): void {
    this.endDateMenu = false;
    this.selectedEndDate = this.endDate;
  }

  getDatesSelected(): string[] {
    const dates: string[] = [];
    if (this.startDate && this.endDate) {
      const startDate: moment.Moment = moment(this.startDate).startOf("month");
      const endDate: moment.Moment = moment(this.endDate).endOf("month");
      const currentDate: moment.Moment = startDate.clone();
      while (currentDate.isSameOrBefore(endDate)) {
        dates.push(currentDate.format("YYYY-MM"));
        currentDate.add(1, "month");
      }
    }
    return dates;
  }

  getTotalCost(): number {
    return this.selectedAzureHubCostsList.reduce(
      (total, item) => total + item.totalCost,
      0
    );
  }

  downloadCSV(): void {
    const csvHeaders = this.headers.map((header) => header.value);
    const rows = this.selectedAzureHubCostsList.map((row) =>
      csvHeaders.map((headerKey) =>
        // eslint-disable-next-line
        JSON.stringify((row as any)[headerKey] || "")
      )
    );
    rows.unshift(csvHeaders);
    const csvContent = rows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute(
      "download",
      `${this.selectedStartDate}_${this.selectedEndDate}_azure-hub.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
