import {
  emptyPortalGroup,
  InterfacePortalGroup,
} from "@/models/PortalGroup.model";
import Vue from "vue";
import { Component, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { PageData } from "@/models/PageData.model";
import { InterfaceOrganization } from "@/models/Organization.model";
import { Alert } from "@/models/Alert.model";

const portalGroups = namespace("PortalGroups");
const organizations = namespace("Organizations");

@Component({})
export default class PortalGroups extends Vue {
  @Ref()
  createPortalGroupFormElement!: HTMLFormElement;
  @Ref()
  editPortalGroupFormElement!: HTMLFormElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public portalGroup: InterfacePortalGroup = emptyPortalGroup();
  public createPortalGroupForm = false;
  public editPortalGroupForm = false;
  public deleteConfirmationForm = false;
  public operationInProgress = false;
  public hidden = false;

  @portalGroups.State
  public existingPortalGroups!: InterfacePortalGroup[];

  @portalGroups.State
  public pageDataPortalGroups!: PageData;

  @portalGroups.State
  public portalGroupAlert!: Alert;

  @portalGroups.State
  public portalGroupTableAlert!: Alert;

  @portalGroups.State
  public selectedPortalGroup!: InterfacePortalGroup;

  @organizations.State
  public selectedOrganization!: InterfaceOrganization;

  @portalGroups.State
  public inProgress!: boolean;

  @portalGroups.Mutation
  public setPageData!: (pageData: PageData) => void;

  @portalGroups.Mutation
  public clearPortalGroupAlert!: () => void;

  @portalGroups.Mutation
  public setPortalGroupAlert!: (alert: Alert) => void;

  @portalGroups.Action
  public getPortalGroups!: (pageData: PageData) => Promise<boolean>;

  @portalGroups.Action
  public getSonarServerPortalGroupMembership!: (
    sonarServerId: string
  ) => Promise<boolean>;

  @portalGroups.Action
  public getOrganizationPortalGroupMembership!: (
    organizationId: string
  ) => Promise<boolean>;

  @portalGroups.Action
  public storePortalGroup!: (
    portalGroup: InterfacePortalGroup
  ) => Promise<boolean>;

  @portalGroups.Action
  public updatePortalGroup!: (
    portalGroup: InterfacePortalGroup
  ) => Promise<boolean>;

  @portalGroups.Action
  public addSonarServerToPortalGroup!: (
    sonarServerId: string
  ) => Promise<boolean>;

  @portalGroups.Action
  public addOrganizationToPortalGroup!: (
    organizationId: string
  ) => Promise<boolean>;

  @portalGroups.Action
  public deletePortalGroup!: (
    portalGroup: InterfacePortalGroup
  ) => Promise<boolean>;

  @organizations.Action
  public getOrganization!: (organizationId: string) => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const sonarServerNames = [
      { text: "Sonar Servers", align: "start", value: "name", sortable: false },
    ];
    const organizationNames = [
      { text: "Organizations", align: "start", value: "name", sortable: false },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return id.concat(name, sonarServerNames, organizationNames, actions);
    }
    return name.concat(sonarServerNames, organizationNames);
  }

  @Watch("pageDataPortalGroups.options", {
    deep: true,
  })
  optionsChange(): void {
    this.contextLoadPortalGroups();
  }

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    name: (value: string): string | boolean => {
      const pattern = /^[a-zA-Z0-9-_]{3,50}$/;
      const onlyNumbers = /^\d+$/;
      return (
        (!onlyNumbers.test(value) && pattern.test(value)) ||
        "Invalid PortalGroup Name. Names must be an alphanumeric (without spaces) at least 3 characters long and less than 50 characters."
      );
    },
  };

  private contextLoadPortalGroups(): void {
    if (this.$router.currentRoute.fullPath === "/orgs/groups") {
      this.getPortalGroups(this.pageDataPortalGroups);
    } else if (
      this.$router.currentRoute.fullPath.startsWith("/sonar/servers/")
    ) {
      this.getSonarServerPortalGroupMembership(
        this.$router.currentRoute.params.id
      );
    } else if (
      this.$router.currentRoute.fullPath.startsWith("/orgs/organizations/")
    ) {
      this.getOrganizationPortalGroupMembership(
        this.$router.currentRoute.params.id
      );
    } else {
      // eslint-disable-next-line no-console
      console.error("No matched router path#3");
    }
  }

  public openPortalGroupDetails(portalGroupId: string): void {
    this.$router.push({
      name: "PortalGroupDetails",
      params: { id: portalGroupId },
    });
  }

  public editPortalGroupDialog(portalGroup: InterfacePortalGroup): void {
    this.portalGroup = portalGroup;
    this.editPortalGroupForm = true;
  }

  public async editPortalGroupDialogAction(
    portalGroup: InterfacePortalGroup
  ): Promise<void> {
    if (this.editPortalGroupFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.updatePortalGroup(portalGroup)) {
        this.closeEditPortalGroupForm();
      }
      this.operationInProgress = false;
      this.contextLoadPortalGroups();
    }
  }

  public deletePortalGroupDialog(portalGroup: InterfacePortalGroup): void {
    this.portalGroup = portalGroup;
    this.deleteConfirmationForm = true;
  }

  public async deletePortalGroupDialogAction(
    portalGroup: InterfacePortalGroup
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deletePortalGroup(portalGroup)) {
      this.closeDeletePortalGroupDialog();
    }
    this.operationInProgress = false;
    this.contextLoadPortalGroups();
  }

  public async storePortalGroupDialogAction(
    portalGroup: InterfacePortalGroup
  ): Promise<void> {
    if (this.createPortalGroupFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.storePortalGroup(portalGroup)) {
        if (this.$router.currentRoute.fullPath === "/orgs/groups") {
          this.closeCreatePortalGroupForm();
        } else {
          // Create membership with sonar-server or organization for new portal-group
          await this.contextAllocateMembership();
        }
      }
      this.operationInProgress = false;
      this.contextLoadPortalGroups();
    }
  }

  public closeCreatePortalGroupForm(): void {
    this.portalGroup = emptyPortalGroup();
    this.createPortalGroupFormElement.reset();
    this.createPortalGroupForm = false;
    this.clearPortalGroupAlert();
  }

  public closeEditPortalGroupForm(): void {
    this.portalGroup = emptyPortalGroup();
    this.editPortalGroupForm = false;
    this.clearPortalGroupAlert();
  }

  public closeDeletePortalGroupDialog(): void {
    this.portalGroup = emptyPortalGroup();
    this.deleteConfirmationForm = false;
    this.clearPortalGroupAlert();
  }

  public async contextAllocateMembership(): Promise<void> {
    if (this.$router.currentRoute.fullPath.startsWith("/sonar/servers/")) {
      await this.allocateSonarServerMembership(
        this.$router.currentRoute.params.id
      );
    } else if (
      this.$router.currentRoute.fullPath.startsWith("/orgs/organizations/")
    ) {
      await this.allocateOrganizationMembership(
        this.$router.currentRoute.params.id
      );
    }
  }

  public async allocateSonarServerMembership(
    sonarServerId: string
  ): Promise<void> {
    if (await this.addSonarServerToPortalGroup(sonarServerId)) {
      this.closeCreatePortalGroupForm();
    }
  }

  public async allocateOrganizationMembership(
    organizationId: string
  ): Promise<void> {
    if (await this.addOrganizationToPortalGroup(organizationId)) {
      await this.allocateSonarServerMembership(
        this.selectedOrganization.sonarServerId
      );
    }
  }

  public cannotDeletePortalGroup(portalGroup: InterfacePortalGroup): boolean {
    return (
      portalGroup.organizationsCount > 0 ||
      portalGroup.sonarServersCount > 0 ||
      portalGroup.portalUsersCount > 0
    );
  }
}
