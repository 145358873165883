import { InterfaceSonarGroup } from "@/models/SonarGroup.model";
import { InterfaceSonarServer } from "@/models/SonarServer.model";
import { PageData } from "@/models/PageData.model";
import { emptySonarUser, InterfaceSonarUser } from "@/models/SonarUser.model";
import router from "@/router";

import Vue from "vue";
import { Component, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Alert } from "@/models/Alert.model";
import SearchInput from "../searchInput/SearchInput.vue";
import {
  emptySearchByKeywordRequest,
  SearchByKeywordRequest,
} from "@/models/Search.model";

const sonarUsers = namespace("SonarUsers");
const sonarGroups = namespace("SonarGroups");
const sonarServers = namespace("SonarServers");

interface SonarGroupList {
  text: string;
  value: string;
}

interface SonarSelectList {
  text: string;
  value: string;
}

@Component({
  components: {
    SearchInput,
  },
})
export default class SonarUsers extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public createSonarUserForm = false;
  public deleteConfirmationForm = false;
  public hidden = false;
  public operationInProgress = false;
  public sonarGroupSelectList: SonarGroupList[] = [];
  public sonarUser: InterfaceSonarUser = emptySonarUser();
  public sonarServerSelectList: SonarSelectList[] = [];
  public searchRequest: SearchByKeywordRequest = emptySearchByKeywordRequest();
  public sonarServerId = "";

  @Ref()
  createSonarUserFormElement!: HTMLFormElement;

  @sonarUsers.State
  public pageDataSonarUsers!: PageData;

  @sonarUsers.State
  public inProgress!: boolean;

  @sonarGroups.State
  public pageDataSonarGroups!: PageData;

  @sonarUsers.State
  public existingSonarUsers!: InterfaceSonarUser[];

  @sonarUsers.State
  public foundSonarUsers!: InterfaceSonarUser[];

  @sonarGroups.State
  public existingSonarGroups!: InterfaceSonarGroup[];

  @sonarServers.State
  public existingSonarServers!: InterfaceSonarServer[];

  @sonarServers.State
  public pageDataSonarServers!: PageData;

  @sonarUsers.State
  public sonarUserAlert!: Alert;

  @sonarUsers.State
  public sonarUserTableAlert!: Alert;

  @sonarUsers.Mutation
  public setSonarServerId!: (data: string) => void;

  @sonarUsers.Mutation
  public setFoundSonarUsers!: (data: InterfaceSonarUser[]) => void;

  @sonarUsers.Mutation
  public setPageData!: (pageData: PageData) => void;

  @sonarUsers.Mutation
  public clearSonarUserAlert!: () => void;

  @sonarUsers.Action
  public deleteSonarUser!: (sonarUser: InterfaceSonarUser) => Promise<boolean>;

  @sonarServers.Action
  public getSonarServers!: (pageData: PageData) => Promise<boolean>;

  @sonarGroups.Action
  public getSonarGroups!: (pageData: PageData) => Promise<boolean>;

  @sonarUsers.Action
  public getSonarUsers!: (
    searchRequest: SearchByKeywordRequest
  ) => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Username", align: "start", value: "name", sortable: false },
    ];
    const serverId = [
      {
        text: "Sonar Server ID",
        align: "start",
        value: "sonarServerId",
        sortable: false,
      },
    ];
    const serverName = [
      {
        text: "Sonar Server Name",
        align: "start",
        value: "sonarServerName",
        sortable: false,
      },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    if (this.$router.currentRoute.fullPath === "/sonar/users") {
      if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
        return id.concat(name, serverId, serverName, actions);
      }
      return name.concat(serverName);
    }
    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return id.concat(name, actions);
    }
    return name;
  }

  loadSonarServers(): void {
    this.getSonarServers(this.pageDataSonarServers).then(() => {
      this.sonarServerSelectList = this.existingSonarServers.map((server) => {
        return { text: server.name, value: server.id };
      });
      this.sonarServerId = this.sonarServerSelectList[0].value;
      this.setSonarServerId(this.sonarServerId);
    });
  }

  @Watch("pageDataSonarUsers.options", {
    deep: true,
  })
  optionsChange(): void {
    this.searchRequest.pageData.options = this.pageDataSonarUsers.options;
    this.loadSonarServers();
    this.getSonarUsers(this.searchRequest);
  }
  @Watch("searchRequest.keyword", {
    deep: true,
  })
  @Watch("sonarServerId", {
    deep: true,
  })
  searchValueChange(): void {
    this.searchRequest.pageData.options = this.pageDataSonarUsers.options;
    this.setSonarServerId(this.sonarServerId);
    this.getSonarUsers(this.searchRequest);
  }

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
  };

  public openSonarUserDetails(sonarUserId: string): void {
    router.push({
      name: "SonarUserDetails",
      params: { id: sonarUserId },
    });
  }

  public deleteSonarUserDialog(sonarUser: InterfaceSonarUser): void {
    this.sonarUser = sonarUser;
    this.deleteConfirmationForm = true;
  }

  public async deleteSonarUserDialogAction(
    sonarUser: InterfaceSonarUser
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteSonarUser(sonarUser)) {
      this.closeDeleteSonarUserDialog();
    }
    this.operationInProgress = false;
    this.getSonarUsers(this.searchRequest);
  }

  public closeDeleteSonarUserDialog(): void {
    this.sonarUser = emptySonarUser();
    this.deleteConfirmationForm = false;
    this.clearSonarUserAlert();
  }
}
