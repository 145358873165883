import { render, staticRenderFns } from "./CostAtlassianDetails.vue?vue&type=template&id=c99477de"
import script from "@/components/costAtlassian/CostAtlassianDetails.component.ts?vue&type=script&lang=ts&external"
export * from "@/components/costAtlassian/CostAtlassianDetails.component.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports