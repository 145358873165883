import Vue from "vue";
import { Component } from "vue-property-decorator";
import PortalUserGroupMembership from "@/views/portalUsers/PortalUserGroupMembership.vue";
import { namespace } from "vuex-class";
import { InterfacePortalUser } from "@/models/PortalUser.model";
import { Alert } from "@/models/Alert.model";

const portalUsers = namespace("PortalUsers");

@Component({ components: { PortalUserGroupMembership } })
export default class PortalUserDetails extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  portalUsersDialog = false;
  showPortalGroups = true;

  @portalUsers.State
  public selectedPortalUser!: InterfacePortalUser;

  @portalUsers.State
  public portalUserDetailAlert!: Alert;

  @portalUsers.Action
  public getPortalUser!: (id: string) => Promise<boolean>;

  mounted(): void {
    this.getPortalUser(this.$router.currentRoute.params.id);
  }

  public reload(): void {
    window.location.reload();
  }
}
