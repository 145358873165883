import Vue from "vue";
import { Component } from "vue-property-decorator";
import { namespace } from "vuex-class";

import Organizations from "@/views/organizations/Organizations.vue";
import PortalGroups from "@/views/portalGroups/PortalGroups.vue";
import { InterfaceRequest } from "@/models/Request.model";
import { Alert } from "@/models/Alert.model";

const requests = namespace("Requests");

@Component({ components: { Organizations, PortalGroups } })
export default class RequestDetails extends Vue {
  showPortalGroups = false;

  @requests.State
  public selectedRequest!: InterfaceRequest;

  @requests.State
  public requestDetailAlert!: Alert;

  @requests.Action
  public getRequest!: (id: string) => Promise<boolean>;

  mounted(): void {
    this.getRequest(this.$router.currentRoute.params.id);
  }

  public reload(): void {
    window.location.reload();
  }
}
