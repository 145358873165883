import Vue from "vue";
import Vuex from "vuex";
import Requests from "@/store/modules/Requests";
import SubscriptionRequests from "@/store/modules/SubscriptionRequests";
import SonarServers from "@/store/modules/SonarServers";
import Organizations from "@/store/modules/Organizations";
import PortalGroups from "@/store/modules/PortalGroups";
import PortalUsers from "@/store/modules/PortalUsers";
import SonarGroups from "@/store/modules/SonarGroups";
import SonarUsers from "@/store/modules/SonarUsers";
import SonarProjects from "@/store/modules/SonarProjects";
import Subscriptions from "@/store/modules/Subscriptions";
import ManagementGroups from "@/store/modules/ManagementGroups";
import CostCenters from "@/store/modules/CostCenters";
import CostAtlassian from "@/store/modules/CostAtlassian";
import CostAzureHub from "@/store/modules/CostAzureHub";
import CostAzureSp from "@/store/modules/CostAzureSp";
import CostAzureSub from "@/store/modules/CostAzureSub";
import CostCloudExcellence from "@/store/modules/CostCloudExcellence";
import CostDataDog from "@/store/modules/CostDataDog";
import CostKubecost from "@/store/modules/CostKubecost";
import KubecostEndpoints from "@/store/modules/KubecostEndpoints";
import ServiceAgreements from "@/store/modules/ServiceAgreements";
import AzureUsers from "@/store/modules/AzureUsers";
import AuditLogs from "@/store/modules/AuditLogs";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Requests,
    SonarServers,
    Organizations,
    PortalGroups,
    PortalUsers,
    SonarGroups,
    SonarUsers,
    SonarProjects,
    Subscriptions,
    SubscriptionRequests,
    ManagementGroups,
    CostCenters,
    CostAtlassian,
    CostAzureHub,
    CostAzureSp,
    CostAzureSub,
    CostCloudExcellence,
    CostDataDog,
    CostKubecost,
    KubecostEndpoints,
    ServiceAgreements,
    AzureUsers,
    AuditLogs,
  },
});
